import { Box } from "@mui/material"

const CheckedIcon = ({ style }) => {
    return <Box sx={{
        backgroundColor: '#377dff',
        width: 20,
        height: 20,
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }}
        style={style}>
        <img
            style={{
                width: 'inherit',
                height: 'inherit',
            }}

            alt="checked"
            src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/checked.svg'}
        />
    </Box>
}

export default CheckedIcon