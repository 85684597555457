import {configureStore} from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

const persistConfig = {
 key: 'root',
 storage: storage,
 //  blacklist: ['reducer to don`t store'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleWare = [];

const store = configureStore({
 reducer: persistedReducer,
 middleware: (getDefaultMiddleWare) =>
  getDefaultMiddleWare({
   serializableCheck: false,
  }).concat(middleWare),
});

export default store;
