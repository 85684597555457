import React from 'react';
import styles from './DetailedContact.module.css';
import AppHeader from '../../../component/appHeader/AppHeader';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import { toggleLoader } from '../../../store/loader/loaderReducer';
import ContactTextField from '../ContactTextField';
import { DeleteModal } from '../../../component/DeleteModal';

function DetailedContact({ values, setValues, setShow, onDelete }) {
  const document = useSelector((state) => state?.document?.value);
  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
  const dispatch = useDispatch();
  const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');


  const [editValue, setEditValue] = useState(null);
  const [toggleEditContact, setToggleEditContact] = useState(false);
  const [editIcon, setEditIcon] = useState(true);
  const [showModal, setShowModal] = useState(false);
  // const [roles, setRoles] = useState(null);

  const editContactDetailScheme = yup.object().shape({
    email_id: yup.string().required('enter email').email('enter valid email'),
    first_name: yup.string().required('firstName code is required'),
  });
  const id = values.Id;

  // useEffect(() => {
  //   setRoles(show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin'))
  // }, [show_integeration])

  const DateFormat = (unix) => {
    let getDate = moment(new Date(unix));
    let startOfToday = moment().startOf('day');
    let startOfDate = moment(getDate).startOf('day');
    let daysDiff = startOfDate.diff(startOfToday, 'days');
    let days = {
      0: 'Today',
      '-1': 'Yesterday',
      1: 'Tomorrow',
    };

    if (Math.abs(daysDiff) <= 1) {
      let day = days[daysDiff];
      let time = moment(new Date(unix)).format('LT');
      return day + ' ' + time;
    } else {
      return moment(new Date(unix)).format('DD/MM/YYYY LT');
    }
  };

  const createdTime = DateFormat(values?.Created_Time);
  const modifiedTime = DateFormat(values?.Modified_Time)

  useEffect(() => {
    editContactDetail.setValues({
      first_name: values.First_Name,
      last_name: values.Last_Name,
      email_id: values.Email_Id,
      phone_number: values.Phone_Number,
      mobile_number: values.Mobile_Number,
      org_id: document?.OrgId,
    });
    let data = {
      first_name: values.First_Name,
      last_name: values.Last_Name,
      email_id: values.Email_Id,
      phone_number: values.Phone_Number,
      mobile_number: values.Mobile_Number,
      org_id: document?.OrgId,
    }
    setEditValue(data);
  }, [values]);

  const contactId = values.Id;

  const getEditInputField = (values) => {
    let old_obj = Object.keys(editValue)?.find(ele => values[ele] !== editValue[ele]);
    return old_obj;
  };

  const editContactDetail = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email_id: '',
      phone_number: '',
      mobile_number: '',
      org_id: document?.OrgId,
    },
    enableReinitialize: true,
    validationSchema: editContactDetailScheme,

    onSubmit: (values) => {
      let edit_value = getEditInputField(values);
      if (!edit_value) {
        toast.warning('No changes');
        return;
      }

      let update_value = {};
      Object.entries(editValue).forEach(([key, value]) => {
        if (value !== values[key]) {
          update_value[key] = values[key];
        };
      });

      dispatch(toggleLoader(true));
      axios.put(process.env.REACT_APP_CONTACT,
        {
          ...update_value,
          id: contactId,
          org_id: document?.OrgId,
        },
        { withCredentials: process.env.REACT_APP_CREDENTIALS }
      )
        .then((res) => {
          dispatch(toggleLoader(false));
          setEditValue(values);
          toast.success('Success', { theme: 'colored' });
          setToggleEditContact(null);
        })
        .catch((err) => {
          toast.error(err.message)
          dispatch(toggleLoader(false))

        }).finally(dispatch(toggleLoader(false)))
    },
  });

  const handleModalClose = () => {
    setToggleEditContact(false);
  }

  return (
    <Box className={styles.main_wrap}>
      <AppHeader moduleText='Detailed Contact' />

      <Box className={styles.edit}>
        <Button onClick={() => { setShow(false) }} className={styles.back_button} variant='outlined' style={{ textTransform: 'unset' }}>
          <ArrowBackIcon className={styles.contact_back} />
          Back
        </Button>
        {(admin || show_integeration?.user_permission[5]?.update === 1) &&
          <Button
            variant='outlined'
            className={styles.edit_button}
            onMouseOver={() => setEditIcon(false)}
            onMouseLeave={() => setEditIcon(true)}
            startIcon={
              editIcon ?
                <img src={process.env.REACT_APP_IMAGE_URL + 'ToggleSvg/editIcon-active.png'} width='15px' height='15px' alt='editIcon'
                  style={{ filter: 'invert(55%) sepia(55%) saturate(575%) hue-rotate(120deg) brightness(91%) contrast(84%)' }} />
                :
                <img src={process.env.REACT_APP_IMAGE_URL + 'ToggleSvg/editIcon-active.png'} width='15px' height='15px' alt='editIcon' />
            }
            onClick={() => setToggleEditContact(true)}
          //   disabled={!checkedId.length}
          >
            Edit
          </Button>}

        {(admin || show_integeration?.user_permission[5]?.delete === 1) &&
          <Button
            variant='outlined'
            className={styles.contact_delete}
            startIcon={<DeleteIcon fontSize={'20px'} className={styles.DeleteIcon} />}
            onClick={() => setShowModal(true)}
          //   disabled={!checkedId.length}
          >
            Delete
          </Button>}
        <DeleteModal
          showModal={showModal}
          closeModal={(val) => setShowModal(val)}
          handleDelete={() => onDelete(id, undefined, 10, 1)}
        />
      </Box>
      <Box className={styles.main_contact_wrap}>
        <Box className={styles.main_contact}>
          <Box className={styles.contact_header}>
            Detailed Information
          </Box>

          <Box className={styles.left_container}>
            <Box className={styles.left_body}>
              <Box className={styles.name}>First Name</Box>
              <Box className={styles.contact_right_text}>
                {editValue?.first_name}
              </Box>
            </Box>
            <Box className={styles.left_body}>
              <Box className={styles.name}>Last Name</Box>
              <Box className={styles.contact_right_text}>
                {editValue?.last_name}
              </Box>
            </Box>
            <Box className={styles.left_body}>
              <Box className={styles.name}>Email</Box>
              <Box className={styles.contact_right_text}>
                {editValue?.email_id}
              </Box>
            </Box>
            <Box className={styles.left_body}>
              <Box className={styles.name}>Mobile </Box>
              <Box className={styles.contact_right_text}>
                {editValue?.mobile_number}
              </Box>
            </Box>
            <Box className={styles.left_body}>
              <Box className={styles.name}>Phone</Box>
              <Box className={styles.contact_right_text}>
                {editValue?.phone_number}
              </Box>
            </Box>
            <Box className={styles.left_body}>
              <Box className={styles.name}>Created By</Box>
              <Box className={styles.contact_right_text}>
                {values.Created_User_Name}
              </Box>
            </Box>
            <Box className={styles.left_body}>
              <Box className={styles.name}>Created Time</Box>
              <Box className={styles.contact_right_text}>
                {createdTime}
              </Box>
            </Box>
            <Box className={styles.left_body}>
              <Box className={styles.name}>Modified By</Box>
              <Box className={styles.contact_right_text}>
                {values.Modified_User_Name}
              </Box>
            </Box>
            <Box className={styles.left_body}>
              <Box className={styles.name}>Modified Time </Box>
              <Box className={styles.contact_right_text}>
                {modifiedTime}
              </Box>
            </Box>
          </Box>
          <ContactTextField
            editContact={true}
            toggleNewContact={toggleEditContact}
            handleModalClose={handleModalClose}
            contactDetail={editContactDetail}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default DetailedContact;
