import { Box, List, ListItem, Tooltip } from "@mui/material";
import React from "react";
import './DeleteModal.css';

export const DeleteModal = (props) => {

    let temp_length = props?.mapped_signs?.length ? props?.mapped_templates?.length >= 6 : props?.mapped_templates?.length >= 10;
    let sign_length = props?.mapped_templates?.length ? props?.mapped_signs?.length >= 6 : props?.mapped_signs?.length >= 10;
    return (
        <div className="delete-modal-mask" style={{ display: props?.showModal ? 'inline-table' : 'none', zIndex: '11', backgroundColor: '#13214440' }}>
            <div className="delete-modal-wrapper">
                <div className="delete-modal-container">
                    <div className="delete-modal-body">
                        {(props?.mapped_templates?.length || props?.mapped_signs?.length) ? <Box className='delete_template_docs'>Linked documents</Box> : null}
                        {props?.mapped_templates?.length ?
                            <Box>
                                <Box className="delete_template_documents" style={{ marginTop: '15px' }}>Template Documents</Box>
                                <Box className='delete-scroll' style={{ overflow: temp_length ? 'scroll' : 'hidden', height: temp_length && 'calc(100vh - 600px)', marginTop: 2 }}>
                                    {props?.mapped_templates?.map((item, i) => {
                                        return (
                                            <Box key={i} className='linked_documents'>
                                                <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
                                                    <Tooltip title={item?.Template_Name} PopperProps={{ disablePortal: true }}>
                                                        <ListItem sx={{ display: 'list-item', textOverflow: 'ellipsis' }} className="delete_template_name">{item?.Template_Name}</ListItem>
                                                    </Tooltip>
                                                </List>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box> : null}
                        {props?.mapped_signs?.length ?
                            <Box>
                                <Box className="delete_template_documents" style={{ marginTop: props?.mapped_templates?.length === 0 ? '15px' : '0px' }}>SendRequest Documents</Box>
                                <Box className='delete-scroll' style={{ overflow: sign_length ? 'scroll' : 'hidden', height: sign_length && 'calc(100vh - 600px)' }}>
                                    {props?.mapped_signs?.map((item, i) => {
                                        return (
                                            <Box key={i} className='linked_documents'>
                                                <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
                                                    <Tooltip title={item?.Template_Name} PopperProps={{ disablePortal: true }}>
                                                        <ListItem sx={{ display: 'list-item', textOverflow: 'ellipsis' }} className="delete_template_name">{item?.Template_Name}</ListItem>
                                                    </Tooltip>
                                                </List>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box> : null}
                        <div style={{ marginTop: props?.mapped_templates?.length ? '15px' : '0px' }} className='delete-modal-text'>Are you sure?</div>
                        <div className='delete-modal-buttons'>
                            <Box variant="outlined" className="delete-container-cancel" onClick={() => props?.closeModal(false)}>Cancel</Box>
                            <Box variant="contained" className="delete-modal" onClick={() => props?.handleDelete()}>Delete</Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};