import * as yup from 'yup';

export const contactsTestData = {
 email: 'rumaro@gmail.com',
 contactName: 'rumaro',
 createdBy: 'rumaro',
 createdTime: '',
};
export const tHead = ['Email', 'Contact Name', 'Created By', 'Created Time'];

export const newContactInit = {
 firstName: '',
 lastName: '',
 email: '',
 phone: '',
 mobile: '',
 createdBy: '',
 createdTime: '',
};

export const contactDetailScheme = yup.object().shape({
 email: yup.string().required('enter valid email').email('enter valid email'),
 firstName: yup.string().required('firstName code is required'),
});

export const getDuplicateData = (data, counts = 1) => {
 let arr = [],
  i = 0;

 for (; i < counts; i++) arr.push(data);
 return arr;
};
