import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import './styles/PDFPreview.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Box, Typography } from "@mui/material";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const bgcolor = ["#377dff1a", "#00c9a71a", "#f5ca991a", "#FFE0D1", "#ed4c781a", "#1321441a", "#F7E2FF", "#FFFDCE", "#CEC1D7", "#D7D7C1", "#ffbcc8cf", "#E0FFF7", "#B8E986", "#DAE4F1", "#97D6D9"];
const circle_color = ["#377DFF", "#00C9A7", "#F5CA99", "#FFC931", "#ED4C78", "#71869D", "#B867DA", "#E8D20D", "#775A8C", "#8A8A5C", "#F86982", "#2EE0AF", "#76B13A", "#3F6CA6", "#0C797D"];

const PDFPreview = (props) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState([]);
    const [file, setFile] = useState('');
    const [tagColor, setTagColor] = useState({});

    const onPageHandler = (val) => {
        props.pageNumber(val);
        setPageNumber(val);
    };

    useEffect(() => {
        let arr = {};
        Object?.keys(props?.formData)?.map((list) => {
            props?.formData[list]?.map((item) => {
                if (!arr[list]) {
                    arr[list] = [item?.background]
                } else if (arr[list].indexOf(item.background) === -1) {
                    arr[list]?.push(item.background);
                }
            });
        })

        setTagColor(arr);
    }, [pageNumber, props?.formData]);

    useEffect(() => {
        setPageNumber(props?.selectPageNumber);
        setFile(props?.file);
    }, [props?.file, props?.selectPageNumber]);

    const onDocumentLoadSuccess = async (numPages) => {
        setNumPages([numPages?.numPages]);
    };

    return (
        <Box className="preview-wrapper" style={{ overflow: numPages > 3 ? 'auto' : 'hidden' }}>
            <Box className="leftbar-container">
                {file && <Document file={file} onLoadSuccess={(num) => onDocumentLoadSuccess(num)}>
                    {Array.apply(null, { length: numPages }).map((val, index) => {
                        return index + 1;
                    }).map((pages, i) => (
                        <Box key={i} className='pdf-preview-container'>
                            <Box className={i + 1 === pageNumber ? "pdf-select-preview" : "pdf-viewer-preview"} onClick={() => onPageHandler(i + 1)}>
                                <Box className="preview-body">
                                    <Page width={70} height={70} pageNumber={pages} />
                                    <Box className="tag-container">
                                        {tagColor[pages]?.map((item, i) => {
                                            let color = item !== '#EDECF7' ? props?.signType !== 'Sign myself' ? circle_color[bgcolor.indexOf(item)] : null : '#EDECF7';
                                            return (
                                                <Box className="tag-body" key={i}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="8" viewBox="0 0 23 8" fill="none">
                                                        <path d="M0.0118625 3.99573L5.46361 0.541749L5.44307 7.46254L0.0118625 3.99573Z" fill={color} />
                                                        <rect x="5.60638" y="0.614746" width="25.3936" height="6.76201" fill={color} />
                                                    </svg>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                                <Box className="page-number">{i + 1}</Box>
                            </Box>
                        </Box>
                    ))}
                </Document>}
            </Box>
        </Box>
    )
};

export default PDFPreview;