import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const schedule_Name = createSlice({
    name: 'scheduleName',
    initialState,
    reducers: {
        scheduleName: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { scheduleName } = schedule_Name.actions;

export default schedule_Name.reducer;