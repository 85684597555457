import { Box, Button, FormControl, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import styles from './PdfDownloadView.module.css'
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const UnlockPdf = ({ downloadPage, changePassword }) => {
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState('')
    const [show, setshow] = useState(true);

    const handlePassword = () => {
        if (password.length) {
            downloadPage(true)
            changePassword(password)
            setErrors({})

        }
        else setErrors({ error: "Please type a password." })
    }

    return (
        <Box className={styles.pdf_right_site}>
            <Box className={styles.pdf_right_done}>
                <Box sx={{ width: "30px" }}>  <img
                    src={process.env.REACT_APP_IMAGE_URL + 'pdfConvertericon/lockicon.svg'}
                    className={styles.box_img}
                    alt='menu-item'
                /></Box>
                <Box className={styles.pdf_right_text}>Remove a Password</Box>
            </Box>
            <Box className={styles.pdf_download_area}>
                <FormControl fullWidth sx={{ m: 1 }}>
                    <TextField
                        error={errors.error}
                        id="outlined-error-helper-text"
                        label="Enter Password"
                        type={show ? 'password' : 'none'}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockOpenIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={() => setshow(!show)}>{show ? < VisibilityIcon /> : < VisibilityOffIcon />}</InputAdornment>)
                        }}
                    />
                </FormControl>

            </Box>
            <Box><Button variant="contained" endIcon={<ArrowForwardIosIcon fontSize='small' />} onClick={() => handlePassword()} fullWidth> Remove Protect  </Button> </Box>

        </Box>
    )
}

export default UnlockPdf