import axios from "axios";

export const getUstat = (data, resp, error) => {
    axios.get(process.env.REACT_APP_USTAT, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};

export const getInit = (data, resp, error) => {
    axios.get(process.env.REACT_APP_INIT, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: data })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};

export const getProperties = (data, resp, error) => {
    axios.post(process.env.REACT_APP_PROPERTIES, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};
