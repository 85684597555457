import axios from "axios";

export const getAdminAccountData = (resp, error) => {
    axios.get(process.env.REACT_APP_USER_HEAD, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};

export const getAdminData = (resp, error) => {
    axios.get(process.env.REACT_APP_USER_TABLE_NAME, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};

export const getDriveData = (resp, error) => {
    axios.get(process.env.REACT_APP_DRIVE_INIT, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};
export const getSeoBotData = (resp, error) => {
    axios.get(process.env.REACT_APP_ADMIN_SEO_BOT, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};