import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const digestDetails = createSlice({
    name: 'digest_details',
    initialState,
    reducers: {
        digestDetail: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { digestDetail } = digestDetails.actions;

export default digestDetails.reducer;