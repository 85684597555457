import { Box, Button, FormControl, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import styles from './PdfDownloadView.module.css'
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ProtectPDF = ({ downloadPage, changePassword }) => {
    const [errors, setErrors] = useState({});
    const [show, setshow] = useState({ mainPassword: true, repeatPassword: true });
    const [password, setPassword] = useState({ mainPassword: '', repeatPassword: '' })


    const handlePassword = () => {
        if (password.mainPassword.length && password.repeatPassword.length) {
            if (password.mainPassword === password.repeatPassword) {
                setErrors({});
                downloadPage(true)
                changePassword(password.mainPassword)

            }
            else setErrors({ error: "These passwords don’t match." })
        }
        else setErrors({ error: "Please type a password." })
    }

    return (
        <Box className={styles.pdf_right_site}>
            <Box className={styles.pdf_right_done}>
                <Box sx={{ width: "30px" }}>  <img
                    src={process.env.REACT_APP_IMAGE_URL + 'pdfConvertericon/lockicon.svg'}
                    className={styles.box_img}
                    alt='menu-item'
                /></Box>
                <Box className={styles.pdf_right_text}>Add a password</Box>
            </Box>
            <Box className={styles.pdf_download_area}>
                <FormControl fullWidth sx={{ m: 1 }}>
                    <TextField
                        error={errors.error}
                        id="outlined-error-helper-text"
                        type={show.mainPassword ? 'password' : 'none'}
                        label="Type password"
                        onChange={(e) => setPassword({ ...password, mainPassword: e.target.value })}
                        value={password.mainPassword}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={() => setshow({ ...show, mainPassword: !show.mainPassword })}>{show.mainPassword ? < VisibilityIcon /> : < VisibilityOffIcon />}</InputAdornment>)
                        }}
                    />
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }}>
                    <TextField
                        error={errors.error}
                        id="outlined-error-helper-text"
                        label="Repeat password"
                        value={password.repeatPassword}
                        type={show.repeatPassword ? 'password' : 'none'}
                        helperText={errors ? errors.error : ''}
                        onChange={(e) => setPassword({ ...password, repeatPassword: e.target.value })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (<InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={() => setshow({ ...show, repeatPassword: !show.repeatPassword })}>{show.repeatPassword ? < VisibilityIcon /> : < VisibilityOffIcon />}</InputAdornment>)
                        }}
                    />
                </FormControl>
            </Box>
            <Box><Button variant="contained" endIcon={<ArrowForwardIosIcon fontSize='small' />} onClick={() => handlePassword()} fullWidth> Protect  </Button> </Box>

        </Box>
    )
}

export default ProtectPDF