import React, { useEffect, useRef, useState } from 'react'
import styles from './PdfEdit.module.css'
import { Box, Button } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import PdfDownloadView from './PdfDownloadView';

const PdfEdit = ({ type }) => {

    const [isDragOver, setIsDragOver] = useState(false);
    const [filetype, setFileType] = useState({ name: "", size: "", format: "" });
    const [file, setFile] = useState({ filevalue: null, conversion_type: '' });
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        clearFiledata()
    }, [type])

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };
    const handleDragStop = (e) => {
        e.preventDefault();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const files = Array.from(e.dataTransfer.files);
        handleFiles(files);
    };

    const handleFileInputChange = (e) => {
        const files = Array.from(e.target.files);
        handleFiles(files);
    };

    const handleFiles = (file) => {
        if (file)
            if (type?.conversion_type >= 6) {
                if (!file[0]?.type?.includes('pdf')) {
                    toast.error('Invalid file format. Please upload a valid file in the appropriate format (e.g. .pdf,)')
                }
                else {
                    setFile({ ...file, filevalue: file[0], conversion_type: type.conversion_type });
                    const fileSizeInMB = convertBytesToMB(file[0].size);
                    const fileSize = `${fileSizeInMB}MB`
                    setFileType({ name: file[0].name, size: fileSize, format: file[0].type })
                }
            }
            else if (!(file[0].type?.includes('png') || file[0].type?.includes('jpg') || file[0].type.includes('csv') || file[0].type.includes('jpeg') || file[0].type.includes('pdf') || file[0].type.includes('vnd.ms-excel') || file[0].type.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet') || file[0].type.includes('msword') || file[0].type.includes('vnd.openxmlformats-officedocument.wordprocessingml.document') || file[0].type.includes('vnd.openxmlformats-officedocument.presentationml.presentation') || file[0].type.includes('vnd.ms-powerpoint'))) {
                toast.error('Invalid file format. Please upload a valid file in the appropriate format (e.g. .jpg, .jpeg, .pdf, .png, ).')
            } else if (file[0].size > 10e6) {
                toast.error('The file you are trying to upload is too large. The maximum file size allowed is 10 MB.');
            } else {
                setFile({ ...file, filevalue: file[0], conversion_type: type.conversion_type });
                const fileSizeInMB = convertBytesToMB(file[0].size);
                const fileSize = `${fileSizeInMB}MB`
                setFileType({ name: file[0].name, size: fileSize, format: file[0].type })
            };
    };

    const convertBytesToMB = (bytes) => {
        return (bytes / (1024 * 1024)).toFixed(2);
    };

    // useEffect(() => {
    //     if (file) {
    //         submitFile()
    //     }
    // }, [file])

    // const submitFile = () => {
    //     setFile({ ...file, conversion_type: type.conversion_type })
    // }

    const clearFiledata = () => {
        setFile({ ...file, filevalue: null });
        setFileType({ name: "", size: "", format: "" })
    }


    return (
        <div style={{ width: "100%", height: "100% " }}>
            {!file.filevalue ? (< div onDragOver={handleDragStop} onDrop={handleDragStop} style={{ height: "100%", width: "100%" }}>
                <div style={{ padding: '30px' }}>
                    <Box className={styles.Upload_head}>
                        <Box className={styles.Upload_head_name}>{type.text}</Box>
                        {type.conversion_type < 6 && < span style={{ paddingBottom: "20px" }} className='formats'>Supported formats: <span className='formats-group'>PDF, Word, JPG, PNG, PPT, Excel</span></span>}
                    </Box>
                    <div className={isDragOver ? styles.upsec_areas : styles.upsec_area}
                        style={{ backgroundColor: isDragOver ? type.color : type.DragColor }}
                        onDragEnter={handleDragEnter}
                        onDragOver={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}>

                        <div style={{ border: '1px dashed #ffff', width: '100%', height: '100%' }} >
                            <div className={styles.browse_inn_part}>
                                <div className={styles.before_upload}>
                                    <img
                                        src={process.env.REACT_APP_IMAGE_URL + 'upload/dropbox-white.svg'}
                                        className={styles.box_img}
                                        alt='menu-item'
                                    />
                                    <div className={styles.upload_file_text}>
                                        {isDragOver ? (
                                            <p>Drop the files here...</p>
                                        ) : (
                                            <p>Drag and Drop your file here</p>)}
                                    </div>
                                    <span className={styles.or}>or</span>
                                    <div className={styles.btn_div}>
                                        <button onClick={() => fileInputRef.current.click()} >Choose file </button>
                                    </div>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={handleFileInputChange}
                                    />
                                </div>
                            </div>
                            {/* {filetype.name ? <div style={{ textAlign: 'left' }}>
                                <div>File Name:{filetype.name}</div>
                                <div>File format:{filetype.format}</div>
                                <div>File size:{filetype.size}</div>
                            </div > : <div></div >
                            } */}
                        </div >
                    </div>
                </div>
            </div>) : (

                <PdfDownloadView file={file} clearFile={clearFiledata} type={type} filetype={filetype} />
            )
            }
        </div >
    );
}
export default PdfEdit