import {integrationDetail} from './integrationReducer';

export const integration_action = (query) => async (dispatch) => {
 try {
  dispatch(integrationDetail(query));
 } catch (err) {
  const error = {
   code: 0,
   message: typeof err == 'object' ? err.message : err,
  };
  throw error;
 }
};
