import React, { useEffect, useState } from 'react';
import styles from './SignSelf.module.css';
import CustomTable from '../../component/customTable/CustomTable';
import AppHeader from '../../component/appHeader/AppHeader';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box, debounce, useMediaQuery } from '@mui/material';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { Navigation } from '../../utils/handleNavigation';
import { toast } from 'react-toastify';
import { signId_action } from '../../store/signIDDetails/signId_action';
import { getTemplatePreview } from '../../Api/TemplatePreviewApi';
import Skeleton from '@mui/material/Skeleton';
import MobileView from '../../component/MobileView';

const tHead = ['Template Name', 'Status', 'Source', 'Receiver', 'Created Time'];
const keys = ['Template_Name', 'Status', 'source_name', 'Email_Id', 'Created_Time'];

const signStatus = [
  { status: 'Declined', color: '#F72222' },
  { status: 'Sent', color: ' #2C47CB' },
  { status: 'Progress', color: '#AB6BFF' },
  { status: 'Completed', color: ' #23AB94' },
  { status: 'Declined', color: '#F72222' },
  { status: 'Expired', color: '#F38435' },
  { status: 'Draft', color: ' #F5CA99' },
  { status: 'Viewed', color: ' #AB6BFF' },
  { status: 'Recall', color: ' #56BAE1' },
];

const SignSelf = () => {
  const dispatch = useDispatch();
  const navPath = Navigation();
  const mobile_view = useMediaQuery('(min-width: 600px)');

  const document = useSelector((state) => state?.document?.value);
  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
  const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');


  const [checkedId, setCheckedId] = useState([]);
  const [selfSignDetails, setSelfSignDetails] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [pageShow, setPageShow] = useState(false);
  const [pageIndex, setPageIndex] = useState(false);
  const [page_idx, setPage_idx] = useState(0);
  const [page_size, setPage_size] = useState(10);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    fetchSignMySelf();
  }, []);

  const fetchSignMySelf = (idx = 0, size = 10, val, key) => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_SIGNMYSELF, {
      params: {
        org_id: document?.OrgId,
        limit: size ?? 10,
        ...(idx > 0 ? { cursor: key ? idx : idx + 1 } : { cursor: 1 }),
        ...(val?.length > 0 ? { search: val } : {}),
      }, withCredentials: process.env.REACT_APP_CREDENTIALS
    })
      .then((res) => {
        const data = res.data.sign_for_myself_list.map((o) => {
          const { Status, Modified_Time, Created_Time, ...restData } = o;

          const statusEl = (
            <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Box
                className={styles.custom_dot}
                style={{ backgroundColor: signStatus[Status].color }}
              ></Box>
              {signStatus[Status].status}
            </Box>
          );

          return {
            ...restData,
            Modified_Time: Modified_Time,
            Created_Time: Created_Time,
            Status: statusEl,
            statustype: Status,
          };
        });
        setSelfSignDetails(data);

        if (idx === 0) {
          setTotalRecord(res.data.total_record);
        }
        setPageShow(true);

        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))

      });
    // .finally(() => dispatch(toggleLoader(false)));
  };

  const debouncedSendRequest = debounce(fetchSignMySelf, 800);

  const handleSearch = (idx = 0, size = 10, val) => {
    const encode = encodeURIComponent(val);
    debouncedSendRequest(idx = 0, size = 10, encode);
  };

  const deleteSignMySelf = (_id, resetId = () => { }, pageSize, cursor) => {
    dispatch(toggleLoader(true));
    const id = _id ? [_id] : checkedId;

    let data = JSON.stringify({
      org_id: document?.OrgId,
      ids: id,
      limit: pageSize,
      cursor: cursor,
    });

    const headers = {
      'Content-Type': 'application/json',
    };

    axios.delete(process.env.REACT_APP_SIGNMYSELF, { headers: headers, data: data, withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        resetId();
        const data = res.data.sign_for_myself_list.map((o) => {
          const { Status, Modified_Time, Created_Time, ...restData } = o;

          const statusEl = (
            <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <Box
                className={styles.custom_dot}
                style={{ backgroundColor: signStatus[Status].color }}
              ></Box>
              {signStatus[Status].status}
            </Box>
          );

          return {
            ...restData,
            Modified_Time: Modified_Time,
            Created_Time: Created_Time,
            Status: statusEl,
            statustype: Status,
          };
        });

        if (data?.length) {
          setSelfSignDetails(data);
        } else {
          fetchSignMySelf(cursor - 1, pageSize, null, 'delete');
          setPageIndex(true);
        }
        setTotalRecord(totalRecord - id?.length);
        setPageShow(true);
        dispatch(toggleLoader(false));
        toast.success('Success');
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))

      })
      .finally(() => dispatch(toggleLoader(false)));
  };

  const convertKeysToLowerCase = (obj) => {
    const newObj = {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const lowerCaseKey = key.toLowerCase();
        newObj[lowerCaseKey] = obj[key];
      }
    }
    return newObj;
  };

  const handleSignClick = (id) => {
    if (signStatus[id?.statustype].status === 'Draft') {
      if (mobile_view) {
        dispatch(toggleLoader(true));
        dispatch(signId_action(null));
        getTemplatePreview(id, dispatch, (res) => {
          let data = res.data;
          const newObj = convertKeysToLowerCase(id);
          dispatch(signId_action(newObj));
          navPath(`/sign-myself/${data.template_information.Id}?id=${id?.Id}&action=draft`, 'document_reload');
        }, (err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
      } else {
        setMobileView(true);
      }
    } else {
      navPath(`/sign-myself-details/${id?.Id}`);
      dispatch(toggleLoader(true));
    }
  };

  return (
    <>
      {pageShow ? (
        <Box className={styles.main_wrap}>
          <>
            {/* {show_integeration?.user_permission[4].create === 1 && */}
            <AppHeader
              btnText='Upload'
              moduleText='My Sign Requests'
              onBtnClick={() => navPath('/upload')}
              checkAdmin={admin}
              rolePermissions={show_integeration?.user_permission[1]}
            />
            {/* } */}
            <CustomTable
              getPageIndexSize={({ pageIdx, pageSize }) => {
                setPage_idx(pageIdx);
                setPage_size(pageSize);
                fetchSignMySelf(pageIdx, pageSize);
              }}
              onSearch={({ pageIdx, pageSize, value }) =>
                handleSearch(0, pageSize, value)
              }
              page_idx={page_idx}
              page_size={page_size}
              totalRecordCount={totalRecord}
              pageDeleteIndex={pageIndex}
              pageSetIndex={(val) => setPageIndex(val)}
              searchName='Search Sign Request'
              keys={keys}
              head={tHead}
              body={selfSignDetails}
              onDelete={deleteSignMySelf}
              getCheckedId={setCheckedId}
              onRowClick={(val) => {
                (admin || (show_integeration?.user_permission[4]?.update === 1 || show_integeration?.user_permission[4]?.read === 1)) && handleSignClick(val)
              }}
              checkAdmin={admin}
              rolePermissions={show_integeration?.user_permission[4]}
            />
          </>
          <MobileView mobileView={mobileView} closeMobileView={() => setMobileView(false)} />
        </Box>
      ) : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 80px)'} />}
    </>
  );
};

export default SignSelf;
