import {createSlice} from '@reduxjs/toolkit';

const initialState = {
 value: 0,
};

export const appReducer = createSlice({
 name: 'app',
 initialState,
 reducers: {
  showLoader: (state, action) => {
   state.value = action.payload;
  },
 },
});

export const {testAction} = appReducer.actions;

export default appReducer.reducer;

// dispatch(testAction(1))
