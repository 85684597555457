import { Box, Button, Modal } from "@mui/material";
import styles from './MobileView.module.css';

const MobileView = ({ mobileView, closeMobileView }) => {
    return (
        <Modal
            open={mobileView}
            sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
            closeAfterTransition
        >
            <Box className={styles.new_limit_wrap}>
                <Box className={styles.text}>Please use the web view to drag and drop fields.</Box>
                <Box className={styles.button_container}><Button variant='contained' onClick={closeMobileView}>ok</Button></Box>
            </Box>
        </Modal>
    )
};

export default MobileView;