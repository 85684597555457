import React, { useState } from "react";
import { Box, Button, IconButton, Modal, TextField } from '@mui/material';
import styles from './contacts.module.css';
import CloseIcon from '@mui/icons-material/Close';

const ContactTextField = (props) => {
    const { toggleNewContact, handleModalClose, contactDetail, editContact, loading } = props;
    return (
        <Modal
            open={toggleNewContact ?? false}
            sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
            closeAfterTransition
        >
            <Box className={styles.new_contact_wrap}>
                <IconButton
                    className={styles.close}
                    onClick={() => handleModalClose()}
                >
                    <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                </IconButton>

                <Box
                    component='h2'
                    className={styles.new_contact}
                >
                    <span>{editContact ? 'Edit Contact' : 'New Contact'}</span>
                </Box>

                <Box className={styles.name_textfield}>
                    <Box className={styles.new_contact_field_wrap} style={{ width: '45%' }}>
                        <TextField
                            onChange={contactDetail.handleChange}
                            placeholder='First Name *'
                            value={contactDetail.values.first_name}
                            error={contactDetail.touched.first_name && contactDetail.errors.first_name ? true : false}
                            helperText={contactDetail.touched.first_name && contactDetail.errors.first_name}
                            name='first_name'
                            sx={{
                                '& .MuiInput-underline:before': { borderBottom: '0.0625rem solid #e7e7e7' },
                                '& .MuiInput-underline:after': { borderBottom: '0.0625rem solid var(--primary)' },
                                "& .MuiInput-underline:hover:before": { borderBottom: '0.0625rem solid #e7e7e7 !important' },
                                "& .MuiInput-underline:hover:after": { borderBottom: '0.0625rem solid var(--primary) !important' },
                                input: {
                                    color: "#62637a",
                                    fontSize: '14px',
                                    padding: '10px 0',
                                },
                            }}
                            variant='standard'
                            autoFocus
                            fullWidth
                        />
                    </Box>
                    <Box className={styles.new_contact_field_wrap} style={{ width: '45%' }}>
                        <TextField
                            onChange={contactDetail.handleChange}
                            value={contactDetail.values.last_name}
                            placeholder='Last Name'
                            sx={{
                                '& .MuiInput-underline:before': { borderBottom: '0.0625rem solid #e7e7e7' },
                                '& .MuiInput-underline:after': { borderBottom: '0.0625rem solid var(--primary)' },
                                "& .MuiInput-underline:hover:before": { borderBottom: '0.0625rem solid #e7e7e7 !important' },
                                "& .MuiInput-underline:hover:after": { borderBottom: '0.0625rem solid var(--primary) !important' },
                                input: {
                                    color: "#62637a",
                                    fontSize: '14px',
                                    padding: '10px 0',
                                },
                            }}
                            variant='standard'
                            name='last_name'
                            fullWidth
                        />
                    </Box>
                </Box>

                <Box className={styles.new_contact_field_wrap}>
                    <TextField
                        onChange={contactDetail.handleChange}
                        value={contactDetail.values.email_id}
                        placeholder='Email *'
                        error={
                            contactDetail.touched.email_id && contactDetail.errors.email_id
                                ? true
                                : false
                        }
                        helperText={
                            contactDetail.touched.email_id && contactDetail.errors.email_id
                        }
                        sx={{
                            '& .MuiInput-underline:before': { borderBottom: '0.0625rem solid #e7e7e7' },
                            '& .MuiInput-underline:after': { borderBottom: '0.0625rem solid var(--primary)' },
                            "& .MuiInput-underline:hover:before": { borderBottom: '0.0625rem solid #e7e7e7 !important' },
                            "& .MuiInput-underline:hover:after": { borderBottom: '0.0625rem solid var(--primary) !important' },
                            input: {
                                color: "#62637a",
                                fontSize: '14px',
                                padding: '10px 0',
                            },
                        }}
                        variant='standard'
                        name='email_id'
                        fullWidth
                    />
                </Box>

                <Box className={styles.new_contact_field_wrap}>
                    <TextField
                        onChange={contactDetail.handleChange}
                        value={contactDetail.values.phone_number}
                        placeholder='Phone'
                        name='phone_number'
                        sx={{
                            '& .MuiInput-underline:before': { borderBottom: '0.0625rem solid #e7e7e7' },
                            '& .MuiInput-underline:after': { borderBottom: '0.0625rem solid var(--primary)' },
                            "& .MuiInput-underline:hover:before": { borderBottom: '0.0625rem solid #e7e7e7 !important' },
                            "& .MuiInput-underline:hover:after": { borderBottom: '0.0625rem solid var(--primary) !important' },
                            input: {
                                color: "#62637a",
                                fontSize: '14px',
                                padding: '10px 0',
                            },
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                display: 'none'
                            },
                            '& input[type=number]': {
                                MozAppearance: 'textfield'
                            }
                        }}
                        variant='standard'
                        type="number"
                        fullWidth
                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                        inputProps={{ maxLength: 10 }}
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, e.target.maxLength)
                        }}
                    />
                </Box>

                <Box className={styles.new_contact_field_wrap}>
                    <TextField
                        onChange={contactDetail.handleChange}
                        value={contactDetail.values.mobile_number}
                        placeholder='Mobile'
                        name='mobile_number'
                        fullWidth
                        sx={{
                            '& .MuiInput-underline:before': { borderBottom: '0.0625rem solid #e7e7e7' },
                            '& .MuiInput-underline:after': { borderBottom: '0.0625rem solid var(--primary)' },
                            "& .MuiInput-underline:hover:before": { borderBottom: '0.0625rem solid #e7e7e7 !important' },
                            "& .MuiInput-underline:hover:after": { borderBottom: '0.0625rem solid var(--primary) !important' },
                            input: {
                                color: "#62637a",
                                fontSize: '14px',
                                padding: '10px 0',
                            },
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                display: 'none'
                            },
                            '& input[type=number]': {
                                MozAppearance: 'textfield'
                            }
                        }}
                        variant='standard'
                        type="number"
                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                        inputProps={{ maxLength: 10 }}
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, e.target.maxLength)
                        }}
                    />
                </Box>
                <Button
                    className={styles.add_btn}
                    fullWidth
                    variant='contained'
                    onClick={contactDetail.handleSubmit}
                    disabled={loading}
                >
                    {editContact ? 'Update' : 'Add Contact'}
                </Button>
            </Box>
        </Modal>
    )
};

export default ContactTextField;