
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    driveinfo: '',
};


export const adminDriveDetails = createSlice({
    name: "admin_drive_info",
    initialState,
    reducers: {
        adminDriveDetail: (state, action) => {
            state.driveinfo = action?.payload?.data

        },
    },
});
export const { adminDriveDetail } = adminDriveDetails.actions;


export default adminDriveDetails.reducer;
