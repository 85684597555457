import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  planName: null,
  planType: null,
  price: null,
};

export const paymentDetails = createSlice({
  name: "payment_details",
  initialState,
  reducers: {
    paymentDetail: (state, action) => {
      state.planName = action.payload.planName;
      state.planType = action.payload.planType;
      state.price = action.payload.price;
    },
  },
});
export const { paymentDetail } = paymentDetails.actions;

export default paymentDetails.reducer;
