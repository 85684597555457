import axios from "axios";

export const getDocumentDelete = (data, resp, error) => {

    const headers = {
        'Content-Type': 'application/json',
      };

    axios.delete(process.env.REACT_APP_DOCUMENT, {
        headers: headers,
        data: data,
        withCredentials: process.env.REACT_APP_CREDENTIALS,
      })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};