import { Box, Button, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { digest_action } from "../store/digest/digest_action";
import { toggleLoader } from "../store/loader/loaderReducer";
import CloseIcon from '@mui/icons-material/Close';

const PDFDecline = (props) => {
    const [modal, setModal] = useState(false);
    const [closePopup, setClosePopup] = useState(false);
    const [declineReason, setDeclineReason] = useState('');
    const [decline, setDecline] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const digestDetails = useSelector((state) => state?.digestDetails?.value);
    const draftType = window.location.href.split('?')[1]?.length;

    let url = window.location.href;
    let digest = draftType ? url.split('=')[1] : digestDetails;

    useEffect(() => {
        setModal(props?.showModal);
        setClosePopup(props?.closePopup);
    }, [props?.closePopup, props?.showModal]);

    const handleDeclineHandler = () => {
        if (declineReason?.length !== 0) {
            setLoading(true);
            dispatch(toggleLoader(true));
            let options = {
                decline_reason: declineReason,
                digest: decodeURIComponent(digest)
            }
            axios.post(process.env.REACT_APP_SIGNDECLINE, options, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    setClosePopup(true);
                    setLoading(false);
                    dispatch(digest_action(null));
                    setDecline(true);
                    dispatch(toggleLoader(false));
                }).catch((err) => {
                    dispatch(toggleLoader(false));
                })
        } else {
            toast.error('Please enter a valid reason for decline.');
        }
    };

    const handleDownload = () => {
        dispatch(toggleLoader(true));
        setLoading(true);
        axios.get(process.env.REACT_APP_SIGNDOWNLOAD, { params: { digest: decodeURIComponent(props?.downloadKey) }, responseType: 'arraybuffer', withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                setLoading(false);
                dispatch(toggleLoader(false));
                dispatch(digest_action(null));
                const blobFile = new Blob([res?.data], { type: "application/pdf" });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blobFile);
                link.download = props?.templateName?.Template_Name.split('.')[0] + '.pdf';
                link.click();
            }).catch((err) => {
                toast.error(err.message);
                dispatch(toggleLoader(false));
            })
    };

    return (
        <div className="decline-mask" style={{ display: modal ? 'inline-table' : 'none' }}>
            <div className="decline-wrapper">
                <div className="decline-container">
                    {!closePopup ?
                        <>
                            <div className="decline-popup" onClick={() => [setModal(false), props?.modal(false)]}>
                                <IconButton >
                                    <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                                </IconButton>
                            </div>
                            <div className="decline-body">
                                <div className='decline-text'>Decline E-Sign Request</div>
                                <div className="decline-textarea">
                                    <textarea
                                        className='decline-desc'
                                        value={declineReason}
                                        onChange={(e) => setDeclineReason(e.target.value)}
                                        placeholder='Reason for decline'
                                    />
                                </div>
                                <Box className='decline-buttons'>
                                    <Button className="decline-submit" disabled={loading} variant="contained" onClick={() => handleDeclineHandler()}>Submit</Button>
                                </Box>
                            </div>
                        </>
                        :
                        <div className="decline-body" style={{ textAlign: 'center' }}>
                            <div className='decline-text'>Thank You!</div>
                            <div className="decline-success">Your request has been submitted successfully.</div>
                            {!decline && <Box className={props?.downloadKey ? 'decline-buttons' : 'decline-message'}>
                                {props?.downloadKey ? <Button className="document-download" variant="outlined" disabled={loading} onClick={() => props?.downloadKey && handleDownload()}>Download</Button> : <Box className="download-message">Expect to get the document by email. Once it's ready, we'll send it your way.</Box>}
                            </Box>}
                        </div>}
                </div>
            </div>
        </div>
    )
};

export default PDFDecline;