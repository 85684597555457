import { Box, Button, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const Reschedule = () => {
    const [schedule, setSchedule] = useState({ job_id: "", schedule_time: 0, plan_type: 'Account' })
    const dispatch = useDispatch();

    const handleSchedule = () => {
        let data = { job_id: schedule.job_id, schedule_time: 0 }
        dispatch(toggleLoader(true));
        if (schedule.plan_type === 'Account') {
            axios.post(process.env.REACT_APP_USER_RESCHEDULE_ACCOUNT, data)
                .then((res) => {
                    dispatch(toggleLoader(false));
                }).catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.response.data);
                })
        }
        else
            axios.post(process.env.REACT_APP_USER_RESCHEDULE_SIGN, data)
                .then((res) => {
                    dispatch(toggleLoader(false));
                }).catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.response.data);
                })

    }

    return (
        <Box sx={{ padding: "40px", width: '575px' }}>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }}>job Id:</Box>
                <FormControl size="small" >
                    <OutlinedInput
                        type='Number'
                        style={{ height: 35, width: '250px' }}
                        value={schedule.job_id}
                        onChange={(e) => setSchedule({ ...schedule, job_id: Number(e.target.value) })}
                        placeholder='Enter job Id' />
                </FormControl>
            </Box>
            {/* <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '30px', width: '200px', textAlign: 'center' }}>Schedule Time:</Box>
                <FormControl size="small" >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['TimePicker']}>
                            <TimePicker
                                style={{ height: 35, width: '250px' }}
                                onChange={(e) => setSchedule({ ...schedule, schedule_time: 0 })}
                                // value={schedule.schedule_time}
                                defaultValue={dayjs(new Date())}
                                label="Schedule Time" />
                        </DemoContainer>
                    </LocalizationProvider>
                </FormControl>
            </Box> */}
            {/* <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '30px', width: '200px', textAlign: 'center' }}>Date:</Box>
                <FormControl size="small">
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker
                                defaultValue={dayjs(new Date())}
                                size="small"
                                label="Without view renderers"
                                viewRenderers={{
                                    hours: null,
                                    minutes: null,
                                    seconds: null,
                                }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </FormControl>
            </Box> */}
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Schedule Time:</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={schedule.schedule_time}
                        onChange={(e) => setSchedule({ ...schedule, schedule_time: e.target.value })}
                        // className={styles.admin_}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={0}>Now</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Plan Type:</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={schedule.plan_type}
                        onChange={(e) => setSchedule({ ...schedule, plan_type: e.target.value })}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={'Account'}>Account</MenuItem>
                        <MenuItem value={'Sign'}>Sign</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                <Button variant="contained" onClick={handleSchedule}>Submit</Button>
            </Box>
        </Box>
    )
}

export default Reschedule