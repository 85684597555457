import React from 'react';
import styles from './ScheduleHome.module.css';
import { Box, Typography } from '@mui/material';
import Calender from '../Component/Calender/Calender';

const ScheduleHome = () => {
    return (
        <Box>
            <Box className={styles.main_content_book}>
                <img
                    src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Centilio.png'}
                    alt='welcome-img'
                    style={{ width: '30px', height: '30px' }}
                />
                <Typography className={styles.centilio_sign}>
                    Centilio Sign
                </Typography>
            </Box>
            <Box className={styles.main_schedule_home}>
                <Calender />
            </Box>
        </Box>
    )
};

export default ScheduleHome