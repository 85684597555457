import { Box } from "@mui/system";
import React, { useRef, useState } from "react";
import { Button, IconButton, Modal, Typography } from '@mui/material';
import styles from '../pages/settings/settings.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Cropper from "react-cropper";
import SignaturePad from 'react-signature-pad-wrapper';
import { activeAllowedType, allowedFileTypes } from "../utils/uploadFileFormat";
import { toast } from "react-toastify";
import html2canvas from 'html2canvas';

const initialImage =
    'https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg';

const SignatureField = (props) => {
    const { toggleNewContact, getToggleNewContact, handleSubmit, getText, getGetImage, getType, getColor, getFont, getImageType } = props;

    const [toggleUploadImage, setToggleUploadImage] = useState(false);
    const [type, setType] = useState('draw');
    const [color, setColor] = useState('#000');
    const [font, setFont] = useState('Square Peg');
    const [imgType, setImgType] = useState('');
    const [image, setImage] = useState(initialImage);
    const [isDragOver, setIsDragOver] = useState(false);

    let signaturePadRef = useRef(null);
    const cropperRef = useRef();
    const signatureTypeRef = useRef();

    const handleColorChange = (e) => {
        setColor(e);
        getColor(e);
        signaturePadRef.current.signaturePad.clear();
        signaturePadRef.current.signaturePad.penColor = e;
    };

    const onClear = (e) => {
        signaturePadRef.current.signaturePad.clear();
    };

    const handleOnchange = (e) => {
        getText(e);
    };

    const uploadFile = (e) => {
        // const file = e.target.files[0];
        const url = URL.createObjectURL(e);
        const fileExtension = e?.name?.split(".")?.at(-1);
        if (!allowedFileTypes.includes(fileExtension)) {
            toast.error('Only image format is accepted');
        } else {
            setImgType(e.name);
            getImageType(e.name);
            setImage(url);
            setToggleUploadImage(true);
        }
    };

    const handleSignature = (e) => {
        e.stopPropagation();
        // setColor('#000');
        if (type === 'type') {
            const sign_pad_containerEl = document.getElementById('signature_pad_container');
            sign_pad_containerEl.style.color = color;
            sign_pad_containerEl.style.fontFamily = font;
            sign_pad_containerEl.style.translate = 'unset';
            sign_pad_containerEl.style.minHeight = '100px';
            sign_pad_containerEl.style.translate = '100px';
            sign_pad_containerEl.style.fill = color;
            sign_pad_containerEl.textContent = signatureTypeRef.current.value;
            html2canvas(sign_pad_containerEl).then(
                (canvas) => {
                    const imageDataURL = canvas.toDataURL('image/png');
                    if (sign_pad_containerEl?.textContent) {
                        handleSubmit(imageDataURL);
                        getGetImage(imageDataURL);
                    }
                }
            );
        } else if (type === 'upload') {
            if (typeof cropperRef.current?.cropper !== 'undefined' && toggleUploadImage) {
                let data = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
                const fileExtension = imgType?.split(".")?.at(-1);
                if (!activeAllowedType.includes(fileExtension)) {
                    toast.error('Invalid Image format');
                } else {
                    handleSubmit(data);
                    setImage(data)
                    getGetImage(data);
                }
            }
            setToggleUploadImage(false);

        } else {
            let empty = signaturePadRef?.current?.signaturePad?.isEmpty();
            let data = signaturePadRef.current.toDataURL('image/png');
            if (!empty) {
                handleSubmit(data);
                getGetImage(data);
            }
        }
    };

    const handleCancel = (i) => {
        setToggleUploadImage(false);
    };

    const handleClosePopup = (e, value) => {
        e.stopPropagation();
        getToggleNewContact(value);
        setColor('#000');
    }
    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const files = Array.from(e.dataTransfer.files);
        uploadFile(files[0])
    };

    return (
        <Box>
            <Modal
                open={toggleNewContact}
                // onClose={() => setToggleNewContact(false)}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                closeAfterTransition
            >
                <div
                    className={
                        styles.new_contact_wrap + ' ' + styles.signature + ' ' + styles.popup
                    }
                    style={{ maxWidth: '500px', padding: '45px' }}
                >
                    <div className={styles.pop_close}>
                        <IconButton
                            className={styles.close}
                            onClick={(e) => handleClosePopup(e, false)}
                        >
                            <CloseIcon sx={{ "&:hover": { color: "#ff094b" } }} />
                        </IconButton>
                    </div>
                    <div className={styles.popup_title}>
                        <span>Signature</span>
                    </div>
                    <div className={styles.popup_cont}>
                        <div className={styles.popup_row + ' ' + styles.s_menu_type}>
                            <span
                                className={styles.draw + ' ' + styles.link}
                                style={{
                                    color: type === 'draw' ? 'var(--primary)' : 'var(--table-font-color)',
                                    borderBottom: type === 'draw' && '2px solid var(--primary)',
                                }}
                                type='button'
                                onClick={() => [setType('draw'), getType('draw')]}
                            >
                                Draw
                            </span>
                            <span
                                className={styles.type + ' ' + styles.link}
                                style={{
                                    color: type === 'type' ? 'var(--primary)' : 'var(--table-font-color)',
                                    borderBottom: type === 'type' && '2px solid var(--primary)',
                                }}
                                type='button'
                                onClick={() => [setType('type'), getType('type')]}
                            >
                                Type
                            </span>
                            <span
                                className={styles.upload + ' ' + styles.link}
                                style={{
                                    color: type === 'upload' ? 'var(--primary)' : 'var(--table-font-color)',
                                    borderBottom: type === 'upload' && '2px solid var(--primary)',
                                }}
                                type='button'
                                onClick={() => [setType('upload'), getType('upload')]}
                            >
                                Upload
                            </span>
                        </div>
                    </div>
                    <div onDragEnter={handleDragEnter}
                        onDragOver={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        className={styles.sign_area}
                        style={{ backgroundColor: isDragOver ? "#eeecfb" : 'white' }}
                    >
                        {type === 'draw' ? (
                            <div className={styles.draw_area + ' ' + styles.draw}>
                                <div className={styles.top_bar}>
                                    <div className={styles.select_pen}>
                                        <div
                                            className={
                                                color === '#000' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.black}
                                                style={{ position: 'absolute' }}
                                                onClick={() => handleColorChange('#000')}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#1e87ca' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.blue}
                                                onClick={() => handleColorChange('#1e87ca')}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#ac3235' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.red}
                                                onClick={() => handleColorChange('#ac3235')}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#108473' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.green}
                                                onClick={() => handleColorChange('#108473')}
                                            ></span>
                                        </div>
                                    </div>
                                    <div className={styles.clear_pad} onClick={onClear}>
                                        <span id={styles.clear}>clear</span>
                                    </div>
                                </div>
                                <div className={styles.draw_sign}>
                                    <SignaturePad
                                        height={167}
                                        options={{ minWidth: 1, maxWidth: 3, penColor: ' #000' }}
                                        ref={signaturePadRef}
                                    />
                                </div>
                            </div>
                        ) : type === 'type' ? (
                            <div className={styles.draw_area + ' ' + styles.draw}>
                                <div className={styles.top_bar}>
                                    <div className={styles.select_pen}>
                                        <div
                                            className={
                                                color === '#000' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.black}
                                                onClick={() => [setColor('#000'), getColor('#000')]}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#1e87ca' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.blue}
                                                onClick={() => [setColor('#1e87ca'), getColor('#1e87ca')]}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#ac3235' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.red}
                                                onClick={() => [setColor('#ac3235'), getColor('#ac3235')]}
                                            ></span>
                                        </div>
                                        <div
                                            className={
                                                color === '#108473' ? styles.active_font_color : styles.font_color
                                            }
                                        >
                                            <span
                                                className={styles.green}
                                                onClick={() => [setColor('#108473'), getColor('#108473')]}
                                            ></span>
                                        </div>
                                    </div>
                                    <span className={styles.select_font + ' ' + styles.sl_type}>
                                        <select
                                            style={{ fontFamily: `${font}`, marginTop: '10px' }}
                                            onClick={(e) => [setFont(e.target.value), getFont(e.target.value)]}
                                            readOnly
                                            className={styles.select}
                                        >
                                            <option value='Square Peg' style={{ fontFamily: 'Square Peg' }}>
                                                Hello Testss
                                            </option>
                                            <option value='Caveat' style={{ fontFamily: 'Caveat' }}>
                                                Hello Test
                                            </option>
                                            <option value='Bad Script' style={{ fontFamily: 'Bad Script' }}>
                                                Hello Test
                                            </option>
                                        </select>
                                    </span>
                                </div>
                                <div className={styles.font_sign}>
                                    <input
                                        type='text'
                                        ref={signatureTypeRef}
                                        placeholder='Type here'
                                        style={{ fontFamily: `${font}`, color: `${color}` }}
                                        onChange={(e) => handleOnchange(e.target.value)}
                                    />
                                    <div id="signature_pad_container" className={styles.signature_type_wrapper}></div>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.upload_area + ' ' + styles.stylesupload}>
                                <Box className={styles.sign_upload_img}>
                                    <Box className={styles.browse + ' ' + styles.inn_part}>
                                        <Typography className={styles.drop_file_text}>Drop your file here</Typography>
                                        <span className={styles.or}>or</span>
                                        <input
                                            id='uploadss'
                                            accept="image/*"
                                            alt='image'
                                            onChange={(e) => uploadFile(e.target.files[0])}
                                            type='file'
                                            hidden
                                        />
                                        <label
                                            className={styles.btn + ' ' + styles.upload_sign}
                                            htmlFor='uploadss'
                                        >
                                            Choose File
                                        </label>
                                    </Box>
                                </Box>
                            </div>
                        )}
                    </div>
                    <div
                        className={styles.sign_terms}
                        style={{
                            fontFamily: 'var(--primary-font-family)',
                            fontWeight: 400,
                            color: '#193037',
                            fontSize: '14px',
                        }}
                    >
                        By signing this document with an electronic signature, I agree that such
                        signature will be as valid as handwritten signatures and considered
                        originals to the extent allowed by applicable law.
                    </div>
                    <Button disabled={toggleUploadImage} onClick={(e) => handleClosePopup(e, false)} className={styles.cancel} variant="outlined">
                        Cancel
                    </Button>

                    <Button disabled={toggleUploadImage} className={styles.submit} onClick={(e) => handleSignature(e)} variant="contained">
                        Submit
                    </Button>
                </div>
            </Modal>
            <Modal
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '360px',
                    borderRadius: '10px',
                    padding: '30px',
                    overflow: 'hidden',
                }}
                open={toggleUploadImage}
                onClose={() => setToggleUploadImage(false)}
                closeAfterTransition
            >
                <Box style={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    width: '100%', height: '100%', padding: '30px', overflow: 'hidden', backgroundColor: '#d4e9ff', zIndex: 1
                }}>
                    <Cropper
                        // width={300}
                        // height={170}
                        ref={cropperRef}
                        zoomTo={0}
                        initialAspectRatio={16 / 9}
                        // crop={true}
                        style={{ background: 'transparent', height: '80%', width: "100%" }}
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={true}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                    />
                    <Box style={{ display: 'flex', gap: '40px', justifyContent: 'space-around', marginTop: '30px' }}>
                        <Button variant='contained' className={styles.submit} onClick={(e) => handleSignature(e)}>Save</Button>
                        <Button variant='contained' onClick={() => handleCancel()} className={styles.cropper_cancel}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
};

export default SignatureField;