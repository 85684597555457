import React, { useEffect, useRef, useState } from 'react';
import './WelcomePage.css';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CardActionArea, CardContent, CardMedia, IconButton, MenuItem, Modal, Select, TextField, Tooltip, Typography, tooltipClasses, useMediaQuery } from '@mui/material';
import { document_action } from '../../store/documentDetails/document_action';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomSelect from '../../component/CustomSelect/CustomSelect';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { Navigation } from '../../utils/handleNavigation';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Card from '@mui/material/Card';
import moment from 'moment';
import { template_preview_action } from '../../store/templatePreview/templatePreviewAction';
import { DeleteModal } from '../../component/DeleteModal';
import { getDocumentDelete } from '../../Api/DocumentDeleteApi';
import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Skeleton from '@mui/material/Skeleton';
import ShareIcon from '@mui/icons-material/Share';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { signId_action } from '../../store/signIDDetails/signId_action';
import { Loader } from '../../component/loader/loader';
import '../WelcomePage/Upload.css';
import { getDocuments } from '../../Api/DocumentApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { folder_id_action } from '../../store/folderId/folderIdAction';
import { crm_param_action } from '../../store/crmParam/crmParamAction';
import MobileView from '../../component/MobileView';

const options = [
  { value: 'Created by me', label: 'Created by me' },
  { value: 'Create ', label: 'Create' },
  { value: 'Created', label: 'Created' },
];

const navigatePage = ['File Upload', 'AITemplate', 'New Folder'];
const skeleton_options = ['1', '2', '3', '4', '5', '6', '7', '8'];

const folder_options = [
  // { name: 'Share folder', icon: <ShareIcon sx={{ fontSize: 15, color: 'var(--table-header-font)' }} /> },
  // { name: 'Move to', icon: <DriveFileMoveIcon sx={{ fontSize: 15, color: 'var(--table-header-font)' }} /> },
  { name: 'Rename', icon: <DriveFileRenameOutlineIcon sx={{ fontSize: 15, color: 'var(--table-header-font) !important', opacity: .7 }} /> },
  { name: 'Delete', icon: <DeleteIcon sx={{ fontSize: 15, color: 'var(--table-header-font) !important', opacity: .7 }} /> }
];

const file_options = [
  // { name: 'Share folder', icon: <ShareIcon sx={{ fontSize: 15, color: 'var(--table-header-font)' }} /> },
  // { name: 'Move to', icon: <DriveFileMoveIcon sx={{ fontSize: 15, color: 'var(--table-header-font)' }} /> },
  { name: 'Rename', icon: <DriveFileRenameOutlineIcon sx={{ fontSize: 15, color: 'var(--table-header-font)' }} /> },
  { name: 'Download', icon: <FileDownloadIcon sx={{ fontSize: 15, color: 'var(--table-header-font)' }} /> },
  { name: 'Delete', icon: <DeleteIcon sx={{ fontSize: 15, color: 'var(--table-header-font)' }} /> }
];

const WelcomePage = () => {
  const pathNav = Navigation();
  const fileInput = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const mobile_view = useMediaQuery('(min-width: 600px)');

  const [documentList, setDocumentList] = useState([]);
  const [createOption, setCreateOption] = useState('Created by me');
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const [tempImg, setTempImg] = useState(false);
  const [mapedDoc, setMapedDoc] = useState([]);
  const [mapedSign, setMapedSign] = useState([]);
  const [welcomeImg, setWelcomeImg] = useState(false);
  const [rename_doc, setRename_doc] = useState({});
  const [open, setOpen] = useState(false);
  const [folder, setFolder] = useState([]);
  const [files, setFiles] = useState([]);
  const [folderName, setFolderName] = useState('');
  const [folderOpen, setFolderOpen] = useState(false);
  const [parent_id, setParent_id] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [navigatePages, setNavigatePages] = useState(navigatePage);
  const [moveFolder, setMoveFolder] = useState(null);
  const [move_parent_id, setMove_parent_id] = useState(null);
  const [moveFiles, setMoveFiles] = useState({ folder: [], files: [] });
  const [current_parent_id, setCurrent_parent_id] = useState(null);
  const [folderNav, setFolderNav] = useState([{ Id: '*', name: 'My Documents' }]);
  const [mobileView, setMobileView] = useState(false);

  const documents = useSelector((state) => state.document.value);
  const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
  const folder_detail = useSelector((state) => state.folderId.value);
  const admin = show_integeration?.user_information?.userRole.includes('Super Admin', 'Admin');

  useEffect(() => {
    if (JSON.parse(show_integeration?.plans_info)[0]?.plan_flags?.AI_TEMPLATE) {
      if (!JSON.parse(show_integeration?.show_features)[0]?.features?.AI_TEMPLATE && JSON.parse(show_integeration?.show_features)[0]?.features?.hasOwnProperty('AI_TEMPLATE')) {
        let filteredArray = navigatePages?.filter(item => item !== 'AITemplate');
        setNavigatePages(filteredArray);
      }
    } else {
      let filteredArray = navigatePages?.filter(item => item !== 'AITemplate');
      setNavigatePages(filteredArray);
    }
  }, []);

  const upload = (key) => {
    switch (key) {
      case 'AITemplate':
        pathNav('/create-templates');
        break;
      case 'File Upload':
        if (parent_id) {
          setFiles([]);
          setFolder([]);
        } else {
          pathNav('/upload');
        }
        break;
      case 'New Folder':
        setFolderOpen(true);
        break;
      default:
        if (parent_id) {
          setFiles([]);
          setFolder([]);
        } else {
          pathNav('/upload');
        }
        break;
    }
  };

  // const handleFilterChange = (e) => {
  //   setCreateOption(e.target.value)
  // };

  useEffect(() => {
    fetchDocument();
  }, [documents]);

  const fetchDocument = (key) => {
    dispatch(toggleLoader(true));
    if (folder_detail && Object.keys(folder_detail)?.length && key !== 'delete') {
      handleSelectFolder({ Id: folder_detail?.Id }, 'refresh');
      setParent_id(folder_detail?.Id);
      setFolderNav(folder_detail?.folderNav);
    } else {
      let options = { org_id: documents?.OrgId, limit: 100 };
      getDocuments(options, (res) => {
        dispatch(folder_id_action(null));
        setDocumentList(res.data.document_list);
        setFolder(res.data.folders);
        if (files?.length) setFiles([]);
        dispatch(toggleLoader(false));
        dispatch(template_preview_action(null));
        dispatch(document_action(null));
        setWelcomeImg(true);
        if (key === 'delete') {
          setFolderNav([{ Id: '*', name: 'My Documents' }]);
          const searchParams = new URLSearchParams(location.search);
          searchParams.delete('folder_id');
          navigate(`?${searchParams.toString()}`, { replace: true });
        }
      }, (err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))
      });
    };
  };

  const handleTemplateHandler = (item) => {
    if (mobile_view) {
      dispatch(toggleLoader(true));
      dispatch(document_action({ item }));
      dispatch(signId_action(null));
      dispatch(folder_id_action(null));
      dispatch(crm_param_action(null));
      pathNav(`/document/${item.Id}`);
    } else {
      setMobileView(true);
    }
  };

  const handleDeleteDoc = (Id) => {
    dispatch(toggleLoader(true))
    let data_obj = {
      org_id: documents?.OrgId,
      ids: [Id.toString()],
      limit: 100,
      cursor: 1,
      is_confirm: false
    };

    let data = JSON.stringify(data_obj);
    handleDocumentDeleteApi(data, Id);
  };

  const handleDocumentDeleteApi = (data, Id) => {
    getDocumentDelete(data, (res) => {
      setShowModal(true);
      let mapedDocument = res.data;
      if (mapedDocument?.has_mapped_template) {
        setMapedDoc(res?.data?.mapped_templates);
      }
      if (mapedDocument?.has_mapped_signs) {
        setMapedSign(res?.data?.mapped_signs);
      }
      dispatch(toggleLoader(false));
    }, (err) => {
      setShowModal(true);
      toast.error(err.message);
      dispatch(toggleLoader(false));
      setShowModal(false);
    });
  }

  const handleConfirmDelete = (Id) => {
    let data_obj = {
      org_id: documents?.OrgId,
      ids: [Id?.toString()],
      limit: 100,
      cursor: 1,
      is_confirm: true
    };

    dispatch(toggleLoader(true))
    getDocumentDelete(JSON.stringify(data_obj), (res) => {
      if (parent_id) {
        handleFolderFiles(parent_id);
      } else {
        setDocumentList(res.data.document_list);
      };
      setWelcomeImg(true);
      setShowModal(false);
      dispatch(toggleLoader(false));
    }, (err) => {
      toast.error(err.message);
      dispatch(toggleLoader(false));
      setShowModal(false);
    });
  };

  const handleCloseModal = (val) => {
    setShowModal(val);
    setMapedDoc([]);
    setMapedSign([]);
  };

  const handleDownLoad = (fileId, fileName) => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_DOCUMENTDOWNLOAD, { params: { file_id: fileId, org_id: documents?.OrgId }, responseType: 'arraybuffer', withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        let data = new Blob([res?.data], { type: "application/pdf" });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(data);
        link.download = fileName?.split('.')[0] + '.pdf';
        link.click();
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        dispatch(toggleLoader(false));
        toast.error(err.message);
      })
  };

  const handleNameChange = (e, key) => {
    let data = { ...rename_doc };
    data[key] = e.target.value;
    setRename_doc(data);
  };

  const handleChangeDocName = () => {
    if (rename_doc?.file_type === 3) {
      let validateDoc = folder?.find(s => s?.Id === rename_doc?.doc_id);
      if (validateDoc?.Folder_Name !== rename_doc?.doc_name) {
        if (rename_doc?.doc_name?.length) {
          let data = { org_id: documents?.OrgId, folder_id: rename_doc?.doc_id, product_code: 3, folder_name: rename_doc?.doc_name };
          dispatch(toggleLoader(true));
          axios.put(process.env.REACT_APP_FOLDER, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
              setOpen(false);
              dispatch(toggleLoader(false));
              if (parent_id) handleFolderFiles(parent_id);
              else fetchDocument();
            })
            .catch((err) => {
              toast.error(err.message);
              dispatch(toggleLoader(false));
            });
        } else {
          toast.error('Folder name is requied');
        }
      } else {
        setOpen(false);
        setRename_doc({});
      }
    } else {
      let validateDoc = rename_doc?.file_type === 1 ? documentList?.find(s => s?.Id === rename_doc?.doc_id) : files?.find(s => s?.Id === rename_doc?.doc_id);
      if (validateDoc?.Document_Name !== rename_doc?.doc_name) {
        if (rename_doc?.doc_name?.length) {
          dispatch(toggleLoader(true));
          let options = { org_id: documents?.OrgId, document_id: rename_doc?.doc_id, document_name: rename_doc?.doc_name };
          axios.put(process.env.REACT_APP_DOCUMENT, options, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
              setOpen(false);
              setRename_doc({});
              if (res?.data?.success && rename_doc?.file_type === 1) fetchDocument();
              else handleFolderFiles(parent_id);
            })
            .catch((err) => {
              toast.error(err.message);
              dispatch(toggleLoader(false));
            });
        } else {
          toast.error('Document name is requied');
        }
      } else {
        setOpen(false);
        setRename_doc({});
      };
    };
  };

  const handleFolderFiles = (item, key) => {
    let data = { org_id: documents?.OrgId, limit: 100, folder_id: item };
    dispatch(toggleLoader(true));
    getDocuments(data, (res) => {
      setFiles(res.data.document_list);
      setFolder(res.data.folders);
      dispatch(toggleLoader(false));
    }, (err) => {
      toast.error(err.message);
      dispatch(toggleLoader(false));
    });
  };

  const handleCreateFolder = () => {
    let data = { org_id: documents?.OrgId, product_code: 3, folder_name: folderName };
    if (parent_id) data['parent_id'] = parent_id;
    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_FOLDER, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        if (!parent_id) fetchDocument();
        else handleFolderFiles(parent_id);
        setFolderOpen(false);
        setFolderName('');
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
  };

  const handleFolder = (item, key) => {
    switch (key) {
      case 'Delete':
        let data = { org_id: documents?.OrgId, product_code: 3, folder_id: item?.Id };
        dispatch(toggleLoader(true));
        axios.delete(process.env.REACT_APP_FOLDER, { withCredentials: process.env.REACT_APP_CREDENTIALS, data: data })
          .then((res) => {
            if (parent_id) handleFolderFiles(parent_id, 'delete');
            else fetchDocument();
            setFolderOpen(false);
            setFolderName('');
            dispatch(toggleLoader(false));
            toast.success('Folder deleted');
          })
          .catch((err) => {
            toast.error(err.message);
            dispatch(toggleLoader(false));
          });
        break;
      case 'Move to':
        let params = { org_id: documents?.OrgId, limit: 100 };
        getDocuments(params, (res) => {
          setMoveFiles({ ...moveFiles, folder: res.data.folders });
          setMove_parent_id(item?.Id);
          setMoveFolder(item);
          setOpen(true);
          dispatch(toggleLoader(false));
        }, (err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
        break;
      case 'Rename':
        setRename_doc({ doc_id: item.Id, doc_name: item?.Folder_Name, file_type: 3 });
        setOpen(true);
        break;
      default:
        // let data = { org_id: documents?.OrgId, product_code: 3, parent_id: item?.Id, source_id: 1263, type: 1 };
        // dispatch(toggleLoader(true));
        // axios.put(process.env.REACT_APP_FILE_MOVE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        //   .then((res) => {
        //     dispatch(toggleLoader(false));
        //     toast.success(`demo.png has been moved to ${item?.folder_name}`);
        //   })
        //   .catch((err) => {
        //     toast.error(err.message);
        //     dispatch(toggleLoader(false));
        //   });
        break;
    }
  };

  const handleSelectFolder = (item, key, idx) => {
    let data = { org_id: documents?.OrgId, limit: 100, folder_id: item?.Id };
    if (item?.Id === '*') {
      delete data['folder_id'];
      setWelcomeImg(false);
      setParent_id(null);
      fetchDocument('delete');
      setFiles([]);
      return;
    };

    dispatch(toggleLoader(true));
    if (key === 'move') {
      getDocuments(data, (res) => {
        setMoveFiles({ folder: res.data.folders, files: res.data.document_list });
        setCurrent_parent_id(item?.Id);
        dispatch(toggleLoader(false));
        setDocumentList([]);
      }, (err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
    } else {
      getDocuments(data, (res) => {
        dispatch(toggleLoader(false));
        if (res.data.document_list?.length && item?.Id !== '*') setFiles(res.data.document_list);
        else setFiles([]);
        setFolder(res.data.folders);
        setWelcomeImg(true);
        const searchParams = new URLSearchParams({ folder_id: item?.Id }).toString();
        navigate(`?${searchParams}`, { replace: true });

        if (folderNav?.length >= idx + 1) {
          const newArr = folderNav.slice(0, idx + 1);
          setFolderNav(newArr);
          dispatch(folder_id_action({ Id: item?.Id, folderNav: newArr }));
        } else {
          if (key !== 'refresh') {
            let data = [...folderNav];
            data.push({ Id: item?.Id, name: item?.Folder_Name });
            setFolderNav(data);
            dispatch(folder_id_action({ Id: item?.Id, folderNav: data }));
          }
        };
        setParent_id(item?.Id);
        setDocumentList([]);
      }, (err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false));
      });
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const upload_document = (e) => {
    const maxSize = 30 * 1024 * 1024;
    if (!(e['type'].includes('png') || e['type'].includes('jpg') || e['type'].includes('jpeg') || e['type'].includes('pdf') || e['type'].includes('vnd.ms-excel') || e['type'].includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet') || e['type'].includes('msword') || e['type'].includes('vnd.openxmlformats-officedocument.wordprocessingml.document') || e['type'].includes('vnd.openxmlformats-officedocument.presentationml.presentation') || e['type'].includes('vnd.ms-powerpoint'))) {
      toast.error('Invalid file format. Please upload a valid file in the appropriate format (e.g. .jpg, .jpeg, .pdf, .png ).')
    } else if (e.size > maxSize) {
      toast.error('The file you are trying to upload is too large. The maximum file size allowed is 10 MB.');
    } else {
      handleUpload(e);
    };
  };

  const handleGetIcons = (type) => {
    let key = type?.split('.')?.pop();
    switch (key) {
      case 'folder':
        return 'folder.svg';
      case 'pdf':
        return 'pdf.svg';
      case 'doc':
        return 'doc.svg';
      case 'xlsx':
        return 'xlsx.svg';
      default:
        return 'image.svg'
    };
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files);
    upload_document(files[0]);
  };

  const handleUpload = (file) => {
    setIsProgress(true);
    let form = {
      file: file,
      product_code: 2,
      folder_path: 'Document',
      permission: 2,
      org_id: documents?.OrgId,
      parent_id: parent_id
    };

    const formData = new FormData();

    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });

    dispatch(document_action(null));
    axios.post(process.env.REACT_APP_DOCUMENT_DRIVE, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        let data = res.data;
        setIsProgress(false);
        dispatch(toggleLoader(false));
        dispatch(document_action({ item: data.response }));
        pathNav(`/document/${data.response.Id}`);
        toast.success('Success');
      })
      .catch((e) => {
        dispatch(toggleLoader(false));
        setIsProgress(false);
        toast.error(e.message);
      });
  };

  const handleFiles = (files, key) => {
    switch (key) {
      case 'Move to':
        let params = { org_id: documents?.OrgId, limit: 100 };
        getDocuments(params, (res) => {
          setMoveFiles({ ...moveFiles, folder: res.data.folders });
          setMove_parent_id(files?.Id);
          setMoveFolder(files);
          setOpen(true);
          dispatch(toggleLoader(false));
        }, (err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
        break;
      case 'Download':
        handleDownLoad(files.File_Id, files?.Document_Name);
        break;
      case 'Delete':
        setDeleteId({ id: files.Id, orgId: files.Org_Id });
        handleDeleteDoc(files.Id);
        break;
      case 'Rename':
        setRename_doc({ doc_id: files.Id, doc_name: files?.Document_Name, file_type: 2 });
        setOpen(true);
        break;
      default:
        break;
    }
  };

  const handleMoveFolder = () => {
    dispatch(toggleLoader(true));
    let data = { org_id: documents?.OrgId, product_code: 3, parent_id: current_parent_id, source_id: move_parent_id, type: moveFolder?.Folder_Name ? 2 : 1 };
    axios.put(process.env.REACT_APP_FILE_MOVE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        setOpen(false);
        dispatch(toggleLoader(false));
        toast.success('Success');
        setParent_id(null);
        fetchDocument();
      })
      .catch((e) => {
        dispatch(toggleLoader(false));
        setIsProgress(false);
        toast.error(e.message);
      });
  };

  return (
    <>
      {welcomeImg ?
        <Box sx={{ width: '100%', height: '100%' }}>
          <Box className='my_doc_header' sx={{ padding: '0px 30px' }}>
            <Box className='document-header'>
              <Box className='title'>
                <Box className='my-documents'>My Documents</Box>
              </Box>
            </Box>
            {(admin || show_integeration?.user_permission[1]?.create === 1) && (
              <div className='upload-body'>
                <Button variant='contained' className='upload-button' onClick={() => upload()}>
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/upload-icon.svg'}
                    className='file-upload-svg'
                    alt='fileUpload'
                  />
                  Upload
                </Button>
                <Select
                  value={''}
                  className='upload-document'
                  sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none !important' } }}
                  MenuProps={{
                    classes: { paper: 'welcome_page_select', border: 'none' },
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:first-of-type': {
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          },
                        },
                      },
                    },
                  }}
                  onChange={(e) => upload(e.target.value)}
                >
                  {navigatePages?.map((s, i) => (
                    <MenuItem className='file-upload-wrapper' key={i} value={s}>
                      <img
                        src={s === 'New Folder' ? process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/new-folder.svg' : process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/file-upload.svg'}
                        className='file-upload-svg'
                        alt='fileUpload'
                      />
                      {s !== 'AITemplate' ? (
                        <span>{s}</span>
                      ) : (
                        <span className="power-letter">Create AI Template<sup>BETA</sup></span>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            )}
          </Box>
          <Box className='document-container'>
            <div className='upload'></div>
            {!documentList?.length && !parent_id && <Box className='no_documents_found'>No documents found !</Box>}
            <div className='favorites-body'>
              <Modal
                open={folderOpen}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                closeAfterTransition
              >
                <Box className='doc_new_limit_wrap'>
                  <Box className='doc_new_text'>Create Folder</Box>
                  <IconButton
                    className='close_btn'
                    onClick={() => setFolderOpen(false)}
                  >
                    <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                  </IconButton>
                  <Box sx={{ width: '100%', display: 'flex', margin: '10px 0px', gap: '10px' }}>
                    <TextField
                      onChange={(e) => setFolderName(e.target.value)}
                      value={folderName}
                      sx={{
                        width: 500,
                        border: '1px solid var(--border-color)',
                        '& input': { fontSize: '13px', padding: '9px 10px 11px 10px' },
                        "& input:hover": {
                          backgroundColor: "#f6f7fa"
                        }
                      }}
                      fullWidth
                      InputProps={{ disableUnderline: true }}
                      variant='standard'
                    />
                    <Button sx={{ textTransform: 'capitalize' }} onClick={handleCreateFolder} variant='contained'>Create</Button>
                  </Box>
                </Box>
              </Modal>
              <Box className='folder_main'>
                <Box>
                  <Box className='nav_folder_wrap'>
                    {folderNav?.map((nav, i) => (
                      <Box key={nav + i} className='nav_folder_body' onClick={() => folderNav?.length !== i + 1 && handleSelectFolder(nav, null, i)}>
                        <Tooltip title={nav?.name} key={i} placement="top"
                          slotProps={{
                            popper: {
                              sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                {
                                  marginBottom: '5px',
                                },
                              },
                            },
                          }}>
                          <Box className='nav_folder_name' sx={{ cursor: folderNav?.length === i + 1 ? 'default' : 'pointer', fontWeight: folderNav?.length === i + 1 && folderNav?.length !== 1 ? '600' : '500', color: folderNav?.length === i + 1 ? 'var(--header-color)' : '#444746', padding: i >= 1 ? '6px 8px' : '6px 0px' }}>{nav?.name}</Box>
                        </Tooltip>
                        <Box sx={{ marginLeft: '10px', color: '#80868b' }}>{'>'}</Box>
                      </Box>
                    )
                    )}
                  </Box>
                  <Box>
                    {folder?.length && (folder?.length || parent_id) ? <Box className='folder_name'>Folders</Box> : null}
                    <Box className='folder_main_wrapper'>
                      {folder?.map((item, i) => {
                        return (
                          <Box className='folder_border' key={i} onDoubleClick={() => handleSelectFolder(item, 'next')}>
                            <Box>
                              <img
                                src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/new-folder.svg'}
                                alt=''
                                width={20}
                                height={20}
                              />
                            </Box>
                            <Box className='folder_file_name'>{item?.Folder_Name}</Box>
                            <Box className='folder_dot_img'>
                              <img
                                src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/three-dots.svg'}
                                alt=''
                                width={20}
                                height={20}
                              />
                              <Box className='delete-doc'>
                                <CustomSelect
                                  condition={true} type={true}>
                                  {folder_options.map((list, idx) => {
                                    return (
                                      <MenuItem
                                        key={idx}
                                        sx={{
                                          margin: '5px',
                                          alignItems: 'center',
                                          "&:hover": {
                                            backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                                            borderRadius: '6px'
                                          },
                                          width: 150
                                        }}
                                        onClick={() => handleFolder(item, list.name)}>
                                        {list.icon}
                                        <Typography className='document_options'>
                                          {list.name}
                                        </Typography>
                                      </MenuItem>
                                    )
                                  })
                                  }
                                </CustomSelect>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                    {files?.length ? <Box className='folder_name'>Files</Box> : null}
                    {files?.length ? files?.map((file, i) => {
                      return (
                        <Box className='favorites-list' key={i}>
                          <Card className='thumbnail_body' onClick={() => handleTemplateHandler(file)}>
                            <CardActionArea sx={{ width: '100%', height: '100%', overflow: 'hidden', position: 'relative' }}>
                              <CardContent sx={{ height: '130px !important', objectFit: 'fill' }}>
                                <img src={`${process.env.REACT_APP_THUMBNAIL}?file_id=${file?.File_Id}&org_id=${file?.Org_Id}`} alt='img' className="template-img" />
                              </CardContent>
                              <CardContent style={{ borderTop: '1px solid rgba(231, 234, 243, 0.7' }}>
                                <Tooltip title={file?.Document_Name?.length > 20 ? file?.Document_Name : ''} key={i}>
                                  <Box className='thumnail_name'>{file?.Document_Name}</Box>
                                </Tooltip>
                                <Typography className='thumbnail_date'>
                                  Created At {moment(new Date(file?.Created_Time)).format('DD/MM/YYYY')}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                          <Box className='delete-doc'>
                            <CustomSelect
                              element={
                                <IconButton>
                                  <MoreVertIcon />
                                </IconButton>
                              }
                              condition={true}>
                              {file_options.map((list, idx) => {
                                return (
                                  <MenuItem
                                    key={idx}
                                    sx={{
                                      margin: '5px',
                                      alignItems: 'center',
                                      "&:hover": {
                                        backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                                        borderRadius: '6px'
                                      },
                                      width: 150
                                    }}
                                    onClick={() => handleFiles(file, list?.name)}>
                                    {list.icon}
                                    <Typography className='document_options'>
                                      {list.name}
                                    </Typography>
                                  </MenuItem>
                                )
                              })
                              }
                            </CustomSelect>
                          </Box>
                        </Box>
                      )
                    }) : null}
                  </Box>
                  {!folder?.length && !files?.length && parent_id &&
                    <div
                      onDragEnter={handleDragEnter}
                      onDragOver={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      className={isDragOver ? 'upsec-areas' : 'upsec-area'}>
                      {<div className='browse-inn-part'>
                        {!isProgress ? (
                          <div className='before-upload'>
                            <Box sx={{ display: 'flex' }}>
                              <Box className='upload_text'>
                                <img
                                  src={process.env.REACT_APP_IMAGE_URL + 'upload/dropbox.svg'}
                                  className='box_img'
                                  alt='menu-item'
                                />
                                <div className='upload-file-text'>Drag and Drop your file here</div>
                                <span className='or'>or</span>
                                <Button
                                  variant='contained'
                                  onClick={() => fileInput.current.click()}
                                  className='btn upload-sign'
                                >
                                  <span>Choose file</span>
                                </Button>
                              </Box>
                            </Box>
                            <input
                              ref={fileInput}
                              type='file'
                              id='upload-doc'
                              style={{ display: 'none' }}
                              onChange={(e) => upload_document(e.target.files[0])}
                            />
                          </div>
                        ) : (
                          <div className='upload-inprogress'>
                            <Box className='upload_text'>
                              <div>
                                <Loader />
                              </div>
                              <p className='up-text'>Uploading...</p>
                              <p className='up-hint'>Wait until your file is uploaded.</p>
                            </Box>
                          </div>
                        )}
                      </div>}
                    </div>}
                </Box>
              </Box>
              {documentList?.length ? <Box className='folder_name'>Files</Box> : null}
              {!parent_id && documentList?.map((item, i) => {
                return (
                  <div className='favorites-list' key={i}>
                    <Card className='thumbnail_body'>
                      {/* <CardActionArea onClick={() => { (admin || show_integeration?.user_permission[1]?.update === 1) && handleTemplateHandler(item) }}> */}
                      <CardActionArea onClick={() => handleTemplateHandler(item)}>
                        <CardContent sx={{ height: '110px' }}>
                          <img src={`${process.env.REACT_APP_THUMBNAIL}?file_id=${item?.File_Id}&org_id=${item?.Org_Id}`} alt='img' className="template-img" />
                        </CardContent>
                        <CardContent style={{ borderTop: '1px solid rgba(231, 234, 243, 0.7' }}>
                          <Tooltip title={item?.Document_Name?.length > 20 ? item?.Document_Name : ''} key={i}>
                            <Typography className='thumnail_name'>
                              {item?.Document_Name}
                            </Typography>
                          </Tooltip>
                          <Typography className='thumbnail_date'>
                            Created At {moment(new Date(item?.Created_Time)).format('DD/MM/YYYY')}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    <div className='delete-doc'>
                      <CustomSelect
                        element={
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        }
                        condition={true}>
                        return (
                        <MenuItem
                          sx={{
                            margin: '5px',
                            alignItems: 'center',

                            "&:hover": {
                              backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                              borderRadius: '6px',
                            },
                            width: 150
                          }}
                          onClick={() => [setRename_doc({ doc_id: item.Id, doc_name: item?.Document_Name, file_type: 1 }), setOpen(true)]}
                        >
                          <DriveFileRenameOutlineIcon sx={{ fontSize: 15, color: 'var(--table-header-font)', opacity: .7 }} />
                          <Typography className='document_options' >
                            Rename
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          sx={{
                            margin: '5px',
                            alignItems: 'center',
                            "&:hover": {
                              backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                              borderRadius: '6px',
                            }
                          }}
                          onClick={() => handleDownLoad(item.File_Id, item?.Document_Name)}
                        >
                          <FileDownloadIcon sx={{ fontSize: 15, color: 'var(--table-header-font)', opacity: .7 }} />
                          <Typography className='document_options' >
                            Download
                          </Typography>
                        </MenuItem>
                        {(admin || show_integeration?.user_permission[1]?.delete === 1) && (
                          <MenuItem
                            sx={{
                              margin: '5px',
                              alignItems: 'center',
                              "&:hover": {
                                backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                                borderRadius: '6px'
                              }
                            }}
                            onClick={() => [setDeleteId({ id: item.Id, orgId: item.Org_Id }, handleDeleteDoc(item.Id))]}>
                            <DeleteIcon sx={{ fontSize: 15, color: 'var(--table-header-font)', opacity: .7 }} />
                            <Typography color='error' className='document_options'>
                              Delete
                            </Typography>
                          </MenuItem>)}
                        );
                      </CustomSelect>
                    </div>
                  </div>
                );
              })}
              <DeleteModal
                showModal={showModal}
                closeModal={handleCloseModal}
                handleDelete={() => handleConfirmDelete(deleteId?.id)}
                mapped_templates={mapedDoc}
                mapped_signs={mapedSign}
              />
              <Modal
                open={open}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                closeAfterTransition
              >
                {!moveFolder ?
                  <Box>
                    <RenameDocumentFolder
                      rename_doc={rename_doc}
                      handleNameChange={handleNameChange}
                      setOpen={setOpen}
                      handleChangeDocName={handleChangeDocName}
                    />
                  </Box>
                  :
                  <Box className='doc_new_limit_wrap'>
                    <Box className='move_folder_name'>Move<span>“{moveFolder?.Folder_Name ? moveFolder?.Folder_Name : moveFolder?.Document_Name}”</span></Box>
                    <Box className='folder_main_wrapper'>
                      {moveFiles.folder?.length ? moveFiles.folder?.map((item, i) => {
                        return (
                          <Box className='folder_move' key={i} onDoubleClick={() => handleSelectFolder(item, 'move')}>
                            <Box>
                              <img
                                src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/new-folder.svg'}
                                alt=''
                                width={20}
                                height={20}
                              />
                            </Box>
                            <Box>{item?.Folder_Name}</Box>
                          </Box>
                        )
                      }) : <Box className='empty_folder_text'>This folder is empty</Box>}
                    </Box>
                    {/* {moveFiles?.files?.length ? moveFiles?.files?.map((file, i) => {
                      let type = handleGetIcons(file?.Document_Name);
                      return (
                        <Box className='folder_file_container' key={i}>
                          <img src={process.env.REACT_APP_IMAGE_URL + `upload/${type}`} alt='files' className="file_type_img" />
                          <Box className='folder_thumnail_name'>{file?.Document_Name}</Box>
                        </Box>)
                    }) : null}
                    {moveFolder && !moveFiles?.folder?.length && !moveFiles?.files?.length ?
                      <Box>
                        <Box>This folder is empty</Box>
                      </Box> : null} */}
                    <Box className='doc_rename_btn'>
                      <Button variant='outlined' className='document-delete-cancel' onClick={() => setOpen(false)}>Cancel</Button>
                      <Button variant='contained' className='doc_new_submit_btn' onClick={handleMoveFolder}>Move</Button>
                    </Box>
                  </Box>}
              </Modal>
            </div>
          </Box>
          <MobileView mobileView={mobileView} closeMobileView={() => setMobileView(false)} />
        </Box> :
        <Box className='document-container'>
          {skeleton_options.map((j) =>
            <Box style={{ width: '200px', height: '180px', display: 'inline-block', margin: '1%', marginTop: '100px' }} key={j}>
              <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)' }} animation="wave" variant="rounded" width={"100%"} height={'100%'} />
            </Box>)}
        </Box>}
    </>
  );
};

export default WelcomePage;

const RenameDocumentFolder = ({ rename_doc, handleNameChange, setOpen, handleChangeDocName }) => {
  return (
    <Box className='doc_new_limit_wrap'>
      <Box className="doc_new_text">{rename_doc?.file_type === 3 ? 'Folder name' : 'Document name'}</Box>
      <TextField
        onChange={(e) => handleNameChange(e, 'doc_name')}
        value={rename_doc?.doc_name}
        sx={{
          minWidth: 220,
          border: '1px solid var(--border-color)',
          '& input': { fontSize: '13px', padding: '9px 10px 11px 10px' },
          "& input:hover": {
            backgroundColor: "#f6f7fa"
          }
        }}
        placeholder='Document name'
        fullWidth
        InputProps={{ disableUnderline: true }}
        variant='standard'
      />
      <Box className='doc_rename_btn'>
        <Button variant='outlined' className='document-delete-cancel' onClick={() => setOpen(false)}>Cancel</Button>
        <Button variant='contained' className='doc_new_submit_btn' onClick={handleChangeDocName}>Update</Button>
      </Box>
    </Box>
  )
};