import { uploadDocument } from './uploadReducer';

export const upload_action = (query) => async (dispatch) => {
    try {
        dispatch(uploadDocument(query));
    } catch (err) {
        const error = {
            code: 0,
            message: typeof err == 'object' ? err.message : err,
        };
        throw error;
    }
};
