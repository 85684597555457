import React, { useEffect, useMemo, useState } from 'react';
import './styles/AddRecipient.css';
import { Box, Button, debounce, MenuItem, Select } from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import { validateEmail } from '../utils/validation';
import { toast } from 'react-toastify';
import { Navigation } from '../utils/handleNavigation';
import { toggleLoader } from '../store/loader/loaderReducer';
import { crm_param_action } from '../store/crmParam/crmParamAction';
import { LimitModal } from './LimitModal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getTemplatePreview } from '../Api/TemplatePreviewApi';
import { signId_action } from '../store/signIDDetails/signId_action';

const AddRecipient = () => {
  const [document, setDocument] = useState('No expiry');
  const [toggle, setToggle] = useState(false);
  const [recipientList, setRecipientList] = useState([{ email: '', name: '' }]);
  const [reminder, setReminder] = useState('1');
  const [description, setDescription] = useState('');
  const [toggleDatePicker, setToggleDatePicker] = useState(false);
  const [error, setError] = useState([]);
  const [signDate, setSignDate] = useState([]);
  const [toggleRecipient, setToggleRecipient] = useState(false);
  const [searchRecipient, setSearchRecipient] = useState([]);
  const [searchList, setSearchList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(false);

  const navPath = Navigation();
  const dispatch = useDispatch();

  const list = useSelector((state) => state?.recipientDetails?.value);
  const orgId = useSelector((state) => state.document.value);
  const signId_details = useSelector((state) => state?.signId_details?.value);

  let approved = signId_details?.approvers_info?.find((item) => item);

  const handleRecipients = (value, index, key) => {
    let data = [...recipientList];
    data[index][key] = value;
    setRecipientList(data);
    setError([]);
  };

  useEffect(() => {
    if (list?.data?.Properties && JSON.parse(list?.data?.Properties)?.properties?.approvers?.length && approved?.status === 1) {
      dispatch(toggleLoader(true));
      axios.get(process.env.REACT_APP_TEMPLATESEETTING, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: { org_id: orgId?.OrgId, template_id: JSON.stringify(list?.data?.Id) } })
        .then((res) => {
          let item = res.data;
          setReminder(item?.Reminder);
          if (item?.Reminder) setToggle(true);
          setDescription(item?.Note_For_Recipients);
          let data_format = moment(item?.Sign_Validity).format('DD-MM-YYYY');
          if (item?.Sign_Validity) setDocument(data_format);
          dispatch(toggleLoader(false));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    }
  }, []);

  const handleAddRecipients = () => {
    handleNameHandler();
    setRecipientList([...recipientList, { email: '', name: '' }]);
  };

  const handleDeleteRecipients = (index) => {
    let data = recipientList.filter((s, i) => i !== index);
    setRecipientList(data);
    setError([]);
  };

  const handleNameHandler = () => {
    recipientList.map((list, i) => {
      if (list.name === '') {
        let name = list?.email?.substring(0, list?.email?.indexOf('@'));
        let data = [...recipientList];
        data[i]['name'] = name;
        setRecipientList(data);
      }
    });
  };

  const submitHandler = (key) => {
    handleNameHandler();
    let err = [];
    let diffDays = false;
    const start = moment(new Date());
    const end = signDate?.length === 0 ? new Date() : new Date(signDate);
    const diffTime = Math.abs(end - start);
    const days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


    let reminddays = toggle ? reminder : 0;

    if (days >= reminddays || document === 'No expiry') {
      diffDays = true;
    } else if (list?.data?.Type === 2 && toggle) {
      toast.error('Reminder days is invalid');
    };

    // list?.data?.Type === 1 && recipientList?.map((item, i) => {
    //   let val = validateEmail(item.email);
    // if (item.email === '') {
    //   err.push(i);
    //   toast.error('Please fill out the required fields before proceeding.');
    // } 
    //     if (val === null) {
    //       err.push(i);
    //       toast.error(item.email + ' email is invalid');
    //     }
    //   setError(err);
    // });

    let options = list?.data?.Type === 2 ? {
      org_id: orgId?.OrgId,
      public_note: description,
      reminder_days: toggle ? Number(reminder) : 0,
      sign_id: list?.data?.Sign_Id,
      sign_validity: document === 'No expiry' ? '0' : document,
      template_id: JSON.stringify(list?.data?.Id),
      type: JSON.stringify(list?.data?.Type),
    }
      :
      {
        template_id: JSON.stringify(list?.data?.Id),
        recipient: recipientList,
        public_note: description,
        type: JSON.stringify(list?.data?.Type),
        org_id: orgId?.OrgId,
        sign_id: list?.data?.Sign_Id,
      };

    if (list?.data?.Type === 1 || diffDays) {
      dispatch(toggleLoader(true));
      setLoading(true);
      if (key === 'send') {
        axios.put(process.env.REACT_APP_RECIPIENTS, options, { withCredentials: process.env.REACT_APP_CREDENTIALS, })
          .then((res) => {
            setLoading(false);
            toast.success('Success');
            dispatch(signId_action(null));
            if (list.data.Type === 1) {
              navPath('/sign-myself');
            } else {
              navPath('/send-sign');
            }
            dispatch(toggleLoader(false));
            dispatch(crm_param_action(null));
          })
          .catch((err) => {
            setLoading(false);
            dispatch(toggleLoader(false));
            if (err?.response?.data?.error?.status === 600) {
              setLimit(true);
            } else {
              if (err?.response?.data?.error?.status === 403 && err?.response?.data?.error?.message === 'account_not_confirmed') {
                toast.error(err?.response?.data?.error?.details);
              } else {
                toast.error(err.message);
              }
            }
          })
      } else {
        if (key === 'approval') {
          delete options.template_id;
          delete options.type;
        }

        axios.post(process.env.REACT_APP_APPROVALREQUEST, options, { withCredentials: process.env.REACT_APP_CREDENTIALS })
          .then((res) => {
            setLoading(false);
            toast.success('Success');
            dispatch(signId_action(null));
            if (list.data.Type === 1) {
              navPath('/sign-myself');
            } else {
              navPath('/send-sign');
            }
            dispatch(toggleLoader(false));
            dispatch(crm_param_action(null));
          })
          .catch((err) => {
            setLoading(false);
            dispatch(toggleLoader(false));
            if (err?.response?.data?.error?.status === 600) {
              setLimit(true);
            } else {
              if (err?.response?.data?.error?.status === 403 && err?.response?.data?.error?.message === 'account_not_confirmed') {
                toast.error(err?.response?.data?.error?.details);
              } else {
                toast.error(err.message);
              }
            }
          })
      }
    }
  };

  const fetchContact = (idx = 0, size = 10, val, index, recipient) => {
    axios.get(process.env.REACT_APP_CONTACT, {
      withCredentials: process.env.REACT_APP_CREDENTIALS,
      params: {
        org_id: orgId?.OrgId,
        limit: size ?? 10,
        ...(val?.length > 0 ? { search: val } : {}),
      },
    })
      .then((res) => {
        if (recipient[index]['email']?.length !== 0) {
          setToggleRecipient((pre) => ({ ...pre, [index]: !toggleRecipient[index] }));
          setSearchRecipient(res?.data?.contacts_list);
          if (res?.data?.contacts_list?.length) {
            setSearchList(index);
          } else {
            setSearchList(false);
          }
        }
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(toggleLoader(false))
      });
  };

  const debouncedSendRequest = useMemo(() => debounce(fetchContact, 300), []);


  const handleSearch = (idx = 0, size = 10, val, index) => {
    let recipient = [...recipientList];
    if (val?.length !== 0) {
      debouncedSendRequest(idx = 0, size = 10, val, index, recipient);
    } else {
      setToggleRecipient(false);
      setSearchRecipient([]);
    }
  };

  const handleRecipientSearch = (item, index) => {
    setToggleRecipient(false);
    setSearchList(false);
    let data = [...recipientList];
    data[index]['name'] = `${item?.First_Name}${item?.Last_Name ? item?.Last_Name : ''}`;
    data[index]['email'] = item?.Email_Id;
    setSearchRecipient([]);
    setRecipientList(data);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#377DFF',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 16,
      height: 16,
      borderRadius: 10,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const handleDocument = () => {
    dispatch(toggleLoader(true));
    let id = { Org_Id: orgId?.OrgId, Template_Id: list?.data?.Id };
    getTemplatePreview(id, dispatch, (res) => {
      let data = JSON.parse(res?.data?.template_information?.Properties)?.properties?.approvers;
      let sign_type = list?.recipientList[0]?.email ? 'send-sign' : 'sign-myself';
      if (data?.length && approved?.status === 1) navPath(`/${sign_type}/${res.data.template_information.Id}?id=${list?.data?.Sign_Id}&action=approved`, 'document_reload');
      else navPath(`/${sign_type}/${res.data.template_information.Id}?id=${list?.data?.Sign_Id}&action=draft`, 'document_reload');
    }, (err) => {
      toast.error(err.message);
      dispatch(toggleLoader(false));
    });
  };

  const handleReminder = (event) => {
    let inputValue = parseInt(event.target.value);
    if (inputValue === 0) {
      inputValue = 1;
    }
    if (inputValue) setReminder(inputValue);
  };

  return (
    <div className='inn-sec'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className='wrapper' onBlur={() => handleNameHandler()}>
          <div className='c-header-recipient'>
            <div className='title'>
              Add Document Details
            </div>
          </div>
          <Box sx={{ display: 'flex' }}>
            <Button onClick={() => handleDocument()} className='recipient_back_button' variant='outlined' style={{ textTransform: 'unset' }}>
              <ArrowBackIcon className='recipient_contact_back' />
              Back
            </Button>
            <Box className='table'>
              <Button variant='outlined' className='cancel' onClick={() => navPath('/my-documents')}>
                Cancel
              </Button>
              {list?.data?.Properties && JSON.parse(list?.data?.Properties)?.properties?.approvers?.length && approved?.status !== 1 && list?.data?.Type !== 1 ? <Button variant='contained' className='send_approval' sx={{ marginLeft: '5px', cursor: loading && 'not-allowed' }} onClick={() => { !loading && submitHandler('approval') }}>
                Send for Approval
                <img
                  src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/send-fill.svg'}
                  className='send-fill-img'
                  alt='send'
                />
              </Button> : null}
              {!list?.data?.Properties || ((list?.data?.Properties && !JSON.parse(list?.data?.Properties)?.properties?.approvers?.length) || approved?.status === 1) ? <Button variant='contained' className='edit' sx={{ marginLeft: '5px', cursor: loading && 'not-allowed' }} onClick={() => { !loading && submitHandler('send') }}>
                Send
                <img
                  src={process.env.REACT_APP_IMAGE_URL + 'DocumentSvg/send-fill.svg'}
                  className='send-fill-img'
                  alt='send'
                />
              </Button> : null}
            </Box>
          </Box>
          <div className='reports-table'>
            <div className='cont-data' style={{ overflow: recipientList.length > 3 || toggleRecipient[searchList] ? 'auto' : 'hidden', display: toggleRecipient[searchList] || recipientList.length > 3 ? '' : 'contents' }}>
              <div className='head'>
                <div className='document-text'>Document Information</div>
              </div>
              {list.data.Type !== 1 && (
                <>
                  <div className='recipient-info'>
                    <div className='left-text'>Reminder Settings</div>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <AntSwitch checked={toggle} onChange={(e) => setToggle(!toggle)} inputProps={{ 'aria-label': 'ant design' }} />
                    </Stack>
                  </div>
                  {toggle && (
                    <div className='recipient-info'>
                      <div className='left-text'>Reminder Days</div>
                      <input
                        onKeyDown={(e) =>
                          ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                        }
                        value={reminder}
                        className='reminder-days'
                        min={1}
                        onChange={handleReminder}
                        type='number'
                      />
                    </div>
                  )}
                  <div className='recipient-info'>
                    <div className='left-text'>Sign Validity</div>
                    <Select MenuProps={{ classes: { paper: 'signtype-container' } }}
                      renderValue={() => document}
                      sx={{
                        '&:hover': {
                          '&& fieldset': {
                            border: '1px solid var(--primary)',
                            outline: 0,
                          },
                        },
                        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                          borderWidth: '0px'
                        },
                      }}
                      className='sign-select'
                      value={document}
                    >
                      <MenuItem
                        className='sign-text'
                        onClick={() => setDocument('No expiry')}
                        value={'No expiry'}
                      >
                        No expiry
                      </MenuItem>
                      <MenuItem
                        className='sign-text'
                        onChange={() => { }}
                        onClick={() => setToggleDatePicker(true)}
                        value={'Select Date'}
                      >
                        Select Date
                      </MenuItem>
                    </Select>

                    <Box
                      sx={{
                        position: 'relative',
                        '& div': {
                          position: 'absolute',
                        },
                      }}
                    >
                      <DatePicker
                        viewRenderers={null}
                        sx={{
                          '& .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root': {
                            scale: '0.7',
                          },
                          '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
                            opacity: 0,
                            pointerEvents: 'none',
                          },
                          '& .css-nk89i7-MuiPickersCalendarHeader-root': {
                            background: 'red',
                          },
                          // mt: 35,
                          // ml: -40,
                          transform: 'translate(10px, -75px)',
                          fontFamily: 'var(--primary-font-family)',
                          color: 'var(--header-color)'
                        }}
                        closeOnSelect={true}
                        open={toggleDatePicker}
                        onClose={() => setToggleDatePicker(false)}
                        minDate={dayjs(new Date())}
                        format='DD-MM-YYYY'
                        onChange={(date) => {
                          setDocument(moment(date.$d).format('DD-MM-YYYY'));
                          setSignDate(date.$d)
                        }}
                      />
                    </Box>
                  </div>
                </>
              )}
              {list?.data?.Type === 1 &&
                <div className='signed-container'>
                  <div className='left-text'>Receives a copy</div>
                  <div className='signed-wrapper'>
                    {recipientList?.map((item, index) => (
                      <div key={index} style={{ display: 'flex', width: '80%', position: 'relative' }}>
                        <input
                          className={error.includes(index) ? 'error-reminder-days' : 'reminder-days-email'}
                          value={item.email}
                          onChange={(e) => [handleRecipients(e.target.value, index, 'email'), handleSearch(0, 10, e.target.value, index)]}
                          placeholder='Search or Enter'
                        />
                        {toggleRecipient[index] && <div className='modal_result'>
                          {searchRecipient?.map((item, i) => {
                            return (
                              <div key={i} className='dropDown_list' onClick={() => handleRecipientSearch(item, index)}>
                                <div>{item?.First_Name}{item.Last_Name}</div>
                                <div>{item?.Email_Id}</div>
                              </div>
                            )
                          })}
                        </div>}

                        <div className='select-recipient-container'>
                          <input
                            className={error.includes(index) ? 'error-reminder-name' : 'reminder-name'}
                            value={item.name}
                            onChange={(e) => handleRecipients(e.target.value, index, 'name')}
                            onClick={() => handleNameHandler()}
                            placeholder='Contact Name'
                          />
                          <div className='add-text' onClick={() => handleAddRecipients()}>
                            Add
                          </div>
                          <div
                            className='remove-text'
                            style={{
                              cursor: recipientList?.length === 1 ? 'no-drop' : 'pointer',
                            }}
                            onClick={() =>
                              recipientList?.length !== 1 && handleDeleteRecipients(index)
                            }
                          >
                            Remove
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>}
              <div className='description-container'>
                <div className='left-text'>Description</div>
                <textarea
                  className='description'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder='Note for signers'
                />
              </div>
            </div>
          </div>
        </div>
      </LocalizationProvider>
      <LimitModal limit={limit} handleModalClose={() => setLimit(false)} />
    </div>
  );
};

export default AddRecipient;
