import { scheduleName } from "./scheduleReducer";

export const schedule_name_action = (query) => async (dispatch) => {
    try {
        dispatch(scheduleName(query));
    } catch (err) {
        const error = {
            code: 0,
            message: typeof err == 'object' ? err.message : err,
        };
        throw error;
    }
};