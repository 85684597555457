import React, { useState } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styles from './DocumentViewModal.module.css';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const DocumentViewModal = ({ file, setFile }) => {
    const [numPages, setNumPages] = useState([]);

    const onDocumentLoadSuccess = async (numPages) => {
        setNumPages([numPages?.numPages]);
    };

    return (
        <Modal
            open={file ? true : false}
            sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
            closeAfterTransition
        >
            <Box className={styles.new_limit_wrap}>
                <IconButton
                    className={styles.close_btn}
                    onClick={() => setFile('')}
                >
                    <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                </IconButton>
                <Box className={numPages >= 2 ? styles.doc_viewer_wrapper : styles.doc_viewer_wrapper_}>
                    <Document file={file} onLoadSuccess={(num) => onDocumentLoadSuccess(num)}>
                        {Array.apply(null, { length: numPages }).map((val, index) => {
                            return index + 1;
                        }).map((pages, i) => (
                            <Box key={i} className={styles.doc_pdf_viewer}>
                                <Page width={520} height={40} pageNumber={pages} />
                            </Box>
                        ))}
                    </Document>
                </Box>
            </Box>
        </Modal>
    )
};

export default DocumentViewModal;