import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const uploadDocuments = createSlice({
    name: 'document',
    initialState,
    reducers: {
        uploadDocument: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { uploadDocument } = uploadDocuments.actions;

export default uploadDocuments.reducer;