import React, { useEffect, useRef, useState } from "react";
import SignatureImage from "./SignatureImage";
import Checkbox from '@mui/material/Checkbox';
import './styles/PDFData.css';
import { LiaCheckSolid } from "react-icons/lia";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Box, Tooltip } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";

const exceptSymbols = ["e", "-", "."];
const PDFDragField = ({ formDetails, selectHandeler, onChangeHandler, handleResizeHandler, signType, currentFormat, handleRemoveSignField, approvals, getWidth, pageProperty, textAreaRefs }) => {
    const [select, setSelect] = useState(null);
    const [optionSelect, setOptionSelect] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [toggleDatePicker, setToggleDatePicker] = useState(false);
    const [open, setOpen] = useState(false);

    const box = useRef(null);
    const signature_id = useSelector((state) => state.signature_id.value);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (box.current && !box.current.contains(event.target)) {
                setSelect(null);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);


    /** MOVE */

    let moveX, moveY, moveElement, moveItem, moveIndex, field_value;
    const startMove = (ev, item, index, fieldValue) => {
        if (!approvals) {
            moveX = ev.pageX;
            moveY = ev.pageY;
            moveElement = ev.target;
            moveItem = item;
            moveIndex = index;
            field_value = fieldValue;
            ev.stopPropagation();
            window.addEventListener('mousemove', handleMove)
            window.addEventListener('mouseup', stopMove)
        }
    };

    const getMoveRECT = (ev) => {
        const pageX = ev.pageX;
        const pageY = ev.pageY;

        const left = moveItem.left + (pageX - moveX);
        const top = moveItem.top + (pageY - moveY);

        return { left, top };
    };

    let fMoveLeft, fMoveTop;
    const handleMove = (ev) => {
        const data = getMoveRECT(ev);

        let fMoveRight = data?.left <= (pageProperty?.width - moveItem?.width);
        let fMoveBottom = data?.top <= (pageProperty?.height - moveItem?.height);

        if (data && data?.top >= 0 && data?.left >= 0 && fMoveRight && fMoveBottom) {
            const { left, top } = data;

            if (left > -1 && top > -1) {
                fMoveLeft = left;
                moveElement.style.left = left + 'px';

                fMoveTop = top;
                moveElement.style.top = top + 'px';
            }
        }
    };

    const stopMove = (ev) => {
        window.removeEventListener('mousemove', handleMove);
        window.removeEventListener('mouseup', stopMove);

        const data = getMoveRECT(ev);
        let fMoveRight = data?.left <= (pageProperty?.width - moveItem?.width);
        let fMoveBottom = data?.top <= (pageProperty?.height - moveItem?.height);

        if (data) {
            let left, top;
            if (data.left > -1 && data.top > -1 && fMoveRight && fMoveBottom) {
                left = data.left;
                top = data.top;
            } else {
                left = fMoveLeft ?? 0;
                top = fMoveTop;
            }
            handleResizeHandler(left, top, null, null, moveIndex, field_value);

            if (select && (moveElement.id === 'multiline-text' || moveElement.id === 'sign-resize')) {
                let current = document.getElementById('resize-box');
                if (current) {
                    current.style.left = left + 'px';
                    current.style.top = top + 'px';
                }
            }
        }
        moveX = moveY = moveElement = moveIndex = moveItem = fMoveLeft = fMoveTop = undefined;
    };

    let id = undefined;
    const startResize = (event, eleId) => {
        id = eleId;
        event.stopPropagation();
        event.preventDefault();
        window.addEventListener('mousemove', handleResize);
        window.addEventListener('mouseup', stopResize);
    };


    /** RESIZE */
    const getRECTData = (event, boundingClient, item) => {

        if (!id) {
            return undefined;
        }

        let data = {};

        const pageX = event.pageX;
        const pageY = event.pageY;

        const x = boundingClient.x;
        const y = boundingClient.y;

        let diffX, diffY;
        switch (id) {
            case 'top-left':
                diffX = x - pageX;
                diffY = y - pageY;

                data.left = item.left - diffX;
                data.top = item.top - diffY;

                data.width = item.width + diffX;
                data.height = Number(item.height) + diffY;

                return data;

            case 'bottom-left':
                diffX = x - pageX;
                diffY = pageY - (y + Number(item.height));

                data.left = item.left - diffX;
                data.top = item.top;

                data.width = item.width + diffX;
                data.height = Number(item.height) + diffY;

                return data;

            case 'top-right':
                diffX = pageX - (x + item.width);
                diffY = y - pageY;

                data.left = item.left;
                data.top = item.top - diffY;

                data.width = item.width + diffX;
                data.height = Number(item.height) + diffY;

                return data;

            case 'bottom-right':
                diffX = pageX - (x + item.width);
                diffY = pageY - (y + Number(item.height));

                data.left = item.left;
                data.top = item.top;

                data.width = item.width + diffX;
                data.height = Number(item.height) + diffY;

                return data;
            default:
                break;
        }
    };

    let fLeft, fTop, fWidth, fHeight;
    const stopResize = (event) => {
        window.removeEventListener('mousemove', handleResize);
        window.removeEventListener('mouseup', stopResize);

        // let element = document.getElementById('multiline-text');
        let elementsList = document?.querySelectorAll("#multiline-text, #sign-resize");
        const elementsArray = [...elementsList];
        let element = '';
        elementsArray?.forEach(ele => {
            if (ele?.id === 'multiline-text' && selectedItem?.field_key === 'Text' && (ele?.name === selectedItem?.field_value || ele?.title === selectedItem?.field_value)) {
                element = ele
            } else if (ele?.id === 'sign-resize' && selectedItem?.field_key === 'Signature' && (ele?.name === selectedItem?.field_value || ele?.title === selectedItem?.field_value)) {
                element = ele
            }
        });

        if (element && selectedItem && id) {
            let boundingClient = element.getBoundingClientRect();

            const data = getRECTData(event, boundingClient, selectedItem);

            if (data) {
                const { left, top, width, height } = data;

                if (left >= 0 && top >= 0 && width > 60 && height > 30) {
                    // if (selectedItem?.field_key !== 'Signature') {
                    setResizeData(left, top, width, height, element, selectedItem?.field_value);
                    // } else {
                    //     if (left >= 0 && top >= 0 && width >= 110 && height >= 62) {
                    //         setResizeData(left, top, width, 62, element);
                    //     } else {
                    //         setResizeData(left, top, width <= 110 ? 110 : width, 62, element);
                    //     }
                    // }
                } else {
                    setResizeData(fLeft, fTop, fWidth, fHeight, element, selectedItem?.field_value);
                }
            }
            id = undefined;
        }
    };

    const handleResize = (event) => {
        let elementsList = document?.querySelectorAll("#multiline-text, #sign-resize");
        const elementsArray = [...elementsList];
        let element;
        elementsArray?.forEach(ele => {
            if (ele?.id === 'multiline-text' && selectedItem?.field_key === 'Text' && (ele?.name === selectedItem?.field_value || ele?.title === selectedItem?.field_value)) {
                element = ele
            } else if (ele?.id === 'sign-resize' && selectedItem?.field_key === 'Signature' && (ele?.name === selectedItem?.field_value || ele?.title === selectedItem?.field_value)) {
                element = ele
            }
        });

        if (element && selectedItem && id) {
            let boundingClient = element.getBoundingClientRect();
            const data = getRECTData(event, boundingClient, selectedItem);

            if (data) {
                const { left, top, width, height } = data;
                // if (selectedItem?.field_key !== 'Signature') {
                if (left >= 0 && top >= 0 && width > 60 && height > 30) {
                    handleResizeBox(left, top, width, height);
                }
                // } else {
                //     if (left >= 0 && top >= 0 && width >= 110 && height >= 62) {
                //         handleResizeBox(left, top, width, 62);
                //     } else {
                //         handleResizeBox(left, top, width <= 110 ? 110 : width, 62);
                //     }
                // }
            }
        }
    };

    const handleResizeBox = (left, top, width, height) => {
        let current = document.getElementById('resize-box');
        fLeft = left;
        current.style.left = left + 'px';
        fTop = top;
        current.style.top = top + 'px';

        fWidth = width;
        current.style.width = width + 'px';
        fHeight = height;
        current.style.height = height + 'px';
    };

    const setResizeData = (left, top, width, height, element, field_value) => {
        element.style.left = left + 'px';
        element.style.top = top + 'px';

        element.style.width = width + 'px';
        element.style.height = height + 'px';

        handleResizeHandler(left, top, Math.round(width), Math.round(height), select, field_value);
        fLeft = fTop = fWidth = fHeight = undefined;
    };

    const handleDatePicker = (value, index, format, type, field_value) => {
        const formats = ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD', 'DD/MMM/YYYY', 'MMM/DD/YYYY'];
        let formatDate = moment(value).format(formats[format - 1]);
        onChangeHandler(formatDate, index, 'value', type, field_value)
    };

    const handleSignPopup = (value, key, index) => {
        setOpen(value);
        if (!value && !signature_id && key === 'close_icon') {
            handleRemoveSignField(index);
        };
    };

    return (
        <div ref={box}>
            {formDetails && formDetails?.map((item, index) => {
                if (item?.sign_type === signType) {
                    let place_holder = item?.sign_type === 'Sign myself';
                    switch (item.field_key) {
                        case 'Name':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value} >
                                <input ref={(el) => (textAreaRefs.current[index] = el)} className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} type="text" placeholder={place_holder ? item.field_key : ''}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }}
                                    onChange={(event) => !approvals && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                            </div>

                        case 'Text':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index), setSelectedItem(item)]} key={item?.field_value + index} >
                                {select === index &&
                                    <div title={item?.field_value} id='resize-box' className="pdf-select-field-drag" style={{ position: "absolute", top: `${item.top}px`, left: item.left, width: item.width + 'px', height: item.height + 'px', backgroundColor: "transparent", padding: '4px 0px 4px 2px' }}>
                                        <div onMouseDown={(ev) => startResize(ev, "top-left")} className="circle-container" style={{ position: 'absolute', top: '-5px', left: '-5px', cursor: 'nwse-resize' }} />
                                        <div onMouseDown={(ev) => startResize(ev, "top-right")} className="circle-container" style={{ position: 'absolute', top: '-5px', right: '-5px', cursor: 'nesw-resize' }} />
                                        <div onMouseDown={(ev) => startResize(ev, "bottom-left")} className="circle-container" style={{ position: 'absolute', left: '-5px', bottom: '-5px', cursor: 'nesw-resize' }} />
                                        <div onMouseDown={(ev) => startResize(ev, "bottom-right")} className="circle-container" style={{ position: 'absolute', right: '-5px', bottom: '-5px', cursor: 'nwse-resize' }} />
                                    </div>
                                }
                                <textarea ref={(el) => (textAreaRefs.current[index] = el)} name={item?.field_value} id="multiline-text" className="pdf-field-drag" value={item?.sign_type === 'Send to others' ? item?.initial_value : item?.value} type="text" placeholder={place_holder ? item.field_key : ''}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color, padding: '4px 0px 4px 2px' }}
                                    onChange={(event) => !approvals && onChangeHandler(event.target.value, index, item?.sign_type === 'Send to others' ? 'initial_value' : 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />

                            </div>

                        case 'Radio':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value} style={{ position: 'absolute', width: item.width, top: item.top, left: item.left }}>
                                {item?.options?.map((list, idx) => {
                                    return <div onClick={() => !approvals && setOptionSelect(idx)} style={{ marginBottom: '3px', backgroundColor: item?.background }} className={optionSelect === idx ? "pdf-radio-active" : "pdf-radio"} key={list?.id} onDragStart={(e) => e.preventDefault()} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)}>
                                        <input className="radio-select-field" style={{ opacity: list.is_selected ? '1' : '0', fontSize: item?.fontSize + 'px' }}
                                            onChange={e => { }}
                                        />
                                    </div>
                                })}
                            </div>

                        case 'Checkbox':
                            return <div style={{ width: '20px', top: item.top, left: item.left, position: 'absolute', cursor: 'move' }} onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value}>
                                {item?.options?.map((list, idx) => {
                                    return <div style={{ border: '1px solid #e0d7ff', width: '20px', height: '20px', marginBottom: '5px', backgroundColor: item?.background }} key={list?.id} onDragStart={(e) => e.preventDefault()} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)}>
                                        <Checkbox disableRipple icon={<Box></Box>} checkedIcon={<Box><LiaCheckSolid color="var(--primary)" style={{
                                            translate: '3% -2px'
                                        }} /></Box>} checked={list?.is_selected} sx={{
                                            border: 'none', padding: 0, '& *': {
                                                padding: 0
                                            }
                                            , '& .css-ex1lad': {
                                                display: 'flex'
                                            },
                                            cursor: 'move'
                                        }} onDragStart={(e) => e.preventDefault()} disabled={approvals} />
                                    </div>
                                })}
                            </div>

                        case 'Dropdown':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value}>
                                <input ref={(el) => (textAreaRefs.current[index] = el)} className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} type='text'
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }} key={index} placeholder={item.field_value}
                                    onChange={(event) => !approvals && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                            </div>
                        case 'Url':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value}>
                                <input ref={(el) => (textAreaRefs.current[index] = el)} className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} type="text" placeholder={place_holder ? item.field_key : ''}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }}
                                    onChange={(event) => !approvals && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                            </div>
                        case 'Date':
                            return <Box onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value}>
                                <input ref={(el) => (textAreaRefs.current[index] = el)} onClick={() => item?.sign_type === 'Sign myself' && setToggleDatePicker(index)}
                                    className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} type="text" placeholder={place_holder ? item.field_key : ''}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }}
                                    onChange={(event) => item?.sign_type === 'Sign myself' ? {} : !approvals && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                                <LocalizationProvider dateAdapter={AdapterDayjs} key={item?.field_value}>
                                    <Box sx={{ position: 'absolute', top: item.top, left: item.left }}>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                '& div': {
                                                    position: 'absolute', padding: 0
                                                },
                                            }}
                                        >
                                            <DatePicker
                                                viewRenderers={null}
                                                sx={{
                                                    '& .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root': {
                                                        scale: '0.7',
                                                    },
                                                    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
                                                        opacity: 0,
                                                        pointerEvents: 'none',
                                                    },
                                                    '& .css-nk89i7-MuiPickersCalendarHeader-root': {
                                                        background: 'red',
                                                    },
                                                    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                                                        padding: 0,
                                                        border: 'none'
                                                    },
                                                    '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                                                        display: 'none',
                                                        border: 'none'
                                                    },
                                                    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                                        borderWidth: '0px'
                                                    },
                                                    '& .css-3gxj82.Mui-error .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none'
                                                    },
                                                    '& .css-vubbuv': {
                                                        display: 'none'
                                                    },
                                                    '& .css-igs3ac': {
                                                        borderWidth: '0px'
                                                    },
                                                    fontFamily: 'var(--primary-font-family)',
                                                    color: 'var(--table-font-color)',
                                                    position: 'relative',
                                                }}
                                                closeOnSelect={true}
                                                open={toggleDatePicker === index}
                                                onClose={() => setToggleDatePicker(false)}
                                                minDate={dayjs(new Date())}
                                                format='DD-MM-YYYY'
                                                value={dayjs(item.value, currentFormat)}
                                                onChange={(date) => {
                                                    handleDatePicker(date.$d, index, item?.date_format, item?.type, item?.field_value)
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </LocalizationProvider>
                            </Box>
                        case 'Email':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value} >
                                <input ref={(el) => (textAreaRefs.current[index] = el)} className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} type="text" placeholder={place_holder ? item.field_key : ''}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }}
                                    onChange={(event) => !approvals && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                            </div>
                        case 'Signature':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index), setSelectedItem(item)]} key={item?.field_value} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)}>
                                {select === index &&
                                    <div title={item?.field_value} id='resize-box' className="pdf-select-field-drag" style={{ position: "absolute", top: `${item.top}px`, left: item.left, width: item.width + 'px', height: item.height + 'px', backgroundColor: "transparent", zIndex: '100' }}>
                                        <div onMouseDown={(ev) => startResize(ev, "top-left")} className="circle-container" style={{ position: 'absolute', top: '-5px', left: '-5px', cursor: 'nwse-resize' }} />
                                        <div onMouseDown={(ev) => startResize(ev, "top-right")} className="circle-container" style={{ position: 'absolute', top: '-5px', right: '-5px', cursor: 'nesw-resize' }} />
                                        <div onMouseDown={(ev) => startResize(ev, "bottom-left")} className="circle-container" style={{ position: 'absolute', left: '-5px', bottom: '-5px', cursor: 'nesw-resize' }} />
                                        <div onMouseDown={(ev) => startResize(ev, "bottom-right")} className="circle-container" style={{ position: 'absolute', right: '-5px', bottom: '-5px', cursor: 'nwse-resize' }} />
                                    </div>
                                }
                                {item?.sign_type === 'Sign myself' ?
                                    <div title={item?.field_value} id="sign-resize" className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} key={item?.field_value} onDragStart={(e) => e.preventDefault()}
                                        style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item?.height, background: item.background, fontSize: item?.fontSize + 'px' }} onClick={() => setOpen(true)}>
                                        <SignatureImage
                                            getOpenSignature={(value, key) => handleSignPopup(value, key, index)}
                                            openSignature={open}
                                            getWidth={(val) => getWidth(val, 'width', index)}
                                        />
                                    </div>
                                    :
                                    <input ref={(el) => (textAreaRefs.current[index] = el)} name={item?.field_value} id="sign-resize" className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value}
                                        style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', background: item.background, fontSize: item?.fontSize + 'px', color: item?.color }} key={index}
                                        onChange={(event) => !approvals && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                                }
                            </div>
                        case 'Company':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value}>
                                <input ref={(el) => (textAreaRefs.current[index] = el)} className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} type="text" placeholder={place_holder ? item.field_key : ''}
                                    style={{ position: 'absolute', zIndex: 0, top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }}
                                    onChange={(event) => !approvals && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                            </div>
                        case 'Number':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value}>
                                <input ref={(el) => (textAreaRefs.current[index] = el)} className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} placeholder={place_holder ? item.field_key : ''}
                                    type={item?.sign_type === 'Sign myself' ? 'number' : 'text'} pattern="[0-9]*" onKeyDown={(evt) => item?.sign_type === 'Sign myself' && exceptSymbols.includes(evt.key) && evt.preventDefault()}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }}
                                    onChange={(event) => !approvals && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                            </div>
                        case 'Title':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value}>
                                <input ref={(el) => (textAreaRefs.current[index] = el)} className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} type="text" placeholder={place_holder ? item.field_key : ''}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }}
                                    onChange={(event) => !approvals && !item?.board_details && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                            </div>
                        case 'Initial':
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value} >
                                <input ref={(el) => (textAreaRefs.current[index] = el)} className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} type="text" placeholder={place_holder ? item.field_key : ''}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }}
                                    onChange={(event) => !approvals && onChangeHandler(event.target.value, index, 'value', item?.type, item?.field_value)} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                            </div>
                        default:
                            return <div onClick={() => [selectHandeler(item, index), setSelect(index)]} key={item?.field_value}>
                                <Tooltip title={item?.field_key === "People" ? item.value ?? '' : ''} placement="top">
                                    <input ref={(el) => (textAreaRefs.current[index] = el)} className={select === index ? "pdf-select-field-drag" : "pdf-field-drag"} value={item.value} type="text" placeholder={place_holder ? item.field_key : ''}
                                        style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', padding: '4px 0px 4px 2px', background: item.background, fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color }}
                                        onChange={(event) => { }} onMouseDown={(ev) => startMove(ev, item, index, item?.field_value)} onDragStart={(e) => e.preventDefault()} />
                                </Tooltip>
                            </div>
                    }
                }
            })}
        </div>
    )
};

export default PDFDragField;