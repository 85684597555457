import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    admintableinfo: '',
};

export const adminDetails = createSlice({
    name: "admin_details",
    initialState,
    reducers: {
        adminDetail: (state, action) => {
            state.admintableinfo = action.payload.data;

        },
    },
});
export const { adminDetail } = adminDetails.actions;


export default adminDetails.reducer;