import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const signIdDetails = createSlice({
    name: 'signId_details',
    initialState,
    reducers: {
        signIdDetail: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { signIdDetail } = signIdDetails.actions;

export default signIdDetails.reducer;