import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import './PDFSignPreview.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFSignPreview = (props) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [numPages, setNumPages] = useState([]);
    const [file, setFile] = useState('');
    const [tagColor, setTagColor] = useState({});

    useEffect(() => {
        let arr = {};
        Object?.keys(props?.formData)?.map((list) => {
            props?.formData[list]?.map((item) => {
                if (!arr[list]) {
                    arr[list] = [item?.background]
                } else if (arr[list].indexOf(item.background) === -1) {
                    arr[list]?.push(item.background);
                }
            });
        })

        setTagColor(arr);
    }, [pageNumber, props?.formData]);

    useEffect(() => {
        setPageNumber(Number(props?.selectPageNumber));
        setFile(props?.file);
    }, [props?.file, props?.selectPageNumber]);

    const onDocumentLoadSuccess = async (numPages) => {
        setNumPages([numPages?.numPages]);
    };

    const onPageHandler = (val) => {
        props.pageNumber(val);
        setPageNumber(val);
    };

    return (
        <Box className="sign-preview-wrapper" style={{ overflow: numPages > 3 ? 'auto' : 'hidden' }}>
            <Box className="sign-leftbar-container">
                {file && <Document file={file} onLoadSuccess={(num) => onDocumentLoadSuccess(num)}>
                    {Array.apply(null, { length: numPages }).map((val, index) => {
                        return index + 1;
                    }).map((pages, i) => (
                        <Box key={i} className='sign-pdf-preview-container'>
                            <Box className={i + 1 === pageNumber ? "sign-pdf-select-preview" : "sign-pdf-viewer-preview"}
                                onClick={() => onPageHandler(i + 1)}>
                                <Box className="sign-preview-body">
                                    <Page width={90} height={100} pageNumber={pages} />
                                    <Box className="sign-tag-container">
                                        {tagColor[pages]?.map((item, i) => {
                                            let color = 'var(--primary)';
                                            return (
                                                <Box className="sign-tag-body" key={i}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="8" viewBox="0 0 23 8" fill="none">
                                                        <path d="M0.0118625 3.99573L5.46361 0.541749L5.44307 7.46254L0.0118625 3.99573Z" fill={color} />
                                                        <rect x="5.60638" y="0.614746" width="25.3936" height="6.76201" fill={color} />
                                                    </svg>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                </Box>
                                <Typography className="sign-page-number">{i + 1}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Document>}
            </Box>
        </Box>
    )
};

export default PDFSignPreview;