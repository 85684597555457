import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const documentDetails = createSlice({
    name: 'document_details',
    initialState,
    reducers: {
        documentDetail: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { documentDetail } = documentDetails.actions;

export default documentDetails.reducer;