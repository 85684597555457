import { Box, Grid, Step, StepLabel, Stepper, TextField } from "@mui/material";
import React, { useState } from "react";
import AddTemplates from "../MyDocument/AddTemplates";
import styles from "./CreateTemplates.module.css";
import TextEditor from "../MyDocument/textEditor";
import PDFView from "../MyDocument/PDFView";

const steps = ["Create", "Edit", "Confirmation"];

const CreateTemplates = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState('');

  const handleValueChange = (newValue) => {
    setActiveStep(newValue);
  };
  return (
    <Grid className={styles.top_grid}>
      <Stepper className={styles.top_stepper} nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (

          <Step
            // onClick={setActiveStep(index)}
            className={
              index < activeStep
                ? styles.completed
                : index === activeStep && styles.active
            }

            key={label}

          >
            <StepLabel
              className={styles.lable_value}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && <AddTemplates steps={handleValueChange} />}
      {activeStep === 1 && <TextEditor steps={handleValueChange} />}
      {activeStep === 2 && <PDFView steps={handleValueChange} />}
    </Grid>
  );
};

export default CreateTemplates;
