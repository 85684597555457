import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Navigation = () => {
    const navigate = useNavigate();

    const org_id = useSelector((state) => state?.document?.value?.OrgId);

    const navigateHandle = (path, state) => {
        const finalPath = '/c' + org_id + path
        navigate(finalPath, state && {state: state})

    }; return navigateHandle
}