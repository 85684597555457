import axios from "axios";

export const getDocuments = (data, resp, error) => {
    axios.get(process.env.REACT_APP_DOCUMENT, { withCredentials: process.env.REACT_APP_CREDENTIALS, params: data})
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};