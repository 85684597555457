import { Box, Button, InputLabel, TextField, TextareaAutosize, Autocomplete, Typography, IconButton } from '@mui/material'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import styles from './ScheduleForm.module.css'
import { schedule_name_action } from '../../../store/schedule/scheduleAction';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { toggleLoader } from '../../../store/loader/loaderReducer';
import axios from 'axios';
import moment from 'moment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../../../../src/component/styles/PDFFields.css';

const ScheduleForm = ({ timeValues, dateValues, sendTime, toggleClose }) => {
    const [emailGuest, setEmailGuest] = useState(false);
    const [guestInput, setGuestInput] = useState('');


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const finalDate = { date: moment(dateValues).format("DD-MM-YYYY"), time: timeValues }

    const scheduleScheme = yup.object().shape({
        scheduleEvent: yup.object().shape({
            name: yup.string().required('Name is required'),
            company: yup.string().required('Company is required'),
            phone: yup.string().required('Phone Number is required'),
            email: yup.string().required('Enter email').email('Enter valid email'),
        }),
    });

    const scheduleDetail = useFormik({
        initialValues: {
            scheduleEvent: {
                name: '',
                email: '',
                company: '',
                phone: '',
                notes: '',
                guests: [],
            },
        },
        enableReinitialize: true,
        validationSchema: scheduleScheme,
        onSubmit: (values) => {
            const data = {
                timeValues, dateValues, sendTime, ...values
            }
            dispatch(schedule_name_action(data))

            const formData = {
                event: {
                    start_time: finalDate,
                    duration: '15 min',
                    description: values.scheduleEvent.notes,
                    guests: values.scheduleEvent.guests,
                },
                user_info: {
                    full_name: values.scheduleEvent.name,
                    email: values.scheduleEvent.email,
                    phone_number: values.scheduleEvent.phone,
                    timezone: "Asia/Calcutta",
                    company: values.scheduleEvent.company,
                },
            }

            dispatch(toggleLoader(true));
            axios.post(process.env.REACT_APP_BOOK_DEMO, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    toast.success('Successfully meeting has been scheduled');
                    navigate('/invitation');
                })
                .catch((err) => {
                    toast.error(err.message);
                    dispatch(toggleLoader(false))

                })
        },
    });

    const handleChange = (val, name, event) => {
        if (name === 'guests') {
            scheduleDetail.setFieldValue(
                'scheduleEvent.guests', val
                // [...scheduleDetail.values.scheduleEvent.guests, ...val]
            );
        } else {
            scheduleDetail.setFieldValue(
                'scheduleEvent',
                { ...scheduleDetail.values.scheduleEvent, [name]: val }
            );
        }
    };
    const scheduleErr = scheduleDetail.errors.scheduleEvent;
    const scheduleTouched = scheduleDetail.touched.scheduleEvent;

    return (
        <Box className={styles.form_body}>
            <Box className={styles.top_text}>
                <IconButton className={styles.back_icon} onClick={() => toggleClose(false)} ><ArrowBackIcon /></IconButton>
                <Box className={styles.intro_text_body}><Typography className={styles.intro_text}>Introduction to Centilio Sign</Typography></Box>
            </Box>
            <Box className={styles.top_border} />
            <Box className={styles.form_container}>
                <Box className={styles.time_wrapper}><AccessTimeIcon className={styles.date_icon} /> 15 min</Box>
                <Box className={styles.time_wrapper}><CalendarTodayIcon className={styles.date_icon} />{timeValues} - {sendTime} {moment(dateValues).format("dddd, MMMM D, YYYY")}</Box>
                <Box className={styles.time_wrapper} >
                    <img
                        src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/standard-time.svg'}
                        className={styles.standard_icon}
                        alt='standard-time'
                    />
                    India Standard Time
                </Box>
            </Box>
            <Box className={styles.input_container}>
                <InputLabel className='add_recepient_label'>
                    Name <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <TextField
                    onChange={(e) => handleChange(e.target.value, 'name')}
                    value={scheduleDetail.values.scheduleEvent.name}
                    error={!!scheduleTouched?.name && scheduleErr?.name}
                    helperText={scheduleTouched?.name && scheduleErr?.name}
                    sx={{
                        fieldset: { border: "1px solid #E6E7E9" },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: 'var(--primary)',
                            },
                            fontSize: '14px',
                            paddingLeft: '2px'
                        },
                    }}
                    name='name'
                    variant='outlined'
                    placeholder="Your name"
                    className='recipient-input'
                />
            </Box>
            <Box className={styles.input_container}>
                <InputLabel className='add_recepient_label'>
                    Email <span style={{ color: 'red' }}>* </span>
                </InputLabel>
                <TextField
                    onChange={(e) => handleChange(e.target.value, 'email')}
                    value={scheduleDetail.values.scheduleEvent.email}
                    error={!!scheduleTouched?.email && scheduleErr?.email}
                    helperText={scheduleTouched?.email && scheduleErr?.email}
                    sx={{
                        fieldset: { border: "1px solid #E6E7E9" },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: 'var(--primary)',
                            },
                            fontSize: '14px',
                            paddingLeft: '2px'
                        },
                    }}
                    placeholder="Your email"
                    name='email'
                    variant='outlined'
                    className='recipient-input'
                />
            </Box>
            {
                emailGuest ?
                    <Box className={styles.input_container}>
                        <InputLabel className='add_recepient_label'>
                            Email Guests
                        </InputLabel>
                        <Box>
                            <Autocomplete
                                multiple
                                options={[]}
                                value={scheduleDetail.values.scheduleEvent.guests}
                                disableClearable={true}
                                freeSolo
                                onChange={(e, val) => {
                                    handleChange(val, 'guests', val)
                                    setGuestInput('')
                                }}
                                // onChange={(e) => handleChange(e.target.value, 'guests')}
                                // getOptionLabel={o => o.a}
                                onInputChange={e => {
                                    setGuestInput(e.target.value)
                                }}
                                onBlur={e => {
                                    if (e.target.value) {
                                        scheduleDetail.setFieldValue(
                                            'scheduleEvent.guests',
                                            [...scheduleDetail.values.scheduleEvent.guests, e.target.value]
                                        );
                                        setGuestInput('')
                                    }
                                }}
                                inputValue={guestInput}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            name='guests'
                                            value={scheduleDetail.values.scheduleEvent.guests}
                                            error={!!scheduleTouched?.guests && scheduleErr?.guests}
                                            helperText={scheduleTouched?.guests && scheduleErr?.guests}
                                        />
                                    )
                                }}
                            />
                        </Box>
                    </Box> :
                    <Button variant='contained' onClick={() => setEmailGuest(!emailGuest)} className={styles.add_guests}>Add guests</Button>
            }
            <Box className={styles.input_container}>
                <InputLabel className='add_recepient_label'>
                    Company <span style={{ color: 'red' }}>* </span>
                </InputLabel>
                <TextField
                    onChange={(e) => handleChange(e.target.value, 'company')}
                    value={scheduleDetail.values.scheduleEvent.company}
                    error={!!scheduleTouched?.company && scheduleErr?.company}
                    helperText={scheduleTouched?.company && scheduleErr?.company}
                    sx={{
                        fieldset: { border: "1px solid #E6E7E9" },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: 'var(--primary)',
                            },
                            fontSize: '14px',
                            paddingLeft: '2px'
                        },
                    }}
                    name='company'
                    variant='outlined'
                    className='recipient-input'
                />
            </Box>
            <Box className={styles.input_container}>
                <InputLabel className='add_recepient_label'>
                    Phone Number<span style={{ color: 'red' }}>* </span>
                </InputLabel>
                <TextField
                    onChange={(e) => handleChange(e.target.value, 'phone')}
                    value={scheduleDetail.values.scheduleEvent.phone}
                    error={!!scheduleTouched?.phone && scheduleErr?.phone}
                    helperText={scheduleTouched?.phone && scheduleErr?.phone}
                    sx={{
                        fieldset: { border: "1px solid #E6E7E9" },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: 'var(--primary)',
                            },
                            fontSize: '14px',
                            paddingLeft: '2px'
                        },
                    }}
                    name='phone'
                    variant='outlined'
                    className='recipient-input'
                />
            </Box>
            <Box className={styles.input_container}>
                <InputLabel className='add_recepient_label'>
                    Please share anything that will help prepare for our meeting.
                </InputLabel>
                <TextareaAutosize
                    onChange={(e) => handleChange(e.target.value, 'notes')}
                    value={scheduleDetail.values.scheduleEvent.notes}
                    error={!!scheduleTouched?.notes && scheduleErr?.notes}
                    helperText={scheduleTouched?.notes && scheduleErr?.notes}
                    name='notes'
                    variant='outlined'
                    className={styles.schedule_textarea}
                />
            </Box>
            <Button variant='contained' className={styles.schedule_button} onClick={scheduleDetail.handleSubmit}>Schedule Event</Button>
        </Box>
    );
};
export default ScheduleForm