import { Box, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Home.module.css';
import { formatBytes } from '../../utils/commonFunction';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { Navigation } from '../../utils/handleNavigation';
import Skeleton from '@mui/material/Skeleton';
import { toast } from 'react-toastify';

const Home = () => {
  const dispatch = useDispatch();
  const navPath = Navigation();
  const document = useSelector((state) => state?.document?.value);
  const get_document = useSelector((state) => state.welcomeImgDetail.value);

  const [dashboard, setDashboard] = useState({});
  const [activeIdx, setActiveIdx] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [menuProcess, setMenuProcess] = useState([]);
  const [accordion, setAccordion] = useState(false);

  useEffect(() => {
    fetchDashboard();
    if (!get_document?.is_document_present) {
      setAccordion(true);
    }
  }, []);

  const fetchDashboard = () => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_DASHBOARD, {
      withCredentials: process.env.REACT_APP_CREDENTIALS,
      params: { org_id: document?.OrgId },
    })
      .then((response) => {
        setDashboard(response.data);
        let data = JSON.parse(response.data.user_setup_guide_info);
        let per = 0;
        let arr = [];
        Object.keys(data).forEach((key) => {
          if (data[key]) {
            arr.push(key);

          }
          if (data[key] === 1) {
            per = per + 1;
          }
        });
        setPercentage(per);
        setMenuProcess(arr);
      })
      .catch((err) => {
        toast.error(err.message)
        dispatch(toggleLoader(false))
      })
      .finally(() => dispatch(toggleLoader(false)));
  };

  const documentsShared = [
    {
      name: 'Sent',
      count: dashboard?.sign_journey_report?.send,
      bg: '#E2FFD4',
    },
    {
      name: 'Viewed',
      count: dashboard?.sign_journey_report?.viewed,
      bg: '#DCFDFF',
    },
    {
      name: 'Declined',
      count: dashboard?.sign_journey_report?.declined,
      bg: '#FFE2E2',
    },
    {
      name: 'Recalled',
      count: dashboard?.sign_journey_report?.recalled,
      bg: '#FFF0E5',
    },
    {
      name: 'Expired',
      count: dashboard?.sign_journey_report?.expired,
      bg: '#FFF8D1',
    },
    {
      name: 'Completed',
      count: dashboard?.sign_journey_report?.completed,
      bg: '#CFD',
    },
  ];

  const CentilioConfigArr = [
    {
      name: 'Customise your signature',
      description: 'Create a personalized signature for your documents in settings.',
      icon: 'PencilLine.svg',
      user_setup_info: 'signature',
      position: 1
    },
    {
      name: 'Upload and send your document',
      description: 'Upload,send and track your documents in realtime.',
      icon: 'UploadSymbol.svg',
      user_setup_info: 'documents',
      position: 2
    },
    {
      name: 'Create a template',
      description: 'Creates a templates for frequently  used documents to quickly send documents and save time in the future.',
      icon: 'FileText.svg',
      user_setup_info: 'template',
      position: 3
    },
    {
      name: 'Brand your account ',
      description: 'Add  a professional touch to your account by branding it with your company logo and colors.  ',
      icon: 'Circlethree.svg',
      user_setup_info: 'white_label',
      position: 4
    },
  ];
  const handleOnClick = (i) => {
    switch (i) {
      case 0:
        navPath('/settings');
        break;
      case 1:
        navPath('/upload');
        break;
      case 2:
        navPath('/templates');
        break;
      case 3:
        window.open(`${process.env.REACT_APP_ACCOUNT}/#/c${document?.OrgId}/organization`, "_blank");
        break;
      default:
        break;
    }
    setActiveIdx(i);
  };

  return (
    <>
      {Object.keys(dashboard).length !== 0 ? (
        <Box className={styles.main_wrap}>
          <div className={styles.progress_container}>
            <Accordion style={{ backgroundColor: '#FAFAFBFF', boxShadow: 'none' }} expanded={accordion} onChange={() => setAccordion(!accordion)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Box className={styles.centilio_container}>
                  <Box className={styles.start_centilio}>
                    Get Started With Centilio
                  </Box>
                  <LinearProgress
                    variant='determinate'
                    value={percentage * 25}
                    style={{ borderRadius: '4px' }}
                  />
                  <Box display='flex' alignItems='center' width='100%' >
                    <Box width='100%' mr={1}>
                      <LinearProgress
                        sx={{
                          backgroundColor: '#ECF2FF',
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: 'var(--sidebar-icon-color)'
                          }
                        }}
                        variant='determinate'
                        value={percentage * 25}
                        style={{ borderRadius: '4px' }}
                      />
                    </Box>
                    <Box minWidth={35}>
                      <Typography variant='body2'>{`${percentage * 25}%`}</Typography>
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={(theme) => ({
                    display: 'grid',
                    gap: '10px',
                    gridTemplateColumns: '1fr 1fr',
                    width: '100%',
                    [theme.breakpoints.down('1000')]: {
                      gridTemplateColumns: '1fr',
                    },
                    marginBottom: '40px',
                  })}
                >
                  {CentilioConfigArr.map((item, i) => {
                    return (
                      <Box
                        onClick={() => {
                          handleOnClick(i);
                        }}
                        key={item.name}
                        className={menuProcess?.indexOf(item?.user_setup_info) !== -1 ? styles.complete_fields : styles.start_fields}
                      >
                        <Box>
                          {menuProcess?.indexOf(item?.user_setup_info) !== -1 ?
                            <Box className={styles.menuProcess_active_icon}>
                              <Box className={styles.active_menuprocess}>
                                <img
                                  src={process.env.REACT_APP_IMAGE_URL + 'ToggleSvg/Vector-icon.svg'}
                                  className={styles.vector_icon_img}
                                  alt=''
                                />
                              </Box>
                            </Box>
                            :
                            <Box className={styles.menuprocess}>{item.position}</Box>
                          }
                        </Box>
                        <Box className={styles.field_name}>
                          {item.name}
                          <Box className={styles.field_description}>
                            {item.description}
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          </div>

          <Box className={styles.welcome_used_wrap}>
            <Box className={styles.welcome_card}>
              <Box className={styles.welcome_text}>Welcome {document?.FirstName}</Box>

              <Box className={styles.signed_new_doc_wrap}>
                <Box>
                  <Box component={'h2'} className={styles.signed_total_doc}>{dashboard?.total_documents_info?.total_siged_document}</Box>
                  <Typography className={styles.signed_doc}>Total Signed Documents</Typography>
                </Box>

                <Box>
                  <Box component={'h2'} className={styles.signed_total_doc}>{dashboard?.total_documents_info?.new_document_for_signed}</Box>
                  <Typography className={styles.signed_doc}>New documents for sign</Typography>
                </Box>
              </Box>
            </Box>

            <Box className={styles.used_storage + ' ' + styles.card}>
              <Box className={styles.used_storage_text}>Used Storage</Box>
              <Box className={styles.signed_new_doc_wrap}>
                <Box>
                  <Box component={'h2'} className={styles.signed_total}>
                    {formatBytes(dashboard?.storage_usage_info?.storage_for_document, 2)}
                  </Box>
                  <Typography className={styles.storage_bottoma_text}>For documents</Typography>
                </Box>

                <Box>
                  <Box component={'h2'} className={styles.signed_total}>
                    {formatBytes(dashboard?.storage_usage_info?.storage_for_signed_image)}
                  </Box>
                  <Typography style={{ color: 'var(--header-color)' }}>For signed images</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={styles.docs_share_wrap}>
            {documentsShared?.map((item, i) => {
              return (
                <Box
                  className={styles.card}
                  style={{
                    backgroundColor: item.bg,
                  }}
                  key={i}
                >
                  <Typography
                    className={styles.shared_name}
                  >
                    {item.name}
                  </Typography>

                  <Box component={'h2'} className={styles.signed_total}>{item.count}</Box>

                  <Typography className={styles.doc_shared}>
                    Documents Shared
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : <Box>
        <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', marginTop: '10px' }} animation="wave" variant="rounded" width={"100%"} height={70} />
        <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', marginTop: '10px' }} animation="wave" variant="rounded" width={"100%"} height={270} />
        <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', marginTop: '10px' }} animation="wave" variant="rounded" width={"100%"} height={150} />
        <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', marginTop: '10px' }} animation="wave" variant="rounded" width={"100%"} height={150} />
        </Box>}
    </>
  );
};

export default Home;
