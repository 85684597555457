import React, { useState } from "react";
import Checkbox from "react-custom-checkbox";
import { Box, MenuItem, Select, Tooltip } from "@mui/material";
import './PDFSign.css';
import PDFSignature from "./PDFSignature";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from "moment";

const exceptSymbols = ["e", "-", "."];
const PDFSignField = (props) => {
    const [signature, setSignature] = useState(false);
    const [datePicker, setDatePicker] = useState(false);

    const { formDetails, onChangeOptionHandler, onChangeHandler, formData, imgType, getImage, getText, getSignText, getFont, getSignType, getColor, signatureOptions } = props;

    const handleDatePicker = (value, index, date_format) => {
        const formats = ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD', 'DD/MMM/YYYY', 'MMM/DD/YYYY'];
        let formatDate = moment(value, 'DD/MM/YYYY').format(formats[date_format - 1]);
        onChangeHandler(formatDate, index, 'value');
        setDatePicker(false);
    };

    return (
        <>
            {formDetails && formDetails?.map((item, index) => {
                switch (item.field_key) {
                    case 'Name':
                        return <div key={`name${index}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -8] } }]
                                }}>
                                <input className="pdf-sign-field" value={item.value} type="text" placeholder={item?.placeholder}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', border: item?.is_required && !item?.value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', fontSize: item?.fontSize + 'px', backgroundColor: '#fff', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    onChange={(event) => onChangeHandler(event.target.value, index, 'value')} />
                            </Tooltip>
                        </div>

                    case 'Text':
                        return <div key={`text${index}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <textarea rows={4} className="pdf-sign-field" value={item?.value} type="text" placeholder={item?.initial_value}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', background: item?.is_required ? '#FAEEE6' : item.background, border: item?.is_required && !item?.value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', fontSize: item?.fontSize + 'px', backgroundColor: '#fff', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    onChange={(event) => onChangeHandler(event.target.value, index, 'value')} />
                            </Tooltip>
                        </div>

                    case 'Radio':
                        return <Tooltip key={`redio${index}`} title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                            tooltip: {
                                sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                            }
                        }}
                            PopperProps={{
                                modifiers: [{ name: "offset", options: { offset: [0, -1] } }]
                            }}>
                            <div style={{ position: 'absolute', width: item.width, top: item.top, left: item.left }}>
                                {item?.options?.map((list, idx) => {
                                    return <div style={{ marginBottom: '3px', top: list?.to, border: item?.is_required && !item?.selected_value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', }} className='sign_radio' key={idx + 1} >
                                        <input className="radio-select-field" style={{ opacity: list.is_selected ? '1' : '0', fontSize: item?.fontSize + 'px', }}
                                            onClick={() => onChangeOptionHandler(list?.is_selected, index, list?.value, 'RadioButton')}
                                            value={list?.is_selected}
                                            onChange={e => { }}
                                        />
                                    </div>
                                })}
                            </div>
                        </Tooltip>

                    case 'Checkbox':
                        return <Tooltip key={`checkbox${index}`} title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                            tooltip: {
                                sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                            }
                        }}
                            PopperProps={{
                                modifiers: [{ name: "offset", options: { offset: [0, -1] } }]
                            }}>
                            <div style={{ width: '20px', top: item.top, left: item.left, position: 'absolute' }}>
                                {item?.options?.map((list, idx) => {
                                    return <div key={idx} style={{ marginBottom: '5px' }}>
                                        <Checkbox
                                            icon={list.is_selected && <div className="custom-icon"><div className="icon-before" /> <div className="icon-after" /></div>}
                                            checked={list?.is_selected}
                                            value={list?.value}
                                            onChange={(value) => onChangeOptionHandler(value, index, list?.value, 'Checkbox')}
                                            style={{ border: item?.is_required && !item?.selected_value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)' }}
                                            className="checkbox-custom"

                                        />
                                    </div>

                                })}
                            </div>
                        </Tooltip>

                    case 'Dropdown':
                        return <Box key={`dropdown${index}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <Select
                                    sx={{ boxShadow: 'none', fontSize: '12px', color: 'var(--table-font-color)', '.MuiOutlinedInput-notchedOutline': { border: 'none' }, border: item?.is_required && !item?.selected_value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', '& .MuiSelect-select': { paddingLeft: '4px' } }}
                                    MenuProps={{
                                        disableScrollLock: true,
                                        classes: { paper: 'dropdown-container-pdf2' }
                                    }}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width === 110 ? '125px' : item?.width + 'px', height: item.height + 'px', fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    defaultValue="choose"
                                    onChange={(e) => [onChangeOptionHandler(e.target.value, index, null, 'Dropdown')]}
                                >
                                    <MenuItem className="select-sign-body" value="choose"><span>--Select--</span></MenuItem>
                                    {item?.options?.map((list, idx) => {
                                        return (
                                            <MenuItem key={idx} value={list.value} className="select-sign-body">
                                                <span>{list.value}</span>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Tooltip>
                        </Box>

                    case 'Url':
                        return <div key={`url${index}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <input className={item?.is_required ? 'pdf-sign-field-required' : "pdf-sign-field"} value={item.value} type="text"
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', background: item?.is_required ? '#FAEEE6' : item.background, border: item?.is_required ? '1px solid #ED4C78' : '', fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    onChange={(event) => onChangeHandler(event.target.value, index, 'value')} placeholder={item?.placeholder} />
                            </Tooltip>
                        </div>

                    case 'Date':
                        return <Box key={`date${index}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <input className="pdf-sign-field" value={item.value} type="text" onClick={() => setDatePicker(index)}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', backgroundColor: '#fff', border: item?.is_required && !item?.value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', fontSize: item?.fontSize + 'px', zIndex: 1, fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    onChange={() => { }} placeholder={item?.placeholder} />
                            </Tooltip>
                            <LocalizationProvider dateAdapter={AdapterDayjs} key={index + 1}>
                                <Box sx={{ position: 'absolute', top: item.top, left: item.left }}>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            '& div': {
                                                position: 'absolute', padding: 0
                                            },
                                        }}
                                    >
                                        <DatePicker
                                            viewRenderers={null}
                                            sx={{
                                                '& .css-1anqmj6-MuiPopper-root-MuiPickersPopper-root': {
                                                    scale: '0.7',
                                                },
                                                '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root': {
                                                    opacity: 0,
                                                    pointerEvents: 'none',
                                                },
                                                '& .css-nk89i7-MuiPickersCalendarHeader-root': {
                                                    background: 'red',
                                                },
                                                '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    padding: 0,
                                                    border: 'none'
                                                },
                                                '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root': {
                                                    display: 'none',
                                                    border: 'none'
                                                },
                                                '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                                    borderWidth: '0px'
                                                },
                                                '& .css-3gxj82.Mui-error .MuiOutlinedInput-notchedOutline': {
                                                    border: 'none'
                                                },
                                                '& .css-vubbuv': {
                                                    display: 'none'
                                                },
                                                '& .css-igs3ac': {
                                                    borderWidth: '0px'
                                                },
                                                fontFamily: 'var(--primary-font-family)',
                                                color: 'var(--table-font-color)',
                                                zIndex: 1,
                                                padding: 0,
                                            }}
                                            closeOnSelect={true}
                                            open={datePicker === index}
                                            value={dayjs(item.value, item?.date_format)}
                                            onClose={() => setDatePicker(false)}
                                            minDate={dayjs(new Date())}
                                            format='DD-MM-YYYY'
                                            onChange={(date) => {
                                                handleDatePicker(date.$d, index, item?.date_format)
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </LocalizationProvider>
                        </Box>

                    case 'Email':
                        return <div key={`email${index}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <input className='pdf-sign-field' value={item.value} type="text"
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', backgroundColor: '#fff', border: item?.is_required && !item?.value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    onChange={(event) => onChangeHandler(event.target.value, index, 'value')} placeholder={item?.placeholder} />
                            </Tooltip>
                        </div>

                    case 'Signature':
                        return <div key={`signature${index}`} aria-hidden="true">
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <Box className="pdf-sign-field"
                                    style={{
                                        position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', backgroundColor: '#fff', border: !(Object.keys(getImage)?.length !== 0 || getSignText) && item?.is_required ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', fontSize: item?.fontSize + 'px', cursor: 'pointer', alignItems: 'center', display: 'flex', whiteSpace: 'nowrap',
                                    }} onClick={() => [setSignature(true)]}>
                                    {/* {getSignType === 'type' ?
                                        (getSignText ? <div id='sign' style={{ fontFamily: getFont, fontSize: '20px', color: getColor, width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{getSignText}</div>
                                            :
                                            <div id='sign' style={{ fontFamily: getFont, fontSize: '12px', color: getColor, width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', paddingLeft: '2px' }}>{item.placeholder}</div>)
                                        : */}
                                    {Object.keys(getImage)?.length !== 0 ?
                                        <img
                                            width='100%'
                                            height='100%'
                                            style={{ objectFit: 'fill', position: 'relative', display: 'flex', justifyContent: 'center' }}
                                            draggable={false}
                                            src={getImage}
                                            alt='signature'
                                        />
                                        : <div style={{ color: 'var(--primary)', paddingLeft: '2px' }}>{item.placeholder}</div>
                                    }
                                </Box>
                            </Tooltip>
                            {signature && <PDFSignature
                                showModal={signature}
                                signatureOptions={signatureOptions}
                                signatureModal={(val) => setSignature(val)}
                                getType={getSignType}
                                imgType={(val) => imgType(val)}
                                type={(type) => getText(type, 'type')}
                                getImage={(value) => formData(value)}
                                getText={(e) => getText(e, 'text')}
                                getColor={(color) => getText(color, 'color')}
                                getFont={(font) => getText(font, 'font')}
                            />}
                        </div>

                    case 'Company':
                        return <div key={`company${index + 1}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <input className="pdf-sign-field" value={item.value} type="text" placeholder={item?.placeholder}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', backgroundColor: '#fff', border: item?.is_required && !item?.value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    onChange={(event) => onChangeHandler(event.target.value, index, 'value')} />
                            </Tooltip>
                        </div>

                    case 'Number':
                        return <div key={`number${index}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <input className="pdf-sign-field" value={item.value} type="number" pattern="[0-9]*" onKeyDown={(evt) => exceptSymbols.includes(evt.key) && evt.preventDefault()} placeholder={item?.placeholder}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', backgroundColor: '#fff', border: item?.is_required && !item?.value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    onChange={(event) => onChangeHandler(event.target.value, index, 'value')} />
                            </Tooltip>
                        </div>

                    case 'Title':
                        return <div key={`Title${index}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <input className="pdf-sign-field" value={item.value} type="text" placeholder={item?.placeholder}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', backgroundColor: '#fff', border: item?.is_required && !item?.value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    onChange={(event) => onChangeHandler(event.target.value, index, 'value')} />
                            </Tooltip>
                        </div>

                    case 'Initial':
                        return <div key={`Initial${index}`}>
                            <Tooltip title={item?.is_required ? `* Required - ${item?.field_key}` : `Optional - ${item?.field_key}`} placement="top-start" componentsProps={{
                                tooltip: {
                                    sx: { color: 'var(--header-color)', backgroundColor: '#FFFAA1', border: '1px solid #999' }
                                }
                            }}
                                PopperProps={{
                                    modifiers: [{ name: "offset", options: { offset: [0, -10] } }]
                                }}>
                                <input className="pdf-sign-field" value={item.value} type="text" placeholder={item?.placeholder}
                                    style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', backgroundColor: '#fff', border: item?.is_required && !item?.value ? '1px solid var(--field-border-danger)' : '1px solid var(--field-border-color)', fontSize: item?.fontSize + 'px', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                    onChange={(event) => onChangeHandler(event.target.value, index, 'value')} />
                            </Tooltip>
                        </div>

                    default:
                        return <div key={`name${index}`}>
                            <input className={item.value ? "pdf_board_field" : "pdf_board_field_empty"} value={item.value} type="text" placeholder={item?.placeholder}
                                style={{ position: 'absolute', top: item.top, left: item.left, width: item.width + 'px', height: item.height + 'px', border: '1px solid var(--field-border-color) !important', fontSize: item?.fontSize + 'px', backgroundColor: '#fff', fontWeight: item?.fontWeight, fontStyle: item?.fontStyle, fontFamily: item?.fontFamily, color: item?.color?.value }}
                                onChange={(event) => { }} />
                        </div>
                }
            })}
        </>
    )
};

export default PDFSignField;