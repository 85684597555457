import React, { useState } from 'react'
import styles from './CentilioDocs.module.css'
import { Box } from '@mui/material'



const CentilioDocs = () => {

    const [docs_image, setDocsImage] = useState([
        {
            img_path: 'CentilioDocSvg/second.png', img_view: 'Item View', img_items: 'CentilioDoc Items', img_text: 'Create, track and send documents for eSignature in real-time - directly within your interface.'
        },
        {
            img_path: 'CentilioDocSvg/secondBlack.png', img_view: 'Item View', img_items: 'CentilioDoc Items', img_text: 'Create, track and send documents for eSignature in real-time - directly within your interface.'
        }
    ])




    return (
        <Box className={styles.main_centilio_docs} >
            <Box className={styles.docs_text_main}>
                <Box>
                    Easily build your ideal workflow with
                </Box>
                <Box>
                    Centilio  building blocks.
                </Box>
            </Box>
            <Box >
                {docs_image?.map((item, idx) => {
                    return (
                        <Box className={styles.docs_img_container} key={idx}>
                            <Box>
                                <img src={process.env.REACT_APP_IMAGE_URL + 'sample.png'} alt='docs' className={styles.docs_image} />

                            </Box>
                            <Box className={styles.docs_text_img}>
                                <Box className={styles.docs_svg_container}>
                                    <Box>
                                        <img
                                            src={process.env.REACT_APP_IMAGE_URL + item.img_path}
                                            className={styles.docs_svg}
                                            alt='svg'

                                        />
                                    </Box>
                                    <Box className={styles.docs_text}>
                                        {item.img_view}
                                    </Box>
                                </Box>
                                <Box className={styles.docs_text_title}>
                                    {item.img_items}
                                </Box>
                                <Box className={styles.docs_large_img_text}>
                                    {item.img_text}
                                </Box>
                            </Box>
                        </Box>

                    )
                })}

            </Box>
        </Box>
    )
}

export default CentilioDocs