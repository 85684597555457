import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: null,
};

export const paraphraseDetails = createSlice({
  name: "paraphrase_details",
  initialState,
  reducers: {
    paraphraseDetail: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { paraphraseDetail } = paraphraseDetails.actions;

export default paraphraseDetails.reducer;


