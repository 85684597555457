import { Box, Button, IconButton, MenuItem, Modal, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './topBar.module.css';
import SearchIcon from '@mui/icons-material/Search';
import CustomSelect from '../CustomSelect/CustomSelect';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import SwitchBar from '../switchBar/SwitchBar';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { welcome_img_action } from '../../store/welcomeImage/welcomeImgAction';
import { useDispatch } from 'react-redux';
import { upload_action } from '../../store/uploadDocument/uploadAction';
import { useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from '../../utils/handleNavigation';
import CloseIcon from '@mui/icons-material/Close';
import { persistStore } from 'redux-persist';
import store from '../../store/store';
import moment from 'moment/moment';
import { getProperties } from '../../Api/UserGetApi';

const initial_account = ['My Account', 'Switch Organization', 'Subscription', 'Logout'];
const crm_account = ['Go to Centilio Sign', 'Logout'];

const TopBar = ({ setIsNavExpand, isFetched, expand }) => {
    const [isActive, setIsActive] = useState(false);
    const [account, setAccount] = useState(initial_account);
    const [switch_org, setSwitch_org] = useState(false);
    const [select_org, setSelect_org] = useState('');

    const document = useSelector((state) => state?.document?.value);
    const show_integeration = useSelector((state) => state.welcomeImgDetail.value);
    const user_role = show_integeration?.user_information?.userRole.includes('Super Admin') || show_integeration?.user_information?.userRole.includes('Admin');
    const show_features = show_integeration?.show_features?.length ? JSON.parse(show_integeration?.show_features)[0]?.features : [];

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const navPath = Navigation();

    const listTopNav = ['create-templates', 'sdkhome', 'sdk'];
    const hideTopNav = location.pathname?.split('/')?.find((v) => listTopNav.indexOf(v) !== -1);

    const first_name = document?.FirstName;
    const role = document?.RoleName;

    useEffect(() => {
        if (show_integeration?.other_organizations?.length > 1) {
            setSelect_org(show_integeration?.other_organizations[0]?.OrgName);
        };
    }, [show_integeration]);

    useEffect(() => {
        let menu_bar = show_integeration?.properties_info && JSON.parse(show_integeration?.properties_info)[0]?.MENU_BAR;
        if (expand === null) {
            setIsActive(menu_bar && JSON.parse(menu_bar));
        } else {
            setIsActive(expand);
        }
    }, [expand, show_integeration?.properties_info]);

    const handleLogout = (option) => {
        switch (option) {
            case 'My Account':
                window.open(process.env.REACT_APP_ACCOUNT, '_blank');
                break;
            case 'Go to Centilio Sign':
                window.open(process.env.REACT_APP_URL, '_blank');
                break;
            case 'Subscription':
                localStorage.removeItem("promo_code");
                window.open(`${process.env.REACT_APP_URL}/#/c${document?.OrgId}/price-list`, '_blank');
                break;
            case 'Switch Organization':
                setSwitch_org(true);
                break;
            default:
                const persistor = persistStore(store);
                axios.delete(process.env.REACT_APP_LOGOUT, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                    .then((res) => {
                        persistor.purge();
                        dispatch(welcome_img_action(null));
                        dispatch(upload_action(null));
                        if (listTopNav.indexOf(hideTopNav) !== -1) {
                            navigate('/sdkhome', { state: { set_login: true } });
                        } else {
                            window.open(process.env.REACT_APP_LOGIN, '_self');
                        }
                    })
                    .catch((err) => {
                        toast.error(err.message);
                    });
                break;
        }
    };

    useEffect(() => {
        if (user_role === false) {
            // if (!show_features?.PAYMENTS && show_features?.hasOwnProperty('PAYMENTS')) {
            let filteredArray = account?.filter(item => item !== 'Subscription');
            setAccount(filteredArray);
            // };
        }

        if (show_integeration?.other_organizations?.length <= 1) {
            let filteredArray = account?.filter(item => item !== 'Switch Organization');
            setAccount(filteredArray);
        };

    }, [show_integeration]);

    const handleOrgUser = (e) => {
        setSelect_org(e.target.value);
    };

    const handleSwitchOrg = () => {
        setSwitch_org(false);
        let data = show_integeration?.other_organizations?.find(s => s.OrgName === select_org);
        if (data) {
            const persistor = persistStore(store);
            persistor.purge();
            navigate(`${data?.OrgUrlName}/home`, { state: { switch_org: true } });
            window.location.reload(true);
        }
    };

    const handleProperties = () => {
        setIsNavExpand(!isActive);
        let param = { org_id: document?.OrgId, key_name: 'MENU_BAR', value: !isActive }
        getProperties(param, (res) => {
        }, (e) => {
            toast.error(e.message);
        })
    };

    const handleDayLeft = (date) => {
        let getdate = moment(new Date(date));
        const targetDate = new Date(getdate);
        return moment(targetDate).diff(moment(), 'days') === 0 ? 'Expires Today' : `${moment(targetDate).diff(moment(), 'days')} Days Left`;
    };

    const handlePayment = () => {
        if (show_features?.PAYMENTS && show_features?.hasOwnProperty('PAYMENTS')) {
            localStorage.removeItem("promo_code");
            window.open(`${process.env.REACT_APP_URL}/#/c${document?.OrgId}/price-list`, '_blank');
        }
    };

    let options = listTopNav.indexOf(hideTopNav) !== -1 ? crm_account : account;
    return (
        <Box className={listTopNav.indexOf(hideTopNav) !== -1 ? styles.crm_top_wrap : styles.top_wrap}>
            {isFetched &&
                <Box style={{ alignItems: 'center', display: 'contents' }}>
                    {(listTopNav.indexOf(hideTopNav) === 0 || listTopNav.indexOf(hideTopNav) === -1) && <Box
                        style={{ cursor: 'pointer' }}
                        onClick={handleProperties}
                    >
                        {isActive ? (
                            <div className={styles.expand} onClick={() => { setIsActive(!isActive) }}>
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/expand.png'}
                                    className={styles.expand_icon}
                                    alt=''
                                />
                            </div>
                        ) : (
                            <div className={styles.expand_close} onClick={() => { setIsActive(!isActive) }}>
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/expand-close.png'}
                                    className={styles.expand_close_icon}
                                    alt=''
                                />
                            </div>)}
                    </Box>
                    }
                    {/* <Box className={styles.search_wrap}>
                    <SearchIcon />
                    <input placeholder='Global search' />
                </Box>
            </Box>
            <Box
                style={{
                    position: 'relative',
                    display: 'flex',
                    gap: '5px',
                }}
            >
                <img
                    src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Star.svg'}
                    alt=''
                    style={{
                        position: 'absolute',
                        left: '2px',
                        top: '-5px',
                    }}
                />
                <img
                    src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Stars.svg'}
                    style={{ position: 'absolute', left: '-7px', top: '0px' }}
                    alt=''
                />

                <Box style={{ color: '#4B3CABFF', fontSize: '12px', marginLeft: '10px' }}>
                    Get Centilio Professional
                </Box>
            </Box>
            <Box style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                <Box>
                    <SwitchBar />
                </Box>
                <Box>
                    <Typography style={{ fontSize: '11px' }}>30 Days Left</Typography>
                </Box>
                <Box>
                    <img
                        src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Activity.svg'}
                        alt=''
                    />
                </Box> */}
                    {/* && show_features?.PAYMENTS */}
                    {/* (JSON.parse(show_integeration?.plan_details)?.plan_name === 'Professional-Trail' || JSON.parse(show_integeration?.plan_details)?.plan_name === 'Enterprise-Trail' */}
                    {user_role && <Box><Box className={styles.get_centilio} onClick={handlePayment}>
                        {/* <img
                            src={process.env.REACT_APP_IMAGE_URL + 'get-centilio.svg'}
                            alt='star-img'
                            className={styles.star_image}
                        />
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + 'get-centilio.svg'}
                            alt='small-star'
                            className={styles.star_small_image}
                        /> */}
                        Get Centilio Pro</Box>
                        <Box className={styles.days_left}>{show_integeration?.plan_details?.length && JSON.parse(show_integeration?.plan_details)?.plan_name !== 'Free' ? handleDayLeft(JSON.parse(show_integeration?.plan_details)?.expiry) : null}</Box>
                    </Box>}
                    <Box className={styles.left_container}>
                        <Box className={styles.plan_wrapper}>
                            <Box className={styles.plan_name}>{show_integeration?.plan_details?.length ? JSON.parse(show_integeration?.plan_details)?.plan_name : null}</Box>
                        </Box>
                        <CustomSelect element={show_integeration?.user_information?.Profile_Id ? <img src={`${process.env.REACT_APP_PROFILE}?file_id=${show_integeration?.user_information?.Profile_Id}&org_id=${document?.OrgId}`} className={styles.profile_image} alt='Profile_img' /> :
                            <div className={styles.profile_name}>
                                <div className={styles.profile_text}>{first_name?.charAt(0)?.toUpperCase()}</div>
                            </div>
                        }>
                            <Box style={{ width: '220px' }}>
                                <Box
                                    style={{
                                        padding: '16px 30px',
                                        borderBottom: '1px solid var(--border-color)',
                                    }}
                                >
                                    <Box className={styles.first_name}>
                                        {first_name}
                                    </Box>
                                    <Box
                                        style={{
                                            color: 'var(--table-font-color)',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        {role}
                                    </Box>
                                </Box>
                                <Box className={styles.dropdown_text}>
                                    {options?.map((option) => {
                                        return (
                                            <MenuItem
                                                sx={{
                                                    "&:hover": {
                                                        backgroundColor: "0 0.1875rem 0.75rem #8c98a440",
                                                        borderRadius: '6px'
                                                    }
                                                }}
                                                className={styles.account_text}
                                                key={option}
                                                onClick={() => handleLogout(option)}>
                                                {option}
                                            </MenuItem>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </CustomSelect>
                    </Box>
                </Box>}
            <Modal
                open={switch_org}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                closeAfterTransition
            >
                <Box className={styles.org_wrap}>
                    <IconButton
                        className={styles.org_close}
                        onClick={() => setSwitch_org(false)}
                    >
                        <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                    </IconButton>
                    <Box className={styles.org_container}>
                        <Box className={styles.org_textfield}>
                            Switch Organization
                        </Box>
                        <Select
                            MenuProps={{ classes: { paper: styles.select_pagination } }}
                            sx={{
                                "& .MuiOutlinedInput-notchedOutline": { border: 'none !important' },
                                mt: 5,
                                minWidth: 80,
                                width: '100%',
                                height: '38px',
                                borderBottom: '2px solid var(--border-color) !important',
                                fontFamily: 'var(--primary-font-family)',
                            }}
                            value={select_org ? select_org : show_integeration?.other_organizations[0]?.OrgName}
                            onChange={handleOrgUser}
                        >
                            {show_integeration?.other_organizations?.length && show_integeration?.other_organizations?.map((item, i) => (
                                <MenuItem className={styles.pagination_item} key={i} value={item?.OrgName}>
                                    {item?.OrgName}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button variant='contained' className={styles.switch_btn} onClick={() => handleSwitchOrg()}>Switch</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default TopBar;
