import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const recipientDetail = createSlice({
    name: 'recipientDetails',
    initialState,
    reducers: {
        recipientDetails: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { recipientDetails } = recipientDetail.actions;

export default recipientDetail.reducer;