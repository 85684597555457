import { Box } from '@mui/material'
import React from 'react'
import List from './List'
import styles from "./UsersInfo.module.css";
import AdminCustomTable from '../../component/adminCustomTable/AdminCustomTable';
import { useSelector } from 'react-redux';

const UsersInfo = ({ orgInfo, orgUsersInfo, planDetails, userInfo }) => {
  const tableInfo = useSelector((state) => state?.adminAccountDetails);
  const tableHead = tableInfo.tabledata.tables[2].OrgUsers
  const Role_Name = ['Role_Name']
  const tableaHeads = tableHead.concat(Role_Name);
 
  return (
    <Box>
    {orgInfo && <Box className={styles.table_value} >
      <List Name={'User Info'} details={userInfo} />
      <List Name={'Org Info'} details={orgInfo} />
      <List Name={'Plan Info'} details={planDetails} />
     <Box sx={{marginBottom:'1rem'}}>
      <AdminCustomTable
        searchName='User Details'
        table='user'
        keys={tableaHeads}
        head={tableaHeads}
        body={orgUsersInfo}
      // getCheckedId={setCheckedId}
      />
      </Box>
    </Box>}
    </Box>
  )
}

export default UsersInfo