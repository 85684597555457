import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const templatesPreviews = createSlice({
    name: 'templatePreview',
    initialState,
    reducers: {
        templatePreview: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { templatePreview } = templatesPreviews.actions;

export default templatesPreviews.reducer;