import axios from "axios";

export const getFileParaphrase = (data, resp, error) => {
  axios.post(process.env.REACT_APP_PARAPHRASE, data, { headers: { "Content-Type": "multipart/form-data" }, withCredentials: process.env.REACT_APP_CREDENTIALS })
    .then((res) => {
      resp(res);
    })
    .catch((err) => {
      error(err);
    });
};

export const getRephrase = (data, resp, error) => {
  axios.post(process.env.REACT_APP_REPHRASE, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
    .then((res) => {
      resp(res);
    })
    .catch((err) => {
      error(err);
    });
};

export const getPdfFile = (data, resp, error) => {
  axios.post(process.env.REACT_APP_HTML, data, { headers: { "Content-Type": "multipart/form-data" }, withCredentials: process.env.REACT_APP_CREDENTIALS })
    .then((res) => {
      resp(res);
    })
    .catch((err) => {
      error(err);
    });
};
