const bgcolor = ["#377dff1a", "#00c9a71a", "#f5ca991a", "#FFE0D1", "#ed4c781a", "#1321441a", "#F7E2FF", "#FFFDCE", "#CEC1D7", "#D7D7C1", "#ffbcc8cf", "#E0FFF7", "#B8E986", "#DAE4F1", "#97D6D9"];
const circle_active_color = ["#377DFF", "#00C9A7", "#F5CA99", "#FFC931", "#ED4C78", "#71869D", "#B867DA", "#E8D20D", "#775A8C", "#8A8A5C", "#F86982", "#2EE0AF", "#76B13A", "#3F6CA6", "#0C797D"];

const Name = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={13}
        height={13}
        viewBox="0 0 13 13"
        fill="none"
        {...props}
    >
        <path
            fillRule="evenodd"
            d="M6.017 6.041a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1h-10s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.003c-.001-.247-.153-.986-.832-1.665-.652-.652-1.879-1.332-4.168-1.332s-3.515.68-4.168 1.332c-.678.679-.83 1.418-.832 1.665h10Z"
            fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}
        />
    </svg>
);

const Company = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g
            fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}>
            <path fillRule="evenodd" d="M15.025.076a.5.5 0 0 1 .237.425v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1.5h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-5.5a.5.5 0 0 1 .342-.474L6.262 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6.262 8.695l-5 1.666v4.64h5V8.695Zm1 6.306h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1.5h2V1.311l-7 3.5V15Z" />
            <path fillRule="evenodd" d="M2.262 11.001h1v1h-1v-1Zm2 0h1v1h-1v-1Zm-2 2h1v1h-1v-1Zm2 0h1v1h-1v-1Zm4-4h1v1h-1v-1Zm2 0h1v1h-1v-1Zm-2 2h1v1h-1v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1v-1Zm0 2h1v1h-1v-1Zm-4-4h1v1h-1v-1Zm2 0h1v1h-1v-1Zm2 0h1v1h-1v-1Zm-4-2h1v1h-1v-1Zm2 0h1v1h-1v-1Zm2 0h1v1h-1v-1Zm0-2h1v1h-1v-1Z" />
        </g>
    </svg>
);

const Pen = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            d="m14.037.876.148-.148a1.207 1.207 0 1 1 1.707 1.707l-.148.148a1.5 1.5 0 0 1-.059 2.06L5.392 14.934a.5.5 0 0 1-.232.132l-4 1a.5.5 0 0 1-.606-.607l1-4a.5.5 0 0 1 .131-.232l9.642-9.641a.5.5 0 0 0-.642.055L7.392 4.935a.5.5 0 0 1-.707-.707L9.978.935a1.5 1.5 0 0 1 2.06-.057 1.5 1.5 0 0 1 2-.002Zm-.645.766a.5.5 0 0 0-.707 0L2.49 11.837l-.764 3.058 3.057-.765L14.978 3.935a.5.5 0 0 0 0-.707l-1.586-1.586Z"
            fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}
        />
    </svg>
);

const Date = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}>
            <path fillRule="evenodd" d="M11.725 7.43a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Z" />
            <path fillRule="evenodd" d="M4.225.93a.5.5 0 0 1 .5.5v.5h8v-.5a.5.5 0 0 1 1 0v.5h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2h1v-.5a.5.5 0 0 1 .5-.5Zm-2.5 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-10h-14Z" />
        </g>
    </svg>
);

const RadioIcon = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}>
            <path fillRule="evenodd" d="M8.276 15.93a7 7 0 1 1 0-14 7 7 0 0 1 0 14Zm0 1a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
            <path fillRule="evenodd" d="M11.276 8.93a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />

        </g>
    </svg>
);

const Initial = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}>
            <path fillRule="evenodd" d="M8.408 5.978c-1.213 0-1.928.92-1.928 2.502v1.06c0 1.57.703 2.461 1.928 2.461.978 0 1.64-.586 1.728-1.418h1.295v.094c-.1 1.447-1.353 2.467-3.029 2.467-2.092 0-3.27-1.336-3.27-3.604V8.468c0-2.262 1.202-3.639 3.27-3.639 1.682 0 2.936 1.055 3.03 2.572v.088h-1.296c-.087-.879-.767-1.511-1.728-1.511Z" />
            <path fillRule="evenodd" d="M.261 2.985a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2v-12Zm15 0a1 1 0 0 0-1-1h-12a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-12Z" />
        </g>
    </svg>
);

const Fonts = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            d="M12.535 3.018h-8.51l-.083 2.46h.479c.26-1.544.758-1.784 2.693-1.845l.424-.014v7.827c0 .663-.144.82-1.299.923v.52h4.081v-.52c-1.162-.102-1.306-.26-1.306-.923V3.62l.431.014c1.935.061 2.434.3 2.694 1.846h.478l-.082-2.461Z"
            fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}
        />
    </svg>
);

const CheckboxIcon = (props) => (
    <svg
        width={15}
        height={14}
        viewBox="0 0 15 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}>
            <path fillRule="evenodd" d="M1.761 13.485a1.5 1.5 0 0 1-1.5-1.5v-10a1.5 1.5 0 0 1 1.5-1.5h8a.5.5 0 1 1 0 1h-8a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5h-10Z" />
            <path fillRule="evenodd" d="m7.115 9.339 7-7a.5.5 0 0 0-.707-.707L6.76 8.278 4.115 5.632a.5.5 0 0 0-.707.707l3 3a.5.5 0 0 0 .707 0Z" />
        </g>
    </svg>
);

const Title = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="briefcase">
            <path
                id="Union"
                fillRule="evenodd"
                d="M6.76141 1.98546C5.93315 1.98546 5.26141 2.65663 5.26141 3.48521V3.98546H1.76141C0.932987 3.98546 0.261414 4.65703 0.261414 5.48546V13.4855C0.261414 14.3139 0.932987 14.9855 1.76141 14.9855H14.7614C15.5898 14.9855 16.2614 14.3139 16.2614 13.4855V5.48546C16.2614 4.65703 15.5898 3.98546 14.7614 3.98546H11.2614V3.48521C11.2614 2.65663 10.5897 1.98546 9.76141 1.98546H6.76141ZM6.76141 2.98546H9.76141C10.0377 2.98546 10.2614 3.21009 10.2614 3.48607V3.98546H6.26141V3.48521C6.26141 3.20923 6.48511 2.98546 6.76141 2.98546ZM8.64791 9.89987L15.2614 8.13626V13.4855C15.2614 13.7616 15.0376 13.9855 14.7614 13.9855H1.76141C1.48527 13.9855 1.26141 13.7616 1.26141 13.4855V8.13626L7.87492 9.89987C8.12816 9.9674 8.39467 9.9674 8.64791 9.89987ZM1.76141 4.98546H14.7614C15.0376 4.98546 15.2614 5.20932 15.2614 5.48546V7.10132L8.39025 8.93363C8.30583 8.95614 8.21699 8.95614 8.13258 8.93363L1.26141 7.10132V5.48546C1.26141 5.20932 1.48527 4.98546 1.76141 4.98546Z"
                fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}
            />
        </g>
    </svg>
);

const Email = (props) => (
    <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            d="M11.854 5.452c0-2.967-2.249-5.031-5.482-5.031C3.022.42.725 2.738.725 6.12c0 3.494 2.236 5.709 5.763 5.709.861 0 1.688-.123 2.304-.335v-.861c-.431.198-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.799 0-2.844 1.921-4.88 4.594-4.88 2.734 0 4.607 1.688 4.607 4.155 0 1.682-.554 2.769-1.415 2.769-.492 0-.772-.28-.772-.759V3.435H7.67v.834h-.11c-.266-.594-.882-.963-1.6-.963-1.4 0-2.378 1.162-2.378 2.823 0 1.736.957 2.905 2.379 2.905.8 0 1.415-.39 1.708-1.087h.11c.082.67.704 1.149 1.504 1.149 1.572 0 2.57-1.415 2.57-3.644Zm-7.178.704c0-1.196.54-1.907 1.456-1.907.93 0 1.525.738 1.525 1.907 0 1.17-.602 1.914-1.538 1.914-.896 0-1.443-.724-1.443-1.914Z"
            fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}
        />
    </svg>
);

const Dropdown = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2614 2.98546C15.2614 2.43317 14.8137 1.98546 14.2614 1.98546H2.26141C1.70913 1.98546 1.26141 2.43317 1.26141 2.98546V14.9855C1.26141 15.5377 1.70913 15.9855 2.26141 15.9855H14.2614C14.8137 15.9855 15.2614 15.5377 15.2614 14.9855V2.98546ZM0.261414 2.98546C0.261414 1.88089 1.15684 0.985458 2.26141 0.985458H14.2614C15.366 0.985458 16.2614 1.88089 16.2614 2.98546V14.9855C16.2614 16.09 15.366 16.9855 14.2614 16.9855H2.26141C1.15684 16.9855 0.261414 16.09 0.261414 14.9855V2.98546ZM8.76141 5.48546C8.76141 5.20932 8.53756 4.98546 8.26141 4.98546C7.98527 4.98546 7.76141 5.20932 7.76141 5.48546V11.2784L5.61497 9.1319C5.41971 8.93664 5.10312 8.93664 4.90786 9.1319C4.7126 9.32717 4.7126 9.64375 4.90786 9.83901L7.90786 12.839C8.10312 13.0343 8.41971 13.0343 8.61497 12.839L11.615 9.83901C11.8102 9.64375 11.8102 9.32717 11.615 9.1319C11.4197 8.93664 11.1031 8.93664 10.9079 9.1319L8.76141 11.2784V5.48546Z"
            fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}
        />
    </svg>
);

const NumberIcon = (props) => (
    <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            id="Union"
            fillRule="evenodd"
            clipRule="evenodd"
            d="m5.41 5 .597-3.582.986.164L6.423 5H9.41l.597-3.582.986.164L10.423 5H13v1h-2.743l-.5 3H13v1H9.59l-.597 3.582-.986-.164.57-3.418H5.59l-.597 3.582-.986-.164.57-3.418H2V9h2.743l.5-3H2V5h3.41Zm.847 1-.5 3h2.986l.5-3H6.257Z"
            fill={props?.color ? circle_active_color[bgcolor.indexOf(props?.color)] : 'var(--primary)'}
        />
    </svg>
);

const BasicFieldIcons = { Name, Company, Pen, Date, RadioIcon, Initial, Fonts, CheckboxIcon, Title, Email, Dropdown, NumberIcon };

export default BasicFieldIcons;
