import React, { useState } from 'react'
import { Box, Button, FormControl, InputAdornment, MenuItem, TextField } from '@mui/material'
import styles from './PdfDownloadView.module.css'
import RestorePageIcon from '@mui/icons-material/RestorePage';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
function RotatePdfPage({ downloadPage, rotate }) {
    const [rotatePage, setRotatePage] = useState(1)
    const [errors, setErrors] = useState('');

    const handleRotatePage = () => {
        downloadPage(true)
        setErrors('')
        rotate(rotatePage)

    }


    return (
        <Box className={styles.pdf_right_site}>
            <Box className={styles.pdf_right_done}>
                <Box>  <img style={{ width: "30px" }}
                    src={process.env.REACT_APP_IMAGE_URL + 'pdfConvertericon/rotateicon.svg'}
                    className={styles.box_img}
                    alt='menu-item'
                /></Box>
                <Box className={styles.pdf_right_text}>Rotate Pdf </Box>
            </Box>
            <Box className={styles.pdf_download_area}>

                <FormControl fullWidth sx={{ m: 1 }}>
                    <TextField
                        error={errors}
                        id="outlined-error-helper-text"
                        label="Rotate Page"
                        value={rotatePage}
                        select
                        helperText={errors}
                        onChange={(e) => setRotatePage(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <RestorePageIcon />
                                </InputAdornment>
                            )
                        }}
                    >

                        <MenuItem value={1}>
                            LEFT
                        </MenuItem>
                        <MenuItem value={2}>
                            RIGHT
                        </MenuItem>
                        <MenuItem value={3}>
                            LANDSCAPE
                        </MenuItem>
                        <MenuItem value={4}>
                            UPSIDEDOWN
                        </MenuItem>
                    </TextField>
                </FormControl>
            </Box>
            <Box><Button variant="contained" endIcon={<ArrowForwardIosIcon fontSize='small' />}
                onClick={() => handleRotatePage()}
                fullWidth> Rotate </Button> </Box>

        </Box>
    )
}

export default RotatePdfPage