import { Box, Button, FormControl, InputAdornment, InputLabel, LinearProgress, OutlinedInput, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './PdfDownloadView.module.css'
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import ReplayIcon from '@mui/icons-material/Replay';
import { toast } from 'react-toastify';
import { toggleLoader } from '../store/loader/loaderReducer';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ProtectPDF from './ProtectPDF';
import DeletePdfPage from './DeletePdfPage';
import RotatePdfPage from './RotatePdfPage';
import UnlockPdf from './UnlockPdf';
import { useSelector } from 'react-redux';
import PdfMerge from './PdfMerge';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    // boxShadow: 24,
    // p: 4,
};
const PdfDownloadView = ({ file, clearFile, type, filetype }) => {

    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [fileName, setFileName] = useState()
    const [updateName, setUpdateName] = useState()
    const [password, setPassword] = useState('')
    const [deletePage, setDeletePage] = useState('')
    const [rotatePage, setRotatePage] = useState('')
    const [mergePage, setMergePage] = useState(false)
    const [mergeDatas, setMergeDatas] = useState([])
    const [downloadPage, setDownloadPage] = useState(true);
    const [response, setResponse] = useState('');
    const Protectedpages = [17, 16, 15, 14];
    const [conversionType, setConversionType] = useState('')
    const loader = useSelector((state) => state?.loader);

    const Fileformates = [
        { name: 'jpg', conversion_type: 1 },
        { name: 'png', conversion_type: 1 },
        { name: 'doc', conversion_type: 2 },
        { name: 'csv', conversion_type: 3 },
        { name: 'ppt', conversion_type: 4 },
        { name: 'xls', conversion_type: 5 },
    ]


    useEffect(() => {
        setUpdateName(fileName)
    }, [fileName])

    useEffect(() => {
        if (type.conversion_type === 4 || 5)
            setConversionType(type.conversion_type)
    }, [type])

    useEffect(() => {
        if (file.conversion_type === 12) {
            setMergePage(true)
        }
    }, [file.conversion_type])

    useEffect(() => {
        const name = filetype.name
        const removedtype = name.split('.').slice(0, -1).join('.');
        setFileName(removedtype)
    }, [filetype])

    useEffect(() => {
        if (Protectedpages.indexOf(type.conversion_type) !== -1) {
            setDownloadPage(false)
        }
        else setDownloadPage(true)
    }, [])

    const handleFileFormatSelection = (selectedFormat) => {
        const selectedConversionType = Fileformates.find(format => format.name === selectedFormat)?.conversion_type;
        if (selectedConversionType) {

            setConversionType(selectedConversionType);
        }
    };

    useEffect(() => {
        const name = filetype.name
        const removedtype = name.split('.');
        if (file.conversion_type < 6) {
            handleFileFormatSelection(removedtype[removedtype.length - 1])
        }
        else if (file.conversion_type < 11) {
            setConversionType(file.conversion_type)
        }
    }, [])

    useEffect(() => {

        if (conversionType) {
            let form;
            form = {
                file: conversionType === 12 ? mergeDatas : file.filevalue,
                conversion_type: conversionType,
                password: password,
                pages_to_delete: deletePage,
                rotate_direction: rotatePage,
            };

            for (let key in form) {
                if (form[key] === '') {
                    delete form[key];
                }
            }
            const formData = new FormData();
            if (conversionType === 12) {
                form.file.forEach((file, index) => {
                    formData.append(`file_${index}`, file);
                });
                formData.append('conversion_type', form.conversion_type);

            }
            else {
                Object.keys(form).forEach((key) => {
                    formData.append(key, form[key]);
                });
            }

            dispatch(toggleLoader(true));
            let api;
            if (conversionType === 12) {

                api = process.env.REACT_APP_MERGE_UPLOAD
            }
            else api = process.env.REACT_APP_PDF_TOOL

            axios.post(api, formData, { headers: { "Content-Type": "multipart/form-data" }, responseType: 'blob', withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {

                    setResponse(res.data)
                    toast.success(res.message);
                    dispatch(toggleLoader(false));
                })
                .catch((err) => {
                    dispatch(toggleLoader(false));
                    toast.error(err.message);
                });
        }
    }, [conversionType])

    const handleDownload = () => {
        const href = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${fileName}.${type.formate}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);
    }

    const handleChange = () => {
        setFileName(updateName)
        handleClose()
    }
    const handlemergeData = (e) => {
        setMergePage(false)
        setMergeDatas(e)
        setConversionType(file.conversion_type)

    }

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            {mergePage ? <PdfMerge file={file} mergeData={handlemergeData} /> : <Box className={styles.pdf_main_content}>
                <Box className={styles.pdf_main_flex}>
                    <Box className={styles.pdf_left_site}>
                        <Box className={styles.pdf_left_full}>
                            <Box className={styles.pdf_left_img}>
                                <Box sx={{ width: '240px', height: '336px' }}>
                                    <Box className={styles.pdf_left_img_color}>
                                        <img
                                            src={process.env.REACT_APP_IMAGE_URL + 'pdfConvertericon/fileicon.svg'}
                                            className={styles.box_img}
                                            alt='menu-item'
                                        />
                                        <Box sx={{ textTransform: 'uppercase' }}>{type.formate}</Box>
                                    </Box>
                                    {loader.isLoading && <LinearProgress />}
                                    {!loader.isLoading ? <Box className={styles.pdf_left_file_name}>{fileName}.{type.formate}</Box> : <Box className={styles.pdf_left_file_name}>Converting...</Box>}
                                </Box>
                            </Box>
                        </Box> </Box>
                    {!downloadPage ? (
                        type.conversion_type === 16 ? (
                            <ProtectPDF downloadPage={(e) => setDownloadPage(e)} changePassword={(e) => { setPassword(e); setConversionType(file.conversion_type) }} />) :
                            type.conversion_type === 14 ?
                                (<DeletePdfPage downloadPage={(e) => setDownloadPage(e)} deletePages={(e) => { setDeletePage(e); setConversionType(file.conversion_type) }} />) :
                                type.conversion_type === 15 ?
                                    (<RotatePdfPage downloadPage={(e) => setDownloadPage(e)} rotate={(e) => { setRotatePage(e); setConversionType(file.conversion_type) }} />) :
                                    (<UnlockPdf downloadPage={(e) => setDownloadPage(e)} changePassword={(e) => { setPassword(e); setConversionType(file.conversion_type) }} />)) :
                        (<Box className={styles.pdf_right_site}>
                            <Box className={styles.pdf_right_done}>
                                <Box sx={{ paddingTop: '10px' }}>  <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'pdfConvertericon/righticon.svg'}
                                    className={styles.box_img}
                                    alt='menu-item'
                                /></Box>
                                <Box className={styles.pdf_right_text}>Done</Box>
                            </Box>
                            <Box className={styles.pdf_download_area}>
                                <Box className={styles.pdf_download_file_name}>
                                    {open ? (<Box className={styles.pdf_download_file}>
                                        <Box className={styles.pdf_modal_padding}>
                                            <FormControl variant="outlined" >
                                                <OutlinedInput
                                                    value={updateName}
                                                    id="outlined-adornment-weight"
                                                    endAdornment={<InputAdornment position="end">.{type.formate}</InputAdornment>}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    fullWidth
                                                    sx={{
                                                        "& fieldset": { border: 'none' },
                                                    }}
                                                    onChange={(e) => setUpdateName(e.target.value)}

                                                />
                                            </FormControl>
                                            <Box sx={{ paddingTop: '10px', display: 'flex', justifyContent: ' flex-end', gap: '20px' }}>
                                                {/* <Box ><Button variant="outlined" onClick={handleClose}>Cancel</Button></Box> */}
                                                <Box ><Button variant="contained" onClick={() => handleChange()}>Submit</Button></Box>

                                            </Box>
                                        </Box>
                                    </Box>)
                                        : (<Box sx={{ padding: '20px' }} className={styles.pdf_download_file}>
                                            <Box className={styles.pdf_download_file_first} onClick={handleOpen}>{fileName}</Box>
                                            <Box className={styles.pdf_download_file_secound}>.{type.formate}</Box>
                                        </Box>)}
                                </Box>
                            </Box>
                            <Box><Button variant="contained" startIcon={<DownloadIcon />} onClick={() => handleDownload()} fullWidth disabled={!loader.isLoading ? false : true}> Download  </Button> </Box>
                            <Box><Button className={styles.pdf_download_start_over} variant="outlined" startIcon={<ReplayIcon />} onClick={() => clearFile(null)} fullWidth>Start Over</Button>  </Box>
                        </Box>)}
                </Box>
            </Box >}
        </Box >
    )
}

export default PdfDownloadView