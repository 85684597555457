import React, { useEffect, useRef, useState } from 'react'
import PdfEdit from './PdfEdit'
import styles from './PdfConverter.module.css'
import { Box, Button, ClickAwayListener, Drawer } from '@mui/material'
import AppsIcon from '@mui/icons-material/Apps';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { elGR } from '@mui/x-date-pickers';

const PdfConverter = () => {
    const [toggle, setToggle] = useState(false)
    const [type, setType] = useState({ text: 'PDF to Word', color: '#93b4f2', DragColor: '#4e8afb', conversion_type: 7, formate: 'doc', })
    const ref = useRef(null);


    useEffect(() => {
        setToggle(false)
    }, [type])


    const ConvertToPDF = [
        { name: 'JPG to PDF', formate: 'pdf', icon: 'pdfConvertericon/jpgicon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 1 },
        { name: 'CSV to PDF', formate: 'pdf', icon: 'pdfConvertericon/csvicon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 3 },
        { name: 'Word to PDF', formate: 'pdf', icon: 'pdfConvertericon/wordIcon.svg', color: '#93b4f2', DragColor: '#4e8afb', conversion_type: 2 },
        { name: 'PPT to PDF', formate: 'pdf', icon: 'pdfConvertericon/ppticon.svg', color: '#e7a602', DragColor: '#ffb806', conversion_type: 4 },
        { name: 'Excel to PDF', formate: 'pdf', icon: 'pdfConvertericon/excelIcon.svg', color: '#558981', DragColor: '#207568', conversion_type: 5 },
    ]
    const ConvertFromPDF = [
        { name: 'PDF to CSV', formate: 'csv', icon: 'pdfConvertericon/csvicon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 6 },
        { name: 'PDF to Word', formate: 'doc', icon: 'pdfConvertericon/wordIcon.svg', color: '#93b4f2', DragColor: '#4e8afb', conversion_type: 7 },
        // { name: 'PDF to PPT', formate: 'pptx', icon: 'pdfConvertericon/ppticon.svg', color: '#e7a602', DragColor: '#ffb806', conversion_type: 8 },
        { name: 'PDF to TEXT', formate: 'txt', icon: 'pdfConvertericon/txticon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 9 },
        { name: 'PDF to Excel', formate: 'xlsx', icon: 'pdfConvertericon/excelIcon.svg', color: '#558981', DragColor: '#207568', conversion_type: 10 },
        { name: 'PDF to JPG', formate: 'zip', icon: 'pdfConvertericon/jpgicon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 11 },
    ]
    const Organize = [
        { name: 'Merge PDF', formate: 'pdf', icon: 'pdfConvertericon/lockicon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 12 },
        { name: 'Split PDF', formate: 'zip', icon: 'pdfConvertericon/jpgicon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 13 },
        { name: 'Delete Page', formate: 'pdf', icon: 'pdfConvertericon/csvicon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 14 },
        { name: 'Rotate Page', formate: 'pdf', icon: 'pdfConvertericon/csvicon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 15 },
    ]
    const SignAndSecurity = [
        { name: 'eSign PDF', formate: 'pdf', icon: 'pdfConvertericon/wordIcon.svg', color: '#93b4f2', DragColor: '#4e8afb', conversion_type: '' },
        { name: 'Protect PDF', formate: 'pdf', icon: 'pdfConvertericon/lockicon.svg', color: '#1ba215', DragColor: '#057100', conversion_type: 16 },
        { name: 'Unlock PDF', formate: 'pdf', icon: 'pdfConvertericon/unlockicon.svg', color: 'blue', DragColor: '#207568', conversion_type: 17 },
    ]


    const handlePdfTypes = (types) => {
        if (types.name !== "eSign PDF") setType({ ...type, text: types.name, formate: types.formate, color: types.color, DragColor: types.DragColor, conversion_type: types.conversion_type });
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setToggle(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [ref]);

    const handleToggle = (e) => {
        setToggle(pre => !pre)
    }

    const handleLogIn = () => {
        window.open(`${process.env.REACT_APP_LOGIN}#/login`, '_self');
    }
    const handleSignUp = () => {
        window.open(`${process.env.REACT_APP_SIGUP}#/signup`, '_self');
    }


    return (
        <Box style={{ height: "100%" }} >
            <Drawer
                anchor='top'
                open={toggle}
                onClose={() => setToggle(false)}
                sx={{
                    top: 55,
                    zIndex: 9,
                    '& .MuiDrawer-paper': {
                        top: 55,
                        boxShadow: 8
                    },
                    '& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
                        top: 55,
                        opacity: '0.7 !important'
                    }
                }}
            // hideBackdrop
            >

                <Box className={styles.top_bar_tools} sx={{}}>
                    <Box className={styles.top_all_tools}>
                        <Box className={styles.top_selected_tools}>
                            <Box className={styles.top_tools_grid}>
                                <Box className={styles.top_tools_colums}>
                                    <Box className={styles.tools_head_name}>
                                        Convert from PDF
                                    </Box>
                                    <Box className={styles.tools_head_tool}>
                                        <Box className={styles.tools}>
                                            <Box className={styles.tool_flex}>
                                                <Box className={styles.tool_center}>
                                                    {ConvertFromPDF.map((val, i) => (
                                                        <Box key={i} className={styles.tool_top} sx={{ display: 'flex' }} onClick={() => handlePdfTypes(val)}>
                                                            <img
                                                                src={process.env.REACT_APP_IMAGE_URL + val.icon}
                                                                className={styles.box_img}
                                                                alt='menu-item'
                                                                style={{ width: '24px', height: '24px' }}
                                                            />
                                                            <Box className={styles.tool_name}>{val.name}</Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={styles.top_tools_colums}>
                                    <Box className={styles.tools_head_name}>
                                        Convert to PDF
                                    </Box>
                                    <Box className={styles.tools_head_tool}>
                                        <Box className={styles.tools}>
                                            <Box className={styles.tool_flex}>
                                                <Box className={styles.tool_center}>
                                                    {ConvertToPDF.map((val) => (
                                                        <Box className={styles.tool_top} sx={{ display: 'flex' }} onClick={() => handlePdfTypes(val)}>
                                                            <img
                                                                src={process.env.REACT_APP_IMAGE_URL + val.icon}
                                                                className={styles.box_img}
                                                                alt='menu-item'
                                                                style={{ width: '24px', height: '24px' }}
                                                            />
                                                            <Box className={styles.tool_name}>{val.name}</Box>
                                                        </Box>
                                                    ))}
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={styles.top_tools_colums}>
                                    <Box className={styles.tools_head_name}>
                                        Sign & Security
                                    </Box>
                                    <Box className={styles.tools_head_tool}>
                                        <Box className={styles.tools}>
                                            <Box className={styles.tool_flex}>
                                                <Box className={styles.tool_center}>
                                                    {SignAndSecurity.map((val) => (
                                                        <Box className={styles.tool_top} sx={{ display: 'flex' }} onClick={() => handlePdfTypes(val)} >
                                                            <img
                                                                src={process.env.REACT_APP_IMAGE_URL + val.icon}
                                                                className={styles.box_img}
                                                                alt='menu-item'
                                                                style={{ width: '24px', height: '24px' }}
                                                            />
                                                            <Box className={styles.tool_name} >{val.name}{val.name === 'eSign PDF' && <sup className={styles.head_sub_names}>Upcoming</sup>}</Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={styles.top_tools_colums}>
                                    <Box className={styles.tools_head_name}>
                                        Organize
                                    </Box>
                                    <Box className={styles.tools_head_tool}>
                                        <Box className={styles.tools}>
                                            <Box className={styles.tool_flex}>
                                                <Box className={styles.tool_center}>
                                                    {Organize.map((val) => (
                                                        <Box className={styles.tool_top} sx={{ display: 'flex' }} onClick={() => handlePdfTypes(val)} >
                                                            <img
                                                                src={process.env.REACT_APP_IMAGE_URL + val.icon}
                                                                className={styles.box_img}
                                                                alt='menu-item'
                                                                style={{ width: '24px', height: '24px' }}
                                                            />
                                                            <Box className={styles.tool_name} >{val.name}</Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box >
            </Drawer>

            <Box className={styles.top_bar} onClick={() => toggle && setToggle(false)}>
                <Box className={styles.top_bar_main}>
                    <Box className={styles.top_bar_img}>
                        <Box className={styles.top_bar_logo}>
                            <img
                                className={styles.logo_img}
                                src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Centilio.png'}
                                alt='welcome-img' />
                            <Box className={styles.logo_text}>Centilio Sign</Box>
                        </Box>
                    </Box>
                    <Box className={styles.menu_bar} >

                        <Box className={styles.tools_menu} onClick={(e) => handleToggle(e)}>
                            <Box className={styles.tools_padding} >
                                <Box className={styles.tools_tool} ><AppsIcon /></Box>
                                <Box className={styles.tools}>Tools</Box>
                                <Box className={styles.tools_arrow} >{toggle ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</Box>
                            </Box>

                        </Box>

                        <Box className={styles.top_head}>
                            <Box className={styles.head_top}>
                                <Box className={styles.head_flex}>
                                    <Box className={styles.head_names}>Compress
                                        <sup className={styles.head_sub_names}>Upcoming</sup></Box>
                                    <Box className={styles.head_names} onClick={() => handlePdfTypes(ConvertToPDF[0])}>Convert</Box>
                                    <Box className={styles.head_names} >Merge <sup className={styles.head_sub_names}>Upcoming</sup></Box>
                                    <Box className={styles.head_names}>Sign <sup className={styles.head_sub_names}>Upcoming</sup></Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={styles.top_head}>
                        <Box className={styles.head_top}>
                            <Box className={styles.head_flex}>
                                <Box className={styles.head_names} onClick={handleLogIn} >Log In</Box>
                                <Box className={styles.head_button} > <Button variant="contained" sx={{ textTransform: "none" }} onClick={handleSignUp}>Free Trial</Button></Box>

                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>

                </Box>

            </Box>

            <Box sx={{ height: '100%' }}>
                <PdfEdit type={type} />
            </Box>
        </Box >
    )
}
export default PdfConverter