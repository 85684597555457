import React, { useEffect, useRef, useState } from 'react'
import { Box, Button } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import styles from './PdfMerge.module.css'
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const PdfMerge = ({ file, mergeData }) => {
    const fileInputRef = useRef(null);
    // var pdfFiles = []
    const [pdfFiles, setPdfFiles] = useState([]);
    const [isDragOver, setIsDragOver] = useState(false);
    const [keyCounter, setKeyCounter] = useState(0);
    // let keyCounter = 0;

    useEffect(() => {
        const newFile = file[0];
        newFile.key = keyCounter + 1
        setPdfFiles([...pdfFiles, newFile]);
        setKeyCounter(keyCounter + 1)
    }, [file])

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };
    const handleDragStop = (e) => {
        e.preventDefault();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const files = Array.from(e.dataTransfer.files);
        const newFile = files[0];
        newFile.key = keyCounter + 1
        setPdfFiles([...pdfFiles, newFile]);
        setKeyCounter(keyCounter + 1)
        e.target.value = null;
        // handleFiles(files);
    };

    const handleFileInputChange = (e) => {
        const files = Array.from(e.target.files);
        const newFile = files[0];
        newFile.key = keyCounter + 1
        setPdfFiles([...pdfFiles, newFile]);
        setKeyCounter(keyCounter + 1)
        e.target.value = null;
        // handleFiles(files);
    };

    const deleteFileByKey = (keyToDelete) => {
        const updatedPdfFiles = pdfFiles.filter(file => file.key !== keyToDelete);
        setPdfFiles(updatedPdfFiles);
    };
    const addClonedFile = (clone) => {
        const newFile = clone;
        newFile.key = keyCounter + 1;
        setPdfFiles([...pdfFiles, newFile]);
        setKeyCounter(keyCounter + 1);
    };

    return (
        <Box>
            <Box>
                <Box className={styles.finish_btn}>
                    <Button variant="contained" endIcon={<NavigateNextIcon />} onClick={() => mergeData(pdfFiles)}>Finish</Button>
                </Box>
            </Box>
            <Box className={styles.pdf_all_files}>
                {pdfFiles.map((val, i) => <Box key={i} className={styles.pdf_file}>
                    <Box className={styles.pdf_file_view}>
                        <Box className={styles.pdf_template}>
                            <Box>
                                <Box className={styles.pdf_template_view}>
                                    <Box className={styles.pdf_template_image}>
                                        <Box className={styles.pdf_image_view}>
                                            <img
                                                src={process.env.REACT_APP_IMAGE_URL + 'pdfConvertericon/fileicon.svg'}
                                                className={styles.box_img}
                                                alt='menu-item'
                                                value={val.name}
                                            />
                                        </Box>
                                    </Box>
                                    <Box className={styles.pdf_template_name}>
                                        <Box className={styles.pdf_name_view}>{val.name}</Box>
                                    </Box>
                                    <Box className={styles.pdf_template_tools}>
                                        <Box className={styles.pdf_template_delete} onClick={() => deleteFileByKey(val.key)} ><DeleteIcon /></Box>
                                        {/* <Box className={styles.pdf_template_delete} onClick={() => addClonedFile(val)}><ContentCopyIcon /></Box> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={styles.pdf_add_next} onClick={() => fileInputRef.current.click()} >
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + 'pdfConvertericon/addnexticon.svg'}
                            className={styles.box_img}
                            alt='menu-item'
                            style={{ width: '24px', height: '24px' }}
                        />
                    </Box>
                </Box>)}
                <Box className={styles.add_new_pdf}
                    onClick={() => fileInputRef.current.click()}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop} >
                    <Box className={styles.add_pdf_icon}>
                        <img
                            src={process.env.REACT_APP_IMAGE_URL + 'pdfConvertericon/addnexticon.svg'}
                            className={styles.box_img}
                            alt='menu-item'
                            style={{ width: '24px', height: '24px' }}
                        />
                    </Box>
                    <Box className={styles.add_pdf_text} >Add PDF files</Box>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                    />
                </Box>
            </Box>
        </Box >
    )
}

export default PdfMerge