import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    value: null,
};

export const pdfDetails = createSlice({
    name: "pdf_details",
    initialState,
    reducers: {
        pdfDetail: (state, action) => {
            state.value = action.payload;
        },
    },
});
export const { pdfDetail } = pdfDetails.actions;

export default pdfDetails.reducer;


