export const FreeList = [
  '1 User',
  '5 Envelopes per month',
  'Unlimited Sign requests',
  'Unlimited Templates',
  'Folder Management',
  'Contacts Management'];

export const ProfessionalList = [
  'Upto 50 User',
  'Unlimited Envelopes',
  'Unlimited Sign requests',
  'Unlimited Templates',
  'AI Template',
  'Automation',
  'Folder Management',
  'Contacts Management',
];

export const EnterpriseList = [
  '50+ User',
  'Unlimited Envelopes',
  'Unlimited Sign requests',
  'Unlimited Templates',
  'AI Template',
  'Automation',
  'Folder Management',
  'Contacts Management',
  'Custom Branding'
];

export const Free = {
  monthly: { planName: "Free", planType: "monthly", price: 0 },
  annually: { planName: "Free", planType: "annually", price: 0 },
};

export const Professional = {
  monthly: { planName: "Professional", planType: "monthly", price: 10 },
  annually: { planName: "Professional", planType: "annually", price: 90 },
};

export const Enterprise = {
  monthly: { planName: "Enterprise", planType: "monthly", price: 30 },
  annually: { planName: "Enterprise", planType: "annually", price: 270 },
};
