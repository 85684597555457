// import { crmParamDetail } from './crmParamReducer';

import { adminDriveDetail } from "./adminDriveReducer";

export const admin_drive_details = (query) => async (dispatch) => {
    try {
        dispatch(adminDriveDetail(query));
    } catch (err) {
        const error = {
            code: 0,
            message: typeof err == 'object' ? err.message : err,
        };
        throw error;
    }
};

