import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, InputLabel, Modal, TextField } from '@mui/material';
import styles from './ViewDocModal.module.css';

const ViewDocModal = (props) => {
    const { info, handlePasscode, modal, file } = props;

    const [showModal, setShowModal] = useState(true);
    const [accessValue, setAccessValue] = useState('');

    useEffect(() => {
        if (file) setShowModal(modal);
    }, [modal, file]);

    return (
        <Box>
            <Modal
                open={showModal}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box className={styles.view_doc_body}>
                    <Box className={styles.doc_view_title}>
                        Please enter access code to view the document
                    </Box>
                    <Box className={styles.view_doc_avatar}>
                        <Box>
                            <Avatar />
                        </Box>
                        <Box>
                            <Box className={styles.doc_Bold_lightText}>{info?.recipient_details?.name}</Box>
                            <Box className={styles.doc_view_lightText}>{info?.recipient_details?.email_id}</Box>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Box className={styles.doc_view_BoldText}>
                            The sender has requested you enter a secret access code prior to
                            reviewing the document.You should have received access code in a separate
                            communication.Please enter the code and validate it in order to proceed
                            to viewing the document.
                        </Box>
                    </Box>
                    <Box sx={{ mt: 3, ml: '10px' }}>
                        <InputLabel className={styles.access_input}>Access Code</InputLabel>
                        <Box className={styles.docs_view_texField}>
                            <TextField
                                onChange={(e) => setAccessValue(e.target.value)}
                                value={accessValue}
                                sx={{
                                    fieldset: { border: "1px solid #E6E7E9" },
                                    '& .MuiOutlinedInput-root': {
                                        '&:hover fieldset': {
                                            borderColor: 'var(--primary)',
                                        },
                                    },
                                }}
                            />
                            <Button className={styles.doc_view_button} onClick={() => handlePasscode(accessValue)}>Validate</Button>
                            <Button sx={{ textTransform: 'capitalize' }} onClick={() => window.open('https://centilio.com', '_self')}>
                                I Never Received An Access Code
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default ViewDocModal;
