import { Button, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./AddTemplates.module.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { toggleLoader } from "../../store/loader/loaderReducer";
import { paraphraseDetail } from "../../store/paraphrase/paraphraseReducer";
import { getFileParaphrase } from "../../Api/ParaphraseApi";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const AddTemplates = ({ steps }) => {
  const [url, setUrl] = useState(false);
  const [format, setFormat] = useState(true);
  const [keyword, setKeyword] = useState(false);
  const [values, setValues] = useState(false);
  const [form, setForm] = useState({ file: '', keywords: '', urls: '', org_id: '', type: '' });

  const document = useSelector((state) => state.document.value);
  const loader = useSelector((state) => state?.loader);
  const dispatch = useDispatch();
  const templatefileInput = useRef(null);



  const selectedFile = (e, key) => {
    if (key === 'keyword') {
      const value = e.target.value
      if (value.length) {
        setForm({
          keywords: e.target.value,
          org_id: document?.OrgId,
          type: 2,
        })
      }
      else setForm({ org_id: '' })

    }
    else if (key === 'link') {
      setForm({
        urls: e.target.value,
        org_id: document?.OrgId,
        type: 3,
      })
    }
    else {
      let form;
      if (e.target.files[0].name.includes('.pdf')) {
        const file = e.target.files[0];
        form = {
          file: file,
          org_id: document?.OrgId,
          type: 1,
        }

        const formData = new FormData();

        Object.keys(form).forEach((key) => {
          formData.append(key, form[key]);
        });

        dispatch(toggleLoader(true));
        getFileParaphrase(formData, (res) => {
          dispatch(toggleLoader(false));
          dispatch(paraphraseDetail({ data: res.data }));
          setValues(true)
          steps((prevValue) => prevValue + 1);
        },
          (err) => {
            if (err?.response?.status === 404) {
              toast.error(err?.response?.data);
              dispatch(toggleLoader(false));
            }
            else {

              err?.response?.data?.error ? toast.error(err?.response?.data?.error.details) : toast.error(err?.message);
              dispatch(toggleLoader(false));
            }
            dispatch(toggleLoader(false));
          }
        );
      }
      else {
        const lastDotIndex = e.target.files[0].name.lastIndexOf('.');
        const fileFormat = e.target.files[0].name.slice(lastDotIndex + 1);
        toast.error(`${fileFormat} invalid file format, Only pdf files are allowed.`);
      }
    }
  }

  const nextStep = () => {
    const formData = new FormData();

    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });

    dispatch(toggleLoader(true));
    getFileParaphrase(formData, (res) => {
      dispatch(toggleLoader(false));
      dispatch(paraphraseDetail({ data: res.data }));
      setValues(true)
      steps((prevValue) => prevValue + 1);
    },
      (err) => {
        if (err?.response?.status === 404) {
          toast.error(err?.response?.data);
          dispatch(toggleLoader(false));
        }
        else {
          err?.response?.data?.error ? toast.error(err?.response?.data?.error.details) : toast.error(err?.message);
          dispatch(toggleLoader(false));
        }
        dispatch(toggleLoader(false));
      }
    );
  };

  const handleUserKeyword = (e, key) => {
    const format = e.target.value.slice(-3);

    setFormat(true)
    if (format === 'pdf' || key === 'keyword') {
      selectedFile(e, key)
    } else setFormat(false)

  }

  const handleKey = (key) => {
    switch (key) {
      case 'url':
        setUrl(true);
        setKeyword(false);
        break;
      case 'keyword':
        setUrl(false);
        setKeyword(true)
        break;
      default:
        break;
    }
  }

  return (
    <div className={styles.top_div}>
      <div className={styles.main_div}>
        <div>
          <div className={styles.image_div}>
            <img src={process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/upload-file.svg`} alt="upload-file" />
          </div>
          <div className={styles.text_div}>
            Upload your document that needs to be referred for creating a
            template.
          </div>
          <div className={styles.btn_div}>
            <Button
              variant="outlined"
              startIcon={<LinkIcon />}
              className={styles.btn}
              component="label"
              onChange={selectedFile}
              onClick={() => templatefileInput.current.click()}
              disabled={loader.isLoading}
            >
              Upload your document
            </Button>
            <input
              ref={templatefileInput}
              type="file"
              id="upload-doc"
              style={{ display: "none" }}
              onChange={(e) => selectedFile(e)}
            />
          </div>
        </div>
        <div>
          <img src={process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/oricon.svg`} alt="or-icon" />
        </div>
        <div>
          <div className={styles.image_div}>
            <img src={process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/url.svg`} alt="url-icon" />
          </div>
          <div className={styles.text_div}>
            Enter public URL to your document that needs to be referred for
            creating a template.
          </div>
          {url ? (
            <div className={styles.btn_div}>
              <input className={styles.input_value_open} placeholder=" " type="url" style={{ border: format || '1px solid #ff3b3b' }} onChange={(e) => handleUserKeyword(e, 'link')} />
              {format || <div style={{ color: 'red', fontSize: '12px' }}> Invalid Url </div>}
            </div>
          ) : (
            <div className={styles.btn_div}>
              <Button
                className={styles.btn}
                variant="outlined"
                startIcon={<LinkIcon />}
                onClick={() => handleKey('url')}
                disabled={loader.isLoading}
              >
                Paste your Url
              </Button>
            </div>
          )}
        </div>
        <div>
          <img src={process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/oricon.svg`} alt="or-icons" />
        </div>
        <div>
          <div className={styles.image_div}>
            <img src={process.env.REACT_APP_IMAGE_URL + `TemplatesSvg/keywords.svg`} alt="My SVG" />
          </div>
          <div className={styles.text_div}>
            Enter the keywords of the template name that needs to be created.
          </div>
          {keyword ? (
            <div className={styles.btn_div_keyword}>
              <TextareaAutosize style={{ maxHeight: '100px', minHeight: '90px', minWidth: '300px', maxWidth: '400px' }} placeholder=" " type="text" onChange={(e) => handleUserKeyword(e, 'keyword')} />
            </div>
          ) : (
            <div className={styles.btn_div}>
              <Button
                className={styles.btn}
                variant="outlined"
                startIcon={<SearchIcon />}
                onClick={() => handleKey('keyword')}
                disabled={loader.isLoading}
              >
                Enter your keywords here
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.next_btn}>
        <Button
          disabled={!form.org_id || loader.isLoading}
          className={styles.btn}
          variant="contained"
          onClick={nextStep}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AddTemplates;
