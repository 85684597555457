import { Box, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import styles from './ApprovarModal.module.css';
import { useState } from "react";

const ApprovarModal = ({ open, type, closeModal, handleApprove, loading, handleReject, handleEditFields }) => {
    const [reason, setReason] = useState('');
    return (
        <div className={styles.decline_mask} style={{ display: open ? 'inline-table' : 'none' }}>
            <div className={styles.decline_wrapper}>
                {type !== 'edit' ?
                    <div className={styles.decline_container}>
                        <div className={styles.decline_popup} onClick={() => [closeModal(false), setReason('')]}>
                            <IconButton>
                                <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                            </IconButton>
                        </div>
                        <div className={styles.decline_body}>
                            <div className={styles.decline_text}>Reason for {type === 'approve' ? 'Approve' : 'Decline'}</div>
                            <div className={styles.decline_textarea}>
                                <textarea
                                    className={styles.decline_desc}
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    placeholder={`Reason for ${type === 'approve' ? 'Approve' : 'Decline'}`}
                                />
                            </div>
                            <Box className={styles.decline_buttons}>
                                <Button className={styles.decline_submit} disabled={loading} variant="contained" onClick={() => type === 'approve' ? handleApprove(reason) : handleReject(reason)}>Submit</Button>
                            </Box>
                        </div>
                    </div>
                    :
                    <Box className={styles.decline_container}>
                        <Box className={styles.edit_doc}>
                            <Box className={styles.edit_text}>Edit this document?</Box>
                            <Box className={styles.approvar_decline_popup} onClick={() => closeModal(false)}>
                                <IconButton>
                                    <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box className={styles.edit_desc}>
                            if you do, its signature and initials fields will be cleared, and you'll need to resend the document so recipients can sign the updated version.
                        </Box>
                        <Box className={styles.edit_doc}>
                            <Button className={styles.decline_submit} disabled={loading} variant="outlined" onClick={() => closeModal(false)}>Cancel</Button>
                            <Button className={styles.decline_submit} disabled={loading} variant="contained" onClick={() => handleEditFields()}>Edit document</Button>
                        </Box>

                    </Box>}
            </div>
        </div>
    )
};

export default ApprovarModal;