import { welcomeImgDetail } from './welcomeImgReducer';

export const welcome_img_action = (query) => async (dispatch) => {
 try {
  dispatch(welcomeImgDetail(query));
 } catch (err) {
  const error = {
   code: 0,
   message: typeof err == 'object' ? err.message : err,
  };
  throw error;
 }
};
