import React from 'react'
import styles from './HowToUse.module.css';

const HowToUse = () => {
    return (
        <div className={styles.container_height}>
            <div className={styles.container}>
                <div className={styles.center_div}>
                    {/* <img className={styles.image_20} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/docusign-logo.png'} alt='docusign-logo' /> */}
                    <img className={styles.image_20}
                        src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Centilio.png'}
                        alt='welcome-img'
                    />
                    <div className={styles.subheading}><strong>Centilio Sign </strong>transforms your signature workflow, offering a seamless and efficient electronic signing experience. Integrate it effortlessly with monday.com for a streamlined, secure, and hassle-free solution.</div>
                    <img className={styles.image_19} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/base_image.jpg'} alt='app_docu_image' />

                    <div className={styles.how_to_use}> Installation Guide for Integrating Centilio Sign with monday.com:</div>
                    <p className={styles.step_headline}> Step 1 : Installation of Centilio Sign Integration</p>
                    <p className={styles.text_step}><strong>There are two methods to install the Centilio Sign app:</strong><br /><br /> Begin by visiting the monday.com marketplace. Search for "Centilio Sign" and install the app directly into your monday.com account.<br /><br /> Access Centilio Sign, go to the "Integration" tab, and locate the monday.com installation option</p>
                    <p className={styles.step_headline}> Step 2 : Configuring Centilio Sign Features</p>
                    <p className={styles.text_step}>Upon successful installation, you will be presented with two features: "Board View" and "Item View."<br /> <br />Ensure that you complete this step before proceeding with the integration. If an existing centilio integration is in place on your board, delete it before continuing. </p>
                    <p className={styles.step_headline}> Step 3 : Adding Centilio Sign to Board or Item View</p>
                    <p className={styles.text_step}>Access the desired board or item view.<br /><br />Look for the "+" symbol on the page and click it.<br /><br />In the options menu, select "Apps" and then choose the "Centilio Sign" app. <br /><br /><p className={styles.step_headline}>Congratulations! </p>The Centilio Sign app is now successfully integrated into your specific board or item view. </p>
                    <div> <div className={styles.how_to_use}> How to Use Centilio Sign App with monday.com:</div></div>
                    <div className={styles.video_div}>
                        {/* <img className={styles.image_19} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/app_docu_image.png'} alt='app_docu_image' /> */}
                    </div>
                    <div className={styles.steps}>
                        <p className={styles.step_headline}>Step 1 : Access Centilio Sign in Item View or Board View</p>
                        <p className={styles.text_step}>Open the Centilio Sign app within Item View or Board View on monday.com.</p>
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_1.jpg'} alt='step_1' />
                    </div>
                    <div className={styles.steps}>
                        <p className={styles.step_headline}>Step 2 : Centilio Sign Welcome Page</p>
                        <p className={styles.text_step}>Upon entering, you'll encounter the Centilio Sign welcome page <br /><br />If the user is not logged in, they will be prompted to log in; otherwise, proceed directly to document creation.</p>
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_2.png'} alt='step_2' />
                    </div>
                    <div className={styles.steps}>
                        <p className={styles.step_headline}>Step 3 : Create Document</p>
                        <p className={styles.text_step}>Click the "Create Document" button to be redirected to the document upload page. <br /><br />Upload the document intended for signature; it will open in a new tab.</p>
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_03.png'} alt='step_3' />
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_03_02.png'} alt='step_03_02' />

                    </div>
                    <div className={styles.steps}>
                        <p className={styles.step_headline}>Step 4 : Add Recipients and Fields</p>
                        <p className={styles.text_step}>Customize the document by adding recipients. <br /><br />
                            Drag and drop fields like signature, name, date, etc., onto the document.</p>
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_04.png'} alt='step_04' />
                    </div>
                    <div className={styles.steps}>
                        <p className={styles.step_headline}>Step 5 : Document Ready for Sending</p>
                        <p className={styles.text_step}>Once the document is prepared, click "Send" to proceed to the next page. <br /><br />
                            Add a description for the document and set the document expiry date.</p>
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_05.png'} alt='step_05' />
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_05_02.png'} alt='step_05_02' />
                    </div>
                    <div className={styles.steps}>
                        <p className={styles.step_headline}>Step 6 : Document Ready for Sending</p>
                        <p className={styles.text_step}>Confirm the details and send the document to the recipients added earlier.</p>
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_06.png'} alt='step_06' />
                    </div>
                    <div className={styles.steps}>
                        <p className={styles.step_headline}>Step 7 : Return to monday.com Board or Item View</p>
                        <p className={styles.text_step}>After successfully sending the document, return to the monday.com board or item view for the Centilio app.<br /><br />
                            Click the "Refresh" button.</p>
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_07.png'} alt='step_07' />
                    </div>
                    <div className={styles.steps}>
                        <p className={styles.step_headline}>Step 8 : Track Document Information</p>
                        <p className={styles.text_step}>View document information for tracking purposes.<br /><br />
                            Click on any document to access options, including the "Sign Journey" feature, allowing you to track the current status of the document.</p>
                        <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/how_to_use_step_08.png'} alt='step_08' />
                    </div>
                    <div className={styles.steps}>
                        <p className={styles.step_headline}>Get ready for an exciting adventure with Centilio Sign</p>
                        <p className={styles.text_step}>Hello! We thoroughly enjoyed creating this app for you, and we genuinely hope you have a fantastic experience using it!<br /><br />
                            If you run into any problems, have special requests, or just want to drop us a message, feel free to get in touch at <a href="javascript:void(0);">team@centilio.com</a>. We're here for you and appreciate any feedback you have. Enjoy the app!.</p>
                        {/* <img className={styles.image_18} src={process.env.REACT_APP_IMAGE_URL + 'HowToUse/step_08.png'} alt='step_08' /> */}
                    </div>
                </div>
            </div >
        </div >
    )
}

export default HowToUse