import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    value: null,
};

export const pdfDatas = createSlice({
    name: "pdf_details",
    initialState,
    reducers: {
        pdfData: (state, action) => {
            state.value = action.payload;
        },
    },
});
export const { pdfData } = pdfDatas.actions;

export default pdfDatas.reducer;


