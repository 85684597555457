
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    seobotinfo: '',
};


export const seobotDetails = createSlice({
    name: "seo_bot_info",
    initialState,
    reducers: {
        seobotDetail: (state, action) => {
            state.seobotinfo = action?.payload?.data

        },
    },
});
export const { seobotDetail } = seobotDetails.actions;


export default seobotDetails.reducer;
