import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    value: null,
};

export const rephraseDetails = createSlice({
    name: "paraphrase_details",
    initialState,
    reducers: {
        rephraseDetail: (state, action) => {
            state.value = action.payload;
        },
    },
});
export const { rephraseDetail } = rephraseDetails.actions;

export default rephraseDetails.reducer;