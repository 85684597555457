import { Box, Button } from '@mui/material'
import React from 'react'
import styles from './WrongPage.module.css'

const WrongPage = () => {

    return (
        <Box style={{ height: "100%" }}>
            <Box className={styles.top_bar}>
                <Box className={styles.top_bar_main}>
                    <Box className={styles.top_bar_img}>
                        <Box className={styles.top_bar_logo}>
                            <img
                                className={styles.logo_img}
                                src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Centilio.png'}
                                alt='welcome-img' />
                            <Box className={styles.logo_text}>Centilio Sign</Box>
                        </Box>
                    </Box>
                    <Box className={styles.top_head}>
                        <Box className={styles.head_top}>
                            <Box className={styles.head_flex}>
                                <Box className={styles.head_names}>Log In</Box>
                                <Box className={styles.head_names}>Sing Up</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={styles.meditation_div}>
                    <img
                        className={styles.meditation}
                        src={process.env.REACT_APP_IMAGE_URL + 'worngpage_meditation.png'}
                        alt='worngpage_meditation' />
                    <Box className={styles.meditation_head}>Something's wrong here.</Box>
                    <Box className={styles.meditation_des}>This is a 404 error, which means you've clicked on a bad link or entered an invalid URL. Maybe what you are looking for can be found at centilio.com. P.S. centilio links are case sensitive.</Box>
                </Box>

            </Box>
        </Box>
    )
}

export default WrongPage;