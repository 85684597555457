import { Box, Button, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './appHeader.module.css';
import TopBar from '../topBar/TopBar';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { toast } from 'react-toastify';

const uploadText = 'Begin by uploading your document.';
const got_it_text = 'Once your document is uploaded, you can easily track its progress in the Sign Request tab. This feature provides a comprehensive view of all your sent documents, keeping you in control and informed throughout the signing process.';

const AppHeader = ({ moduleText = '', btnText = '', onBtnClick, crmPages = '', crm_upload = '', tool_tip_open, body = [], rolePermissions, checkAdmin }) => {
    const [open, setOpen] = useState(false);
    const [next, setNext] = useState(false);

    const dispatch = useDispatch();
    const banner_details = useSelector((state) => state.welcomeImgDetail.value);

    useEffect(() => {
        let banner_info = banner_details?.show_banner_info?.length ? JSON.parse(banner_details?.show_banner_info)[0]?.banner_details?.MONDAY_WELCOME : null;
        if (banner_info) setOpen(true);
    }, []);

    const ToolTips = () => {

        const handleTolltip = () => {
            dispatch(toggleLoader(true));
            let option = { banner_key: "MONDAY_WELCOME" }
            axios.post(process.env.REACT_APP_BANNER, option, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    setOpen(false);
                    tool_tip_open(false);
                    dispatch(toggleLoader(false));
                })
                .catch((err) => {
                    toast.error(err.message);
                    dispatch(toggleLoader(false));
                })
        };

        return (
            <Tooltip title={<div><Box className={styles.tooltip_text}>{next ? got_it_text : uploadText}</Box>
            <Box className={styles.next_wrapper}>{!next && <Box onClick={() => setNext(true)} className={styles.got_it}>{`Next >>`}</Box>}<Box onClick={() => handleTolltip()} className={styles.got_it}>Got it!</Box></Box>
            </div>} placement="left-end" arrow open={open} componentsProps={{
                tooltip: {
                    sx: { color: 'var(--table-font-color)', backgroundColor: '#fff', boxShadow: 'var(--box-shadow) !important' }
                }, arrow: { sx: { color: '#fff', boxShadow: 'var(--box-shadow) !important' } }
            }}
            >
                <Box className={styles.app_header_profile}>
                <Button
                    style={{ textTransform: 'unset', gap: 7, fontFamily: 'var(--primary-font-family)' }}
                    variant='contained'
                    className={styles.btn}
                    onClick={onBtnClick}
                > {btnText === 'Upload' &&
                    <img
                        src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/upload-icon.svg'}
                        className={styles.upload_img}
                        alt='fileUpload'
                    />}{btnText}</Button>
                <TopBar />
                </Box>
            </Tooltip >
        )
    };

    return (
        <Box className={crmPages ? styles.header_wrap : styles.wrap}>
            <Box component='h3' className={styles.header_text}>{moduleText}</Box>
            {btnText && (checkAdmin || rolePermissions.create === 1) && (
                <Box className={styles.header_wrapper}>
                    {crm_upload ? <ToolTips /> :
                        <Button
                            style={{ textTransform: 'unset', gap: 7, fontFamily: 'var(--primary-font-family)' }}
                            variant='contained'
                            className={styles.btn}
                            onClick={onBtnClick}
                        >
                            {btnText === 'Upload' &&
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/upload-icon.svg'}
                                    className={styles.upload_img}
                                    alt='fileUpload'
                                />}
                            {btnText === 'New Contact' &&
                                <img
                                    src={process.env.REACT_APP_IMAGE_URL + 'UploadFileSvg/person-add-white.svg'}
                                    className={styles.contact_img}
                                    alt='fileUpload'
                                />}
                            {btnText}
                        </Button>
                    }
                </Box>
            )}
            {crmPages && <TopBar />}
        </Box>
    );
};

export default AppHeader;
