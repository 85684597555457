import { useEffect, useRef } from "react";

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 B';

    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let total = bytes;
    let i = 0;
    for (i; total > 0; i++) {
        total = Math.floor(total / 1024);
    }
    return `${(bytes / Math.pow(1024, i - 1)).toFixed(dm)} ${sizes[i - 1]}`;
};

export const replaceToNumber = (val = '', canAllowInteger) =>
    Number(canAllowInteger ? val.replace(/[^\d.]+|(?<=\..*)\./g, '') : val.replace(/\D/g, ''));

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, [])
    return isMountRef.current;
};