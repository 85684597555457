import React, { useState } from 'react'
import { Box, Button, FormControl, InputAdornment, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';

const Signup = ({ userId }) => {
    const [signtype, setSigntype] = useState(0);
    const [signUp, setSignUp] = useState({ email: "", password: "" })
    const [error, setError] = useState({ email: true, password: true })
    const [show, setShow] = useState(true);
    const dispatch = useDispatch();

    const handleCreate = () => {

        if (signUp.password.length >= 8) {
            setError({ ...error, password: true })
            let data = { emailId: signUp.email, password: signUp.password }
            dispatch(toggleLoader(true));
            axios.post(process.env.REACT_APP_USER_SIGNUP, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
                .then((res) => {
                    dispatch(toggleLoader(false));
                    setError({ ...error, email: true })
                }).catch((err) => {
                    setError({ ...error, email: false, password: true })
                    dispatch(toggleLoader(false));
                    toast.error(err.response.data);
                })
        }
        else {
            setError({ ...error, password: false })
        }
    }

    const handleConfirm = () => {
        let data = { user_id: userId }
        dispatch(toggleLoader(true));
        axios.put(process.env.REACT_APP_USER_SIGNUP, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.response.data);
            })
    }

    return (
        <Box sx={{ padding: "40px", width: '575px' }}>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Type</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={signtype}
                        onChange={(e) => setSigntype(e.target.value)}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={0}>CREATE</MenuItem>
                        <MenuItem value={1}>CONFIRM</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {!signtype ? <Box sx={{ paddingTop: '5px' }}>
                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Email Id:</Box>
                    <FormControl size="small" >
                        <TextField size="small" style={{ height: 35, width: '250px' }} placeholder='Enter Email Id' value={signUp.email}
                            onChange={(e) => setSignUp({ ...signUp, email: e.target.value })}
                            error={!error.email}
                            helperText={!error.email && "Email id Exist"} />
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Password:</Box>
                    <FormControl size="small" sx={{ display: 'flex' }} >
                        {show ?
                            <TextField type='password' size="small" value={signUp.password} placeholder='Enter Password'
                                onChange={(e) => setSignUp({ ...signUp, password: e.target.value })}
                                style={{ height: 35, width: '250px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <VisibilityIcon onClick={() => setShow(false)} />
                                        </InputAdornment>
                                    ),
                                }}
                                error={!error.password}
                                helperText={!error.password && "Must be at least 8 Characters"}
                            /> : <TextField size="small" type='name' value={signUp.password} placeholder='Enter Password'
                                onChange={(e) => setSignUp({ ...signUp, password: e.target.value })}
                                style={{ height: 35, width: '250px' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <VisibilityOffIcon onClick={() => setShow(true)} />
                                        </InputAdornment>
                                    ),
                                }}
                                error={!error.password}
                                helperText={!error.password && "Must be at least 8 Characters"}
                            />
                        }
                    </FormControl>
                </Box>
                <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                    <Button variant="contained" onClick={handleCreate}>Submit</Button></Box>
            </Box> :
                <Box>
                    <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                        <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >User Id:</Box>
                        <FormControl size="small" >
                            <OutlinedInput style={{ height: 35, width: '250px' }} placeholder='Enter User Id' value={userId} disabled />
                        </FormControl>
                    </Box>
                    <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                        <Button variant="contained" onClick={handleConfirm}>Submit</Button>
                    </Box>
                </Box>}
        </Box>
    )
}

export default Signup