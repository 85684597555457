import axios from "axios";

export const getPDFDownload = (data, resp, error) => {
    axios.get(process.env.REACT_APP_PDFDOWNLOAD, {
        withCredentials: process.env.REACT_APP_CREDENTIALS,
        params: { org_id: data.Org_Id, sign_id: data.sign_id, type: data.type }, responseType: 'arraybuffer'
    })
        .then((res) => {
            resp(res);
        })
        .catch((err) => {
            error(err);
        });
};