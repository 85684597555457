import { ThemeProvider } from '@mui/material';
import AppRoute from './appRoute';
import { ToastContainer } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from './theme';

function App() {

  const CloseButton = ({ closeToast }) => (
    <div
      className="close-icons"
      onClick={closeToast}
    >
      <CloseIcon fontSize="small" />
    </div>
  );

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        closeButton={CloseButton}
        pauseOnFocusLoss
        icon={false}
        draggable
        pauseOnHover={true}
        theme='light'
      />
      <ThemeProvider theme={theme}>
        <AppRoute />;
      </ThemeProvider>
    </>
  );
}

export default App;
