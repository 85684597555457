import { combineReducers } from 'redux';
import app from './app/appReducer';
import loader from './loader/loaderReducer';
import document from './uploadDocument/uploadReducer';
import document_details from './documentDetails/document_reducer';
import templatePreview from './templatePreview/templatePreviewReducer';
import recipientDetails from './recipientDetails/recipientRedeucer';
import welcomeImgDetail from './welcomeImage/welcomeImgReducer';
import digestDetails from './digest/digest_reducer';
import signId_details from './signIDDetails/signId_reducer';
import signature_id from './signatureId/signatureIdReducer';
import integrationDetails from './integrations/integrationReducer';
import crm_param from './crmParam/crmParamReducer';
import schedule_Name from './schedule/scheduleReducer';
import paymentDetails from "./payment/paymentReducer";
import paraphraseDetails from "./paraphrase/paraphraseReducer";
import rephraseDetails from "./paraphrase/rephraseReducer";
import pdfDetails from "./paraphrase/pdfReducer";
import pdfDatas from "./paraphrase/pdfDataReducer";
import adminAccountDetails from "./adminDetails/adminAccountReducer";
import adminDetails from "./adminDetails/adminDetailsReducer";
import adminDriveDetails from "./adminDetails/adminDriveReducer";
import folderId from './folderId/folderIdReducer';
import seobotDetails from "./adminDetails/seoBotAccountReducer";

const rootReducer = combineReducers({
  app, loader, document, document_details, templatePreview, recipientDetails, welcomeImgDetail, digestDetails, signId_details, signature_id, integrationDetails, crm_param, paymentDetails, paraphraseDetails,
  rephraseDetails, pdfDetails, pdfDatas, schedule_Name, adminAccountDetails, adminDetails, adminDriveDetails, folderId, seobotDetails
});

export default rootReducer;
