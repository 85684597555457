import axios from "axios";
import { document_action } from "../store/documentDetails/document_action";
import { template_preview_action } from "../store/templatePreview/templatePreviewAction";

export const getTemplatePreview = (id, dispatch, resp, error) => {

  if (id) {
    dispatch(document_action(null));
    axios.get(process.env.REACT_APP_TEMPLATEPREVIEW, {
      withCredentials: process.env.REACT_APP_CREDENTIALS,
      params: { org_id: id?.Org_Id, template_id: id?.Template_Id },
    })
      .then((res) => {
        let data = res.data;
        dispatch(document_action(data));
        let field_value = JSON.parse(res.data.template_information.Properties);
        dispatch(template_preview_action(field_value));
        resp(res);
      })
      .catch((err) => {
        error(err);
      });
  }
};