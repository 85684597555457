import { Box, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import styles from "./Payment.module.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Free, Professional, Enterprise } from "./ListDetails.js";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { paymentDetail } from "../../store/payment/paymentReducer.js";
import { Navigation } from "../../utils/handleNavigation.js";
import axios from "axios";
import { toast } from "react-toastify";
import { toggleLoader } from "../../store/loader/loaderReducer.js";
import CloseIcon from '@mui/icons-material/Close';

const plans_type = { Free: 1, Professional: 2, Enterprise: 3 };

function Payment() {
  const user_details = useSelector((state) => state?.document?.value);
  const selectPlan = useSelector((state) => state?.paymentDetails);
  const document = useSelector((state) => state?.document?.value);
  let reload = window.performance.getEntriesByType('navigation').map((nav) => nav.type);

  const dispatch = useDispatch();
  const location = useLocation();
  const navPath = Navigation();

  const [promo, setPromo] = useState('');
  const [type, setType] = useState({ name: selectPlan?.planName, plan: selectPlan?.planType });
  const [user, setUser] = useState(1);
  const [add, setAdd] = useState(true);
  const [dis_price, setDis_price] = useState(null);
  const [app_promo, setApp_promo] = useState(null);
  const [promo_code, setPromo_code] = useState(false);

  const changeTypePlan = (e) => {
    setType({ ...type, plan: e.target.value });
  };

  useEffect(() => {    
    if (location?.state?.state?.promoCode) {
      handleApplyPromoCode(location?.state?.state?.promoCode);
    } else {
      let code_ = localStorage.getItem("promo_code");
      if (reload[0] === 'reload' && code_) {
        handleApplyPromoCode(code_);
      }
    }
  }, []);

  const handleApplyPromoCode = (code) => {
    setPromo(code);
      setAdd(false);
      handleSubmitPromo(code, 'url_promo');
  };

  useEffect(() => {
    switch (type.name) {
      case "Free":
        let personal_details = Free[type.plan];
        dispatch(
          paymentDetail({
            planName: personal_details.planName,
            planType: personal_details.planType,
            price: personal_details.price,
          })
        );

        break;
      case "Professional":
        let standard_details = Professional[type.plan];
        dispatch(
          paymentDetail({
            planName: standard_details.planName,
            planType: standard_details.planType,
            price: standard_details.price,
          })
        );
        break;
      case "Enterprise":
        let businessPro_details = Enterprise[type.plan];
        dispatch(
          paymentDetail({
            planName: businessPro_details.planName,
            planType: businessPro_details.planType,
            price: businessPro_details.price,
          })
        );
        break;
      default:
        break;
    }
  }, [location, type]);

  const handleSubmit = () => {
    dispatch(toggleLoader(true));
    let param = { plan_type: plans_type[type?.name], org_id: document.OrgId, product_code: 3, subscription_type: type?.plan === 'monthly' ? 1 : 2 };
    if (dis_price !== null) param['promo_code'] = app_promo;
    axios.get(process.env.REACT_APP_PAYMENT, { params: param, withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        dispatch(toggleLoader(false));
        localStorage.removeItem("promo_code");
        if (res?.data?.success && res?.data?.payment_link) window.open(res?.data?.payment_link, '_self');
        else {
          if (res?.data?.success) navPath('/home');
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error?.message) {
          toast.error(err?.response?.data?.error?.details);
        } else {
          toast.error(err.message);
        }
        dispatch(toggleLoader(false));
      });
  };

  const handleSubmitPromo = (promoCode, url_promo) => {
    setPromo_code(true);
    let param = { plan_type: plans_type[type?.name], org_id: document.OrgId, product_code: 3, subscription_type: type?.plan === 'monthly' ? 1 : 2, promo_code: url_promo ? promoCode : promo };
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_PAYMENT_PROMOCODE, { params: param, withCredentials: process.env.REACT_APP_CREDENTIALS })
      .then((res) => {
        setApp_promo(url_promo ? promoCode : promo);
        setDis_price(res?.data?.discounted_price);
        dispatch(toggleLoader(false));
      })
      .catch((err) => {
        setApp_promo(false);
        localStorage.removeItem("promo_code");
        if (err?.response?.data?.error?.message) {          
          toast.error(err?.response?.data?.error?.details);
        } else {
          toast.error(err.message);
        }
        dispatch(toggleLoader(false));
      });
  };

  const handleClear = () => {
    setPromo('');
    setPromo_code(false);
    setApp_promo(null);
    setDis_price(null);
  };

  return (
    <Box className={styles.payment_top_div}>
      <div className={styles.payment_details}>
        <div className={styles.left_side}>
          <Button variant="contained" className={styles.back_button} onClick={() => navPath("/price-list")}>Back</Button>
          <div className={styles.order}>ORDER SUMMARY</div>
          <div className={styles.your_details}>
            <div className={styles.name}>{user_details?.FirstName}</div>
            <div className={styles.mail}>{user_details?.Email}</div>
          </div>
          <div className={styles.plan_type}>
            <div className={styles.plan_text}>Plan Type :</div>
            <div className={styles.plan_value} >{selectPlan.planName}</div>
          </div>
          <div className={styles.bill}>
            <div style={{ paddingTop: "12px", fontWeight: "bold", width: '100%' }}>
              Billing options
            </div>
            <div className={styles.select_payment}>
              <select
                value={selectPlan?.planType?.toLowerCase()}
                onChange={changeTypePlan}
              >
                <option value="monthly">Monthly</option>
                <option value="annually">Annually</option>
              </select>
            </div>
          </div>
          {/* <div className={styles.bill_price}>
            <div style={{ width: '100%' }}>
              <div className={styles.users} style={{ fontWeight: "bold" }}>
                Users
              </div>
               <div className={styles.all_users} style={{ fontSize: "12px" }}>
                ${selectPlan.price} per user
              </div> 
            </div>
            <div className={styles.select_payment}>
              <select
                value={user}
                onChange={(e) => setUser(Number(e.target.value))}
                disabled
              >
                <option value="1">{selectPlan?.planName === 'Professional' ? 50 : 100}</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
          </div> */}
          {/* <div className={styles.moreoption}>
            For more than 50 users, contact
            <span style={{ color: "dodgerblue" }}> team@centilio.com</span>
          </div> */}
          <div className={styles.promocode}>
            <div className={styles.promo_name}>Promo code</div>
            {add ? <div className={styles.promo_add} onClick={() => setAdd(false)}>Add</div> :
              <Box className={styles.sign_order_input}>
                <Box style={{ display: 'flex' }}>
                  <TextField
                    onChange={(e) => setPromo(e.target.value)}
                    className={styles.promo_text_field}
                    sx={{ "& fieldset": { border: '1px solid var(--border-color) !important' }, input: { color: "#62637a !important", fontSize: '14px !important', padding: '10px 5px !important', background: '#fff !important', borderRadius: '6px !important' }, width: '100%', background: '#fff !important', borderRadius: '6px !important' }}
                    placeholder="Enter promo code"
                    InputProps={{
                      endAdornment: (
                        promo && (<InputAdornment position="end" sx={{ marginLeft: '0px !important' }}>
                          <IconButton onClick={handleClear} edge="end" sx={{ padding: '4px' }}><CloseIcon fontSize="small" sx={{ color: "#ed4c78", cursor: "pointer" }} /></IconButton>
                        </InputAdornment>)
                      )
                    }}
                    variant="outlined"
                    value={promo}
                    disabled={promo_code}
                    autoFocus
                  />
                  <Button variant="text" disabled={!promo} className={styles.promo_button} onClick={() => handleSubmitPromo()}>
                    Submit
                  </Button>
                </Box>
                {app_promo === promo && <Box className={styles.promo_applied}>Promo code applied</Box>}
                {app_promo !== promo && promo_code && app_promo !== null && <Box className={styles.promo_invalid}>Invalid promo code</Box>}
              </Box>}
          </div>
          <div className={styles.sub_total}>
            <div className={styles.sub_value}>Total</div>
            <div style={{ textDecoration: dis_price !== null && 'line-through' }}>{selectPlan.price * user}$/{type?.plan === 'monthly' ? 'month' : 'year'}</div>
          </div>
          {dis_price !== null && <div className={styles.sub_total}>
            <div className={styles.sub_value}>Final Total</div>
            <div>{dis_price * user}$/{type?.plan === 'monthly' ? 'month' : 'year'}</div>
          </div>}
          {/* <div className={styles.due}>
            <div>Due today</div>
            <div>{selectPlan.price * user}$</div>
          </div> */}
          <Box className={styles.submit_btn}>
            <Button variant="contained" className={styles.pay_button} onClick={() => handleSubmit()}>
              Submit
            </Button>
          </Box>
        </div>
      </div>
    </Box>
  );
}

export default Payment;
