import { Box, Button, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import styles from './Calender.module.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScheduleForm from '../ScheduleForm/ScheduleForm';

const Calender = () => {

    const [value, onChange] = useState(new Date());
    const [toggle, setToggle] = useState(true);
    const [dateValue, setDateValue] = useState(null);
    const [selectedTime, setSelectedTime] = useState(false);
    const [timeToggle, setTimeToggle] = useState(true);
    const [form, setForm] = useState(false);
    const [sendTimeValue, setSendTimeValue] = useState(null);
    const [timeValue, setTimeValue] = useState([]);
    const [eventTime, setEventTime] = useState(null);

    useEffect(() => {
        generateTime();
    }, [])

    const handleOnclickDay = (e) => {
        setToggle(false);
        setDateValue(e);
        setSelectedTime(true);
    };

    const someDateString = moment(dateValue).format("MMMM D, YYYY");
    const day = moment(dateValue).format("dddd");

    const isSunday = (date) => {
        return date.getDay() === 0;
    };

    const tileDisabled = ({ date }) => {
        return isSunday(date);
    };

    const handleClose = (val) => {
        setTimeToggle(true);
        setForm(false);
    };

    const generateTime = () => {
        const times = [];
        const startTime = new Date();
        startTime.setHours(0, 0, 0, 0);
        for (let i = 0; i < 96; i++) {
            const time = new Date(startTime.getTime() + i * 15 * 60 * 1000);
            const formattedTime = moment(time).format('LT')
            times.push(formattedTime);
        }
        setTimeValue(times);
    };

    const nextMinutes = (givenTime) => {
        const [time, period] = givenTime.split(' ');
        const [hoursStr, minutesStr] = time.split(':');
        let givenHours = parseInt(hoursStr, 10);
        let givenMinutes = parseInt(minutesStr, 10);

        givenMinutes += 15;

        if (givenMinutes >= 60) {
            givenHours += Math.floor(givenMinutes / 60);
            givenMinutes %= 60;
        }

        givenHours %= 12;
        if (givenHours === 0) {
            givenHours = 12;
        }
        const nextPeriod = period === 'AM' ? 'AM' : 'PM';
        const nextTime = `${String(givenHours).padStart(2, '0')}:${String(givenMinutes).padStart(2, '0')} ${nextPeriod}`;
        setSendTimeValue(nextTime);
    };

    const handleTime = (e, time) => {
        setEventTime(time);
        nextMinutes(time);
        setTimeToggle(false);
        setForm(true);
    };

    return (
        <>
            {toggle ? <Box sx={{ padding: '20px' }}>
                <Box className={styles.main_calender_container}>
                    <Box className={styles.day_text}>Select Day</Box>
                    <Calendar className={styles.main_calender} onChange={onChange} value={value} onClickDay={(e) => handleOnclickDay(e)} minDate={value} tileDisabled={tileDisabled} />
                </Box>
            </Box > :
                <Box style={{ width: '100%' }}>
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        {timeToggle &&
                            <Box className={styles.main_container}>
                                <Box className={styles.top_hiding_container}>
                                    <IconButton className={styles.back_icon} onClick={() => setToggle(true)} ><ArrowBackIcon /></IconButton>
                                    {(!selectedTime ? <Box className={styles.days_container}>
                                        <Box className={styles.day}>{day}</Box>
                                        <Box className={styles.month_date}>{someDateString}</Box>
                                    </Box> :
                                        <Box className={styles.days_container_disabled}>
                                            <Box className={styles.centilio_sign}>
                                                Centilio Sign
                                            </Box>
                                        </Box>)}
                                </Box>
                                <Box sx={{ m: '25px' }}>
                                    {selectedTime &&
                                        <>
                                            <Box className={styles.day} sx={{ mt: 2 }}>Select a Time</Box>
                                            <Box className={styles.duration}>Duration: 15 min</Box>
                                        </>}
                                    <Box className={styles.time_container}>
                                        <Box className={styles.main_time_btn}>
                                            {(timeValue?.length && !form) && timeValue?.map((time, index) => (
                                                <Button className={styles.time_btn} variant='outlined' onClick={(e) => handleTime(e, time)} key={index} value={time}>{time}</Button>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>}
                        {form && <Box className={styles.main_container}>
                            <ScheduleForm toggleClose={() => handleClose()} timeValues={eventTime} dateValues={dateValue} sendTime={sendTimeValue} />
                        </Box>}
                    </Box>
                </Box>
            }
        </>
    );
}
export default Calender;

