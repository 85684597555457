import React, { useRef, useState } from 'react'
import styles from "./blog.module.css";
import LinkIcon from "@mui/icons-material/Link";
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
const Blog = () => {
    const dispatch = useDispatch();
    const timezones = moment.tz.names();
    const templatefileInput = useRef(null);
    const [file, setFile] = useState(null);
    const [timezone, setTimezone] = useState(moment.tz.guess());


    const selectedFile = (e) => {
        const file = e.target.files[0];
        if (!(file['type'].includes('ms-excel') || file['type'].includes('csv'))) {
            toast.error('Invalid file format. Please upload a valid file in the appropriate format (e.g.xls,.csv).')
        } else if (file.size > 10e6) {
            toast.error('The file you are trying to upload is too large. The maximum file size allowed is 10 MB.');
        } else {
            setFile(e.target.files[0])
        };
    }

    const submitFile = () => {
        let form;
        form = {
            file: file,
            timezone: timezone
        };

        const formData = new FormData();

        Object.keys(form).forEach((key) => {
            formData.append(key, form[key]);
        });

        dispatch(toggleLoader(true));
        axios.post(process.env.REACT_APP_BLOG, formData, { headers: { "Content-Type": "multipart/form-data" }, withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
                toast.success(res.message);
            })
            .catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.message);
            });
    }

    const handleChange = (event) => {
        setTimezone(event.target.value);
    };

    return (
        <div style={{
            display: "flex", gap: '15px', padding: '50px'
        }}>
            < div >
                <Button
                    variant="outlined"
                    startIcon={<LinkIcon />}
                    className={styles.btn}
                    component="label"
                    onChange={selectedFile}
                    onClick={() => templatefileInput.current.click()}
                >
                    Upload your File
                </Button>
                <input
                    ref={templatefileInput}
                    type="file"
                    id="upload-doc"
                    style={{ display: "none" }}
                    onChange={(e) => selectedFile(e)}
                />
            </div >
            <FormControl className={styles.controler}>
                <InputLabel id="demo-simple-select-label">Time Zone</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={timezone}
                    label="timezone"
                    className={styles.selector_timezone}
                    onChange={handleChange}
                >
                    {timezones.map((tz) => (
                        <MenuItem key={tz} value={tz}>
                            {tz}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                variant="contained"
                className={styles.sub_btn}
                component="label"
                onClick={() => submitFile()}
                disabled={file ? timezone ? false : true : true}
            >
                Submit
            </Button>
        </div >
    )
}

export default Blog