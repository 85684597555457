import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '../store/loader/loaderReducer';

const Banner = ({ userId, bannerInfo }) => {

    const dispatch = useDispatch();
    const [banner, setBanner] = useState({ userid: userId, status: 0, bannerKey: '' })

    useEffect(() => {
        setBanner({ ...banner, bannerKey: bannerInfo[1] })
    }, [])

    const handleSubmit = () => {
        let data = {
            "banner_key": banner.bannerKey,
            "user_id": banner.userid,
            "status": banner.status,
        }

        dispatch(toggleLoader(true));
        axios.put(process.env.REACT_APP_USER_BANNER, data, { withCredentials: process.env.REACT_APP_CREDENTIALS })
            .then((res) => {
                dispatch(toggleLoader(false));
            }).catch((err) => {
                dispatch(toggleLoader(false));
                toast.error(err.response.data);
            })
    }

    return (
        <Box sx={{ padding: "40px", width: '575px' }}>
            <Box>
                <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                    <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >User Id:</Box>
                    <FormControl size="small" >
                        <OutlinedInput style={{ height: 35, width: '250px' }} placeholder='Enter User Id'
                            type='Number'
                            value={banner.userid}
                            onChange={(e) => setBanner({ ...banner, userid: Number(e.target.value) })} />
                    </FormControl>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Status</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={banner.status}
                        onChange={(e) => setBanner({ ...banner, status: Number(e.target.value) })}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={0}>SHOW_BANNER</MenuItem>
                        <MenuItem value={1}>HIDE_BANNER</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Banner Key </Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={banner.bannerKey}
                        onClick={(e) => setBanner({ ...banner, bannerKey: e.target.outerText, })}
                        style={{ height: 35, width: '250px' }}
                    >
                        {Object.keys(bannerInfo).map((key, index) =>
                            (<MenuItem key={index} value={banner.bannerKey}>{bannerInfo[key]}</MenuItem>)
                        )}
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ paddingTop: '20px', width: '100%', textAlign: 'end' }}>
                <Button variant="contained" onClick={handleSubmit}>Submit</Button>
            </Box>
        </Box>
    )
}

export default Banner