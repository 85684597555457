import { Box, Button, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { toggleLoader } from '../../store/loader/loaderReducer';
import { useDispatch } from 'react-redux';

const PromoCode = () => {

    const [promoCodeValue, setPromoCodeValue] = useState({ Product: 3, Type: 1, ActionType: 1, Value: '', PromoCode: '' });
    const dispatch = useDispatch();

    const handlePromoCode = () => {
        let data = {
            product_code: promoCodeValue.Product,
            type: promoCodeValue.Type,
            promo_code: promoCodeValue.PromoCode,
            value: promoCodeValue.Value,
        }
        promoCodeValue.ActionType === 1 ? axios.post(process.env.REACT_APP_PROMOCODE, data, {
            withCredentials: process.env.REACT_APP_CREDENTIALS
        }).then((res) => {
            dispatch(toggleLoader(false));
            toast.success("Success");
        }).catch((err) => {
            dispatch(toggleLoader(false));
            toast.error(err.message);
        }) : axios.delete(process.env.REACT_APP_PROMOCODE, {
            data: data,
            withCredentials: process.env.REACT_APP_CREDENTIALS
        }).then((res) => {
            dispatch(toggleLoader(false));
            toast.success("Deleted");
        }).catch((err) => {
            dispatch(toggleLoader(false));
            toast.error(err.message);
        })
    }

    return (
        <Box sx={{ padding: "40px", width: '575px' }}>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Product:</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={PromoCode.Product}
                        defaultValue={3}
                        onChange={(e) => setPromoCodeValue({ ...promoCodeValue, Product: e.target.value })}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={3}>SIGN</MenuItem>

                    </Select></FormControl>
            </Box>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Promo Code:</Box>
                <FormControl size="small" >
                    <OutlinedInput
                        value={PromoCode.Product}
                        onChange={(e) => setPromoCodeValue({ ...promoCodeValue, PromoCode: e.target.value })}
                        style={{ height: 35, width: '250px' }}
                        placeholder='Enter PromoCode' />
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Type </Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={PromoCode.Type}
                        defaultValue={1}
                        onChange={(e) => setPromoCodeValue({ ...promoCodeValue, Type: e.target.value })}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={1}>PERCENTAGE</MenuItem>
                        <MenuItem value={2}>MONTHLY DOLLAR</MenuItem>
                        <MenuItem value={3}>YEARLY DOLLAR</MenuItem>

                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Action type</Box>
                <FormControl size="small" >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={1}
                        value={PromoCode.ActionType}
                        onChange={(e) => setPromoCodeValue({ ...promoCodeValue, ActionType: e.target.value })}
                        style={{ height: 35, width: '250px' }}
                    >
                        <MenuItem value={1}>ADD</MenuItem>
                        <MenuItem value={2}>DELETE</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box sx={{ display: 'flex', padding: '5px', gap: '20px' }}>
                <Box sx={{ paddingTop: '5px', width: '200px', textAlign: 'center' }} >Value:</Box>
                <FormControl size="small" >
                    <OutlinedInput
                        value={PromoCode.Value}
                        onChange={(e) => setPromoCodeValue({ ...promoCodeValue, Value: e.target.value })}
                        style={{ height: 35, width: '250px' }}
                        placeholder='Enter Value' />
                </FormControl>
            </Box>
            <Box sx={{
                textAlign: 'end', padding: '20px'
            }}>
                <Button
                    variant="contained"
                    onClick={handlePromoCode}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    )
}

export default PromoCode