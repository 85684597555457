import { Box, Button, FormControl, InputAdornment, TextField } from '@mui/material'
import React, { useState } from 'react'
import styles from './PdfDownloadView.module.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
const DeletePdfPage = ({ downloadPage, deletePages }) => {
    const [deletePage, setDeletePage] = useState('')
    const [errors, setErrors] = useState('');

    const handleDeletePage = () => {
        if (deletePage.length) {
            downloadPage(true)
            setErrors('')
            deletePages(deletePage)

        }
        else setErrors('Enter the Delete Page Number')
    }

    return (
        <Box className={styles.pdf_right_site}>
            <Box className={styles.pdf_right_done}>
                <Box>  <img style={{ width: "30px" }}
                    src={process.env.REACT_APP_IMAGE_URL + 'pdfConvertericon/deleteicon.svg'}
                    className={styles.box_img}
                    alt='menu-item'
                /></Box>
                <Box className={styles.pdf_right_text}>Delete</Box>
            </Box>
            <Box className={styles.pdf_download_area}>

                <FormControl fullWidth sx={{ m: 1 }}>
                    <TextField
                        error={errors}
                        id="outlined-error-helper-text"
                        label="Pages to Delete"
                        type='Number'
                        value={deletePage}
                        helperText={errors}
                        onChange={(e) => setDeletePage(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DeleteIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </FormControl>
            </Box>
            <Box><Button variant="contained" endIcon={<ArrowForwardIosIcon fontSize='small' />}
                onClick={() => handleDeletePage()}
                fullWidth> Delete </Button> </Box>

        </Box>
    )
}

export default DeletePdfPage