const routes = [{
  component: "Home",
  path: "home",
  exact: true,
},
{
  component: "WelcomePage",
  path: "my-documents",
  exact: true,
},
{
  component: "Upload",
  path: "upload",
  exact: true,
},
{
  component: "SignSelf",
  path: "sign-myself",
  exact: true,
},
{
  component: "SendSign",
  path: "send-sign",
  exact: true,
},
{
  component: "Templates",
  path: "templates",
  exact: true,
},
{
  component: "PDFViewer",
  path: "document/:id",
  exact: true,
},
{
  component: "Settings",
  path: "settings",
  exact: true,
},
{
  component: "Contacts",
  path: "contacts",
  exact: true,
},
{
  component: "AddRecipient",
  path: "envelope-settings/:id/add_recipient",
  exact: true,
},
{
  component: "PDFViewer",
  path: "edit-template/:id",
  exact: true,
},
{
  component: "PDFViewer",
  path: "edit-as-new-template/:id",
  exact: true,
},
{
  component: "PDFViewer",
  path: "send-sign/:id",
  exact: true,
},
{
  component: "PDFViewer",
  path: "sign-myself/:id",
  exact: true,
},
{
  component: "SendDetails",
  path: "send-sign-details/:id",
  exact: true,
},
{
  component: "SignDetails",
  path: "sign-myself-details/:id",
  exact: true,
},
{
  component: "Integrations",
  path: "integrations",
  exact: true,
},
{
  component: "PriceList",
  path: "price-list",
  exact: true,
},
{
  component: "Payment",
  path: "payment",
  exact: true,
},
{
  component: "CreateTemplates",
  path: "create-templates",
  exact: true,
},
{
  component: 'MondayCRM',
  path: 'sdkhome',
  exact: true
},
{
  component: 'RecentActivity',
  path: 'activity',
  exact: true
}];

export default routes;