import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null,
};

export const welcomeImgDetails = createSlice({
    name: 'welcomeImgDetail',
    initialState,
    reducers: {
        welcomeImgDetail: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { welcomeImgDetail } = welcomeImgDetails.actions;

export default welcomeImgDetails.reducer;