import React, { useEffect, useRef, useState } from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import { AppBar, Box, Button, Divider, Drawer, IconButton, MenuItem, Select, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './PDFSign.css';
import PDFSignField from './PDFSignField';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { toggleLoader } from '../store/loader/loaderReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { digest_action } from '../store/digest/digest_action';
import PDFDecline from './PDFDecline';
import { replaceToNumber } from '../utils/commonFunction';
import { validateEmail } from '../utils/validation';
import PDFSignPreview from './PDFSignPreview';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const field_constants = ['Name', 'Multiline', 'Radio', 'Checkbox', 'Dropdown', 'Url', 'Date', 'Email', 'Signature', 'Company', 'Initial', 'Text', 'Number', 'Title'];

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'scroll',
    overflowY: 'hidden',
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const PDFSign = (props) => {
  const [numPages, setNumPages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageInput, setPageInput] = useState(1);
  const [controls, setControls] = useState({});
  const [file, setFile] = useState(null);
  const [modal, setModal] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [getImage, setGetImage] = useState({});
  const [imgType, setImgType] = useState('');
  const [downloadKey, setDownloadKey] = useState(null);
  const [pageProperties, setPageProperties] = useState(null);
  const [signText, setSignText] = useState(null);
  const [type, setType] = useState(null);
  const [getColor, setGetColor] = useState(null);
  const [getFont, setgetFont] = useState(null);
  const [signType, setSignType] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const digestDetails = useSelector((state) => state?.digestDetails?.value);
  const draftType = window.location.href.split('?')[1]?.length;
  const is_mobile = useMediaQuery('(min-width: 600px)');

  let url = window.location.href;
  let digest = draftType ? url.split('=')[1] : digestDetails;

  const refCanvas = useRef();
  const refDocument = useRef();

  useEffect(() => {
    setFile(props?.file);
    if (props?.file) {
      dispatch(toggleLoader(false));
    }
  }, [props?.file]);

  useEffect(() => {
    let position = props?.properties?.template_information?.Properties ? JSON.parse(props?.properties?.template_information?.Properties) : null;
    let signature_options = props?.properties?.signature_options ? JSON.parse(props?.properties?.signature_options) : null;
    let item = position?.properties?.field_properties ? Object.keys(position?.properties?.field_properties)?.length !== 0 : null;
    let list = {};
    if (item) {
      let get_field_position = Object.keys(position?.properties?.field_properties);
      setPageProperties(position?.properties?.page_properties);
      get_field_position?.map((item) => {
        let arr = [];
        let fields = position?.properties?.field_properties[item];
        let fields_position = position?.properties?.field_positions_object;
        let format_object = position?.properties?.format_object;
        let default_format = position?.properties?.default_format_object;        

        let fields_name = Object.keys(fields);

        let radioOption = [];
        let checkboxOption = [];
        let dropdownOption = [];

        fields_name.map((list) => {
          const isCheckBox = fields[list]?.field_value?.replace(/[\d'']+/g, '') === 'Checkbox '
          const isDropDown = fields[list]?.field_value?.replace(/[\d'']+/g, '') === 'Dropdown '
          const isRadio = fields[list]?.field_value?.replace(/[\d'']+/g, '') === 'Radio '
          const isSignature = fields[list]?.field_value?.replace(/[\d'']+/g, '') === 'Signature '
          // const isText = fields[list]?.field_value?.replace(/[\d'']+/g, '') === 'Text'

          if (isCheckBox || isRadio) {
            const curData = {
              ...fields[list],
              field_key: field_constants[fields[list]?.type - 1],
              width: fields_position[list]?.width,
              height: fields_position[list]?.height,
              background: fields_position[list]?.background,
              type: fields[list]?.type,
            };

            let optionValue = fields[list]?.field_value?.split(' ')[0].concat('_value').toLowerCase();

            const activeOption = isCheckBox ? checkboxOption : radioOption;
            const curIdx = activeOption.findIndex((o) => o.group_id === curData.group_id);
            const dataLen = activeOption.length;

            if (curIdx === -1) {
              activeOption[dataLen] = { ...curData, options: [curData[optionValue]] };
            } else {
              activeOption[curIdx] = {
                ...activeOption[curIdx],
                options: [...activeOption[curIdx].options, curData[optionValue]],
              };
            }
          }
          else if (isDropDown) {
            return dropdownOption.push({
              key: fields[list]?.key,
              field_key: field_constants[fields[list]?.type - 1],
              field_value: fields[list]?.field_value,
              value: fields[list]?.field_value,
              options: fields[list]?.dropdown_value,
              width: fields_position[list]?.width,
              height: fields_position[list]?.height,
              top: fields_position[list]?.top + 9,
              left: fields_position[list]?.left - 2,
              background: fields_position[list]?.background?.replace(';', ''),
              type: fields[list]?.type,
              is_required: fields[list]?.is_required,
              fontSize: fields[list]?.is_format_object ? format_object[list]?.font_size : default_format?.font_size,
              fontWeight: fields[list]?.is_format_object ? format_object[list]?.bold ? 'bold' : 'normal' : default_format?.bold ? 'bold' : 'normal' ,
              fontStyle: fields[list]?.is_format_object ? format_object[list]?.italic ? 'italic' : 'normal' : default_format?.italic ? 'italic' : 'normal',
              alignment: fields[list]?.is_format_object ? format_object[list]?.alignment : default_format?.alignment,
              fontFamily: fields[list]?.is_format_object ? format_object[list]?.font_family : default_format?.font_family,
              color: fields[list]?.is_format_object ? format_object[list]?.color : default_format?.color,
              is_format_object: fields[list]?.is_format_object,
              board_details: fields[list]?.board_details
            })
          }
          else {
            return arr.push({
              key: fields[list]?.key,
              field_key: fields[list]?.board_details ? list : field_constants[fields[list]?.type - 1],
              field_value: fields[list]?.type === 12 ? list : fields[list]?.field_value,
              initial_value: fields[list]?.field_value,
              value: fields[list]?.type === 12 ? fields[list]?.default_value : '',
              placeholder: fields[list]?.default_value,
              width: fields_position[list]?.width,
              height: fields_position[list]?.height,
              top: isSignature ? fields_position[list]?.top : fields_position[list]?.top + 9,
              left: isSignature ? fields_position[list]?.left : fields_position[list]?.left - 2,
              background: fields_position[list]?.background?.replace(';', ''),
              type: fields[list]?.type,
              is_required: fields[list]?.is_required,
              date_format: fields[list]?.date_format,
              board_details: fields[list]?.board_details,
              fontSize: fields[list]?.is_format_object ? format_object[list]?.font_size : default_format?.font_size,
              fontWeight: fields[list]?.is_format_object ? format_object[list]?.bold ? 'bold' : 'normal' : default_format?.bold ? 'bold' : 'normal' ,
              fontStyle: fields[list]?.is_format_object ? format_object[list]?.italic ? 'italic' : 'normal' : default_format?.italic ? 'italic' : 'normal',
              alignment: fields[list]?.is_format_object ? format_object[list]?.alignment : default_format?.alignment,
              fontFamily: fields[list]?.is_format_object ? format_object[list]?.font_family : default_format?.font_family,
              color: fields[list]?.is_format_object ? format_object[list]?.color : default_format?.color,
              is_format_object: fields[list]?.is_format_object,
            })
          }
        })

        const sortRadio = radioOption.map((o) => {
          const { radio_value, ...restData } = o;
          const sorted = restData.options.sort((a, b) => a.id - b.id);

          return {
            ...restData,
            options: sorted,
            top: sorted[0].top,
            left: sorted[0].left,
          };
        });

        const sortCheckBox = checkboxOption.map((o) => {
          const { checkbox_value, ...restData } = o;
          const sorted = restData.options.sort((a, b) => a.id - b.id);
          return {
            ...restData,
            options: sorted,
            top: sorted[0].top,
            left: sorted[0].left,
          };
        });
        list[item] = [...sortRadio, ...sortCheckBox, ...dropdownOption, ...arr];
      });
    }
    setControls(list);
    setSignType(signature_options);
  }, [props?.properties]);

  const handleSubmit = async () => {
    setOpen(false);
    let field_properties = {};
    let field_positions = {};
    let format_object = {};
    let signatureArr = [];
    let error = [];
    let page_num = [];

    Object.keys(controls)?.map((val) => {
      let property = {};
      let options = {};
      controls[val].map((item) => {
        if (item?.is_required && !item?.board_details) {
          let errName = item?.type === 12 ? item.initial_value : item.placeholder;
          if (item.field_value.split(' ')[0] === 'Signature') {
            if (!Object.keys(getImage)?.length) {
              error.push(errName);
              if (!page_num?.length) page_num = [val];
            }
          } else {
            if (!item?.value) {
              error.push(errName);
              if (!page_num?.length) page_num = [val];
            }
          }
        }

        if (item?.field_key === 'Email' && item?.value) {
          let val = validateEmail(item?.value);
          if (!val) {
            toast.error(item.value + ' email is invalid');
            error.push('email');
          }
        }

        signatureArr.push(item.field_value.replace(/\d+/g, ''));
        let field_name = item.field_value.replaceAll(' ', '_');

        if (item?.field_key === 'Radio' || item?.field_key === 'Checkbox') {
          item.options.map((list, i) => {
            if (item?.is_required) {
              if (!item?.selected_value) error.push(item?.field_key);
            }
            // let lnth = Object.keys(options).length === 0 ? 1 : Object.keys(options).length + 1;
            // let option_name = `${item.field_key.split(' ')[0] + '_options_' + lnth}`.toLowerCase();
            let option_name = list?.option_name;
            let option_value = `${item.field_key.split(' ')[0] + '_value'}`.toLowerCase();
            options = {
              ...options,
              [option_name]: {
                field_value: item.field_value,
                key: item.key,
                group_id: item?.group_id,
                type: item?.type,
                [option_value]: {
                  id: list.id,
                  value: list.value,
                  is_selected: list.is_selected,
                },
              },
            };
            field_positions = {
              ...field_positions,
              [option_name]: {
                top: item?.type === 5 ? item?.top : list.top,
                left: item?.type === 5 ? item?.left : list.left,
                background: item.background,
                width: item.width,
                key: item.key,
                height: item.height,
              },
            };
            property = { ...options, ...property };
          });
        } else if (item?.field_key === 'Dropdown') {
          if (item?.is_required) {
            if (!item?.selected_value) error.push(item?.field_key);
          }
          property = {
            ...property,
            [field_name]: {
              width: item?.width,
              field_value: item?.field_value,
              dropdown_value: item.options,
              default_value: item?.selected_value,
              type: item?.type,
              key: item?.key,
              height: item?.height,
              is_format_object: item?.is_format_object
            },
          };

          field_positions = {
            ...field_positions,
            [field_name]: {
              top: item.top + 9,
              left: item.left + 2,
              width: item.width,
              key: item.key,
              height: item.height,
            },
          };

          format_object = {
            ...format_object, [field_name]: {
                font_family: item?.fontFamily,
                bold: item?.fontWeight === 'bold' ? true : false,
                italic: item?.fontStyle === 'italic' ? true : false,
                alignment: "left",
                font_size: item?.fontSize,
                color: item?.color
            }
        };
        } else {
          property = {
            ...property,
            [field_name]: {
              is_required: item?.is_required,
              width: item?.width,
              field_value: item?.type === 12 ? item?.initial_value : item?.field_value,
              is_read_only: item?.is_read_only,
              default_value: item?.value,
              type: item?.type,
              key: item?.key,
              height: item?.height,
              is_format_object: item?.is_format_object
            },
          };

          field_positions = {
            ...field_positions,
            [field_name]: {
              top: item?.field_key === 'Signature' ? item.top : item.top + 9,
              left: item?.field_key === 'Signature' ? item.left : item.left + 2,
              background: item.background,
              width: item.width,
              key: item.key,
              height: item.height,
            },
          };

          format_object = {
            ...format_object, [field_name]: {
                font_family: item?.fontFamily,
                bold: item?.fontWeight === 'bold' ? true : false,
                italic: item?.fontStyle === 'italic' ? true : false,
                alignment: "left",
                font_size: item?.fontSize,
                color: item?.color
            }
        };
        }
      });

      field_properties = { ...field_properties, [val]: { ...property } };
      field_positions = { ...field_positions };
      format_object = { ...format_object };
    });

    scrollPages(page_num[0]);
    page_num = [];

    let field = {
      field_properties: field_properties,
      page_properties: pageProperties,
      field_positions_object: field_positions,
      format_object: format_object
    };

    let blob = null;
    if (Object.keys(getImage)?.length) {
      blob = await fetch(getImage).then((res) => res.blob());
    }

    let file = new File([blob], imgType);

    let form =
      signatureArr.indexOf('Signature ') !== -1 && Object.keys(getImage)?.length
        ? {
          file: file,
          properties: field,
          digest: decodeURIComponent(digest),
        }
        : {
          properties: field,
          digest: decodeURIComponent(digest),
        };

    const formData = new FormData();

    formData.append('properties', JSON.stringify(form.properties));
    formData.append('digest', form.digest);
    signatureArr.indexOf('Signature ') !== -1 &&
      Object.keys(getImage)?.length &&
      formData.append('file', form?.file);

    if (!error?.length) {
      dispatch(toggleLoader(true));
      setLoading(true);
      axios.post(process.env.REACT_APP_SIGNSUBMIT, formData, { withCredentials: process.env.REACT_APP_CREDENTIALS })
        .then((res) => {
          setModal(true);
          setClosePopup(true);
          if (res?.data?.status === 3) {
            setDownloadKey(res?.data?.pdf_download_key);
          } else {
            setDownloadKey(null);
          }
          dispatch(toggleLoader(false));
          dispatch(digest_action(null));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(toggleLoader(false));
        });
    } else {
      if (error[0] !== 'email') {
        toast.error(`${error[0]} is required`);
      }
    }
  };

  const scrollPages = (val) => {
    handlePageNumber(Number(val), 'preview');
  };

  const go_to_mydocument = () => {
    window.open('https://centilio.com', '_self');
  };

  const onDocumentLoadSuccess = async (numPages) => {
    setNumPages([numPages?.numPages]);
  };

  const handlePageNumber = (e, key) => {
    let num = key ? e : Number(e.target.value);
    if (num >= 1) {
      if (numPages >= num) {
        setPageNumber(num);
        const ref = refCanvas.current?.style;
        let containerHeight = replaceToNumber(ref?.height) * (num - 1);
        refDocument.current.scrollTop = containerHeight;
      }
      setPageInput(num);
    } else {
      setPageInput('');
    }
  };

  const onChangeHandler = (value, index, key) => {
    let data = controls[pageNumber];
    data[index][key] = value;
    setControls((pre) => {
      return { ...pre, [pageNumber]: [...data] };
    });
  };

  const onChangeOptionHandler = (selected, index, value, optionKey) => {
    let data = controls[pageNumber];
    let options = [];
    controls[pageNumber][index]?.options?.map((item, i) => {
      if (optionKey === 'RadioButton') {
        options.push({
          id: item.id,
          is_selected: item.value === value ? true : false,
          value: item.value,
          top: item.top,
          left: item.left,
          option_name: item?.option_name
        });
        data[index]['selected_value'] = true;
      } else if (optionKey === 'Checkbox') {
        options.push({
          id: item.id,
          is_selected: item.value === value ? selected : item.is_selected,
          value: item.value,
          top: item.top,
          left: item.left,
          option_name: item?.option_name
        });
        let req = controls[pageNumber][index]?.options?.filter((s) => s.is_selected === true);
        if (req?.length === 0 && selected) {
          data[index]['selected_value'] = true;
        } else if (req?.length === 1 && !selected) {
          data[index]['selected_value'] = false;
        }
      } else {
        options.push({
          id: item.id,
          value: item.value,
        });
        data[index]['selected_value'] = selected !== 'choose' ? selected : '';
      }
    });
    data[index]['options'] = options;
    setControls((pre) => {
      return { ...pre, [pageNumber]: [...data] };
    });
  };

  const handleGetFile = (file) => {
    setGetImage(file);
  };

  const findClosest = (ele) => {
    if (!ele) {
      return false;
    }

    const parent = ele?.parentNode;
    const classList = parent?.classList;

    if (classList?.contains('react-pdf__Page')) {
      return parent;
    } else {
      if (classList?.contains('react-pdf__Document')) {
        return false;
      }
      return findClosest(parent);
    }
  };

  const handleDocument = (e) => {
    const ele = e.target;
    const parent = findClosest(ele);

    if (parent) {
      const pageNumber = parent.getAttribute('data-page-number');
      setPageNumber(pageNumber);
      setPageInput(pageNumber);
    }
  };

  const handleGetText = (e, key) => {
    switch (key) {
      case 'text':
        setSignText(e);
        break;
      case 'type':
        setType(e);
        break;
      case 'color':
        setGetColor(e);
        break;
      case 'font':
        setgetFont(e);
        break;
      default:
        break;
    }
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));



  return (
    <>
      {file ?
        <div className='pdf_header_wrapper'>
          <div className='sign-top-bar'>
            <div className='top-bar-right-side'>
              <div className='pdf-home' onClick={() => go_to_mydocument()}>
                <img
                  src={process.env.REACT_APP_IMAGE_URL + 'sideBarSvg/Centilio.png'}
                  className='centilio-img'
                  alt='centilio-img'
                />
              </div>
              <div className='title-sec'>
                <div className='doc-title-document-name'>
                  {props?.properties?.template_information?.Template_Name?.split(".")?.slice(0, -1)?.join('.')}
                </div>
                {/* <div className='document_modified_time'>
         <div>
          Modified on{' '}
          <span>
           {moment(
            new Date(props?.properties?.template_information?.Modified_Time)
           ).format('DD/MM/YYYY')}
          </span>
         </div>
        </div> */}
              </div>
            </div>
            <Box className='pdf-button'>
              <Button
                variant='contained'
                onClick={() => handleSubmit()}
                className='btn-save'
                disabled={loading}
              >
                Submit
              </Button>
            </Box>
          </div>
          <div className='sign-request-bar'>
            <Box>
              <AppBar position="fixed" open={open}>
                <Toolbar sx={{ position: 'absolute', top: '60px', color: 'black' }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpen(true)}
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: 'none' }) }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
            </Box>
            <div className='sign-paginator' style={{ margin: is_mobile && '0 auto' }}>
              <input
                className='sign-page-input'
                value={pageInput}
                onChange={(e) => handlePageNumber(e)}
                type='text'
              />
              <span>/</span>&nbsp;<span>{numPages}</span>
            </div>
            <div className='recipent_select'>
              <Select
                value={'View Options'}
                MenuProps={{ classes: { paper: 'information-container' } }}
                className='sign-request-document'
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  float: 'right',
                }}
              >
                <MenuItem style={{ display: 'none' }} value={'View Options'}>
                  <span className='sign-select-option'>View Options</span>
                </MenuItem>
                <MenuItem
                  className='sign-text'
                  value={'newFolder'}
                  onClick={() => props?.handleInformation()}
                >
                  <span>Information</span>
                </MenuItem>
                <MenuItem
                  className='sign-text'
                  value={'File Upload'}
                  onClick={() => setModal(true)}
                >
                  <span>Decline</span>
                </MenuItem>
                {/* <MenuItem className='sign-text' value={'Folder Upload'}>
                            <span>Print PDF</span>
                        </MenuItem> */}
              </Select>
            </div>
          </div>
          <Box className='sign-document-wrapper'>
            <Drawer
              sx={{
                '--Drawer-transitionDuration': open ? '0.4s' : '0.50s',
                '--Drawer-transitionFunction': open
                  ? 'cubic-bezier(0.79,0.14,0.15,0.86)'
                  : 'cubic-bezier(0.77,0,0.18,1)',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: open ? is_mobile ? '180px' : '120px' : '0px',
                  marginTop: '116px',
                  boxSizing: 'border-box',
                },
                '& .css-1d00l8e-MuiDrawer-docked': { width: '0px' }
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <DrawerHeader>
                <IconButton onClick={() => setOpen(false)}>
                  <ChevronLeftIcon />
                </IconButton>
              </DrawerHeader>

              <PDFSignPreview
                file={file}
                pageNumber={(val) => handlePageNumber(val, 'preview')}
                selectPageNumber={pageNumber}
                formData={controls} />
            </Drawer>
            <Main open={open}>
              <Box className='sign-request-pdf' ref={refDocument}>
                <div className='pdf-viewer-sign'>
                  <div
                    id='sign-request'
                    className='scrolling-page'
                    style={{ padding: is_mobile ? '0px' : '10px' }}
                    onClick={(e) => handleDocument(e)}
                  >
                    <Document
                      file={file}
                      error=''
                      loading=''
                      noData=''
                      options={{ workerSrc: 'pdf.worker.js' }}
                      onLoadSuccess={(num) => onDocumentLoadSuccess(num)}
                    >
                      {Array.apply(null, { length: numPages })
                        .map((val, index) => {
                          return index + 1;
                        })
                        .map((pages, i) => (
                          <Page
                            canvasRef={refCanvas}
                            pageNumber={pages}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            key={i}
                            className='page-canvas'
                            width={700}
                            height={500}
                          >
                            <PDFSignField
                              formDetails={controls[pages]}
                              signatureOptions={signType}
                              imgType={(value) => setImgType(value)}
                              formData={(file) => handleGetFile(file)}
                              getImage={getImage}
                              getText={handleGetText}
                              getSignText={signText}
                              getSignType={type}
                              getFont={getFont}
                              getColor={getColor}
                              onChangeHandler={(value, index, key) =>
                                onChangeHandler(value, index, key)
                              }
                              onChangeOptionHandler={(selected, index, value, optionKey) =>
                                onChangeOptionHandler(selected, index, value, optionKey)
                              }
                            />
                          </Page>
                        ))}
                    </Document>
                  </div>
                </div>
              </Box>
            </Main>

          </Box>
          {
            modal && (
              <PDFDecline
                templateName={props?.properties?.template_information}
                downloadKey={downloadKey}
                modal={() => setModal(false)}
                showModal={modal}
                closePopup={closePopup}
              />
            )
          }
        </div> : <Skeleton sx={{ bgcolor: 'rgba(189,197,209,.2)', margin: '10px' }} animation="wave" variant="rounded" width={'calc(100% - 20px)'} height={'calc(100vh - 20px)'} />}
    </>
  );
};

export default PDFSign;