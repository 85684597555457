import {
  Box,
  Button,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import React from "react";
import styles from "./PriceTable.module.css";
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import {
  FreeList,
  ProfessionalList,
  EnterpriseList,
  Free,
  Professional,
  Enterprise,
} from "./ListDetails.js";
import { Navigation } from "../../utils/handleNavigation.js";
import { payment_action } from "../../store/payment/paymentAction.js";
import { useDispatch, useSelector } from "react-redux";

const PriceTable = ({ plan, promoCode }) => {
  const navPath = Navigation();
  const dispatch = useDispatch();

  const feature_plan = useSelector((state) => state.welcomeImgDetail.value);

  const selectPlanHandile = (e) => {
    let get_plan = null;
    switch (e) {
      case "Free":
        get_plan = Free[plan];
        break;
      case "Professional":
        get_plan = Professional[plan];
        break;
      case "Enterprise":
        get_plan = Enterprise[plan];
        break
      default:
        break;
    };
    dispatch(payment_action(get_plan));
    if (promoCode) navPath("/payment", { state: { promoCode: promoCode } });
    else navPath("/payment");
  };

  const show_features = feature_plan?.show_features?.length ? JSON.parse(feature_plan?.show_features)[0]?.features : [];
  return (
    <Grid sx={{ paddingBottom: "56px" }}>
      <Box className={styles.box_head}>
        <Box className={styles.plans}>
          <div style={{ paddingBottom: "38px" }}></div>
          <h2 className={styles.header} style={{ paddingBottom: "20px" }}>
            Free
          </h2>
          <div className={styles.phara} style={{ paddingBottom: "20px" }}>
            For individuals and sole proprietors with basic eSignature needs
          </div>
          <div className={styles.price}>
            <div className={styles.money}>
              <h2>{Free[plan].price}$</h2>
            </div>
            <div className={styles.plan_div}>
              <p>User/month</p>
              <p>Billed {Free[plan].planType}</p>
            </div>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Button
              variant="contained"
              className={styles.pay_button}
              onClick={() => selectPlanHandile("Free")}
              disabled
            >
              Select Plan
            </Button>
          </div>
          <List
            sx={{ width: "100%", maxWidth: 360 }}
            className={styles.list}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                className={styles.price_lists}
                component="div"
                id="nested-list-subheader"
              >
                Core features include:
              </ListSubheader>
            }
          >
            {FreeList.map((list, i) => {
              return (
                <ListItemButton className={styles.list_button} key={i}>
                  <Box className={styles.check_icon}>
                    <CheckSharpIcon fontSize="5px" color="primary" />
                  </Box>
                  <ListItemText className={styles.listed_text} primary={list} />
                </ListItemButton>
              );
            })}
          </List>
        </Box>
        <Box className={styles.plans}>
          <div className={styles.most_popular}>
            Most Popular
          </div>
          <h2 className={styles.header} style={{ paddingBottom: "20px" }}>
            Professional
          </h2>
          <div className={styles.phara} style={{ paddingBottom: "20px" }}>
            For small to medium teams that need to send, sign and collaborate
          </div>
          <div className={styles.price}>
            <div className={styles.money}>
              <h2>{Professional[plan].price}$</h2>
            </div>
            <div className={styles.plan_div}>
              <p>User/month</p>
              <p>Billed {Professional[plan].planType}</p>
            </div>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Button
              variant="contained"
              onClick={() => selectPlanHandile("Professional")}
              className={styles.pay_button}
            >
              Select Plan
            </Button>
          </div>
          <List
            sx={{ width: "100%", maxWidth: 360 }}
            className={styles.list}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                className={styles.price_lists}
                component="div"
                id="nested-list-subheader"
              >
                All Personal Plan features:
              </ListSubheader>
            }
          >
            {ProfessionalList.map((list, i) => {
              return (
                <ListItemButton className={styles.list_button} key={i}>
                  <Box className={styles.check_icon}>
                    <CheckSharpIcon fontSize="5px" color="primary" />
                  </Box>
                  <ListItemText className={styles.listed_text} primary={list} />
                </ListItemButton>
              );
            })}
          </List>
        </Box>
        <Box className={styles.plans}>
          <div style={{ paddingBottom: "38px" }}></div>
          <h2 className={styles.header} style={{ paddingBottom: "20px" }}>
            Enterprise
          </h2>
          <div className={styles.phara} style={{ paddingBottom: "20px" }}>
            For automating and optimising agreements with advanced features
          </div>
          <div className={styles.price}>
            <div className={styles.money}>
              <h2>{Enterprise[plan].price}$</h2>
            </div>
            <div className={styles.plan_div}>
              <p>User/month</p>
              <p>Billed {Enterprise[plan].planType}</p>
            </div>
          </div>
          <div style={{ paddingBottom: "20px" }}>
            <Button
              variant="contained"
              onClick={() => selectPlanHandile("Enterprise")}
              className={styles.pay_button}
            >
              Select Plan
            </Button>
          </div>
          <List
            sx={{ width: "100%", maxWidth: 360, ":hover": "none" }}
            className={styles.list}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader
                className={styles.price_lists}
                component="div"
                id="nested-list-subheader"
              >
                All Standard Plan features:
              </ListSubheader>
            }
          >
            {EnterpriseList.map((list, i) => {
              return (
                <ListItemButton className={styles.list_button} key={i}>
                  <Box className={styles.check_icon}>
                    <CheckSharpIcon fontSize="5px" color="primary" />
                  </Box>
                  <ListItemText className={styles.listed_text} primary={list} />
                </ListItemButton>
              );
            })}
          </List>
        </Box>
      </Box>
    </Grid>
  );
};

export default PriceTable;
