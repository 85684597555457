import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import styles from './LimitModal.module.css';
import CloseIcon from '@mui/icons-material/Close';

export const LimitModal = ({ limit, handleModalClose }) => {
    return (
        <Modal
            open={limit}
            sx={{ '& .MuiBackdrop-root': { backgroundColor: '#13214440' } }}
            closeAfterTransition
        >
            <Box className={styles.new_limit_wrap}>
                <IconButton
                    className={styles.close}
                    onClick={handleModalClose}
                >
                    <CloseIcon sx={{ "&:hover": { color: "#ed4c78" } }} />
                </IconButton>
                <Box className={styles.textfield}>
                    Your access limit is over.. Please upgrade your plan to access this feature.
                </Box>
            </Box>
        </Modal>
    )
};