import {createTheme} from '@mui/material';

export const theme = createTheme({
 palette: {
  border: {
   main: '#cbd0e06e',
  },
  primary: {
    main: '#377DFF',
   },
 },
 breakpoints: {
  keys: ['1000'],
  values: {
   1000: 1000,
  },
 },
});

